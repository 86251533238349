import React from "react";
import {
  HomeIcon,
  Guide,
  History,
  Consultant,
  News,
  Service,
  Logout,
  Switch,
  AccIco,
  Dollar,
  HistoryIconSvg,
  NewGuide,
  NewConsult,
  btnico1,
  NewService,
  ClientAccount,
  ClientAccountActive,
  ClientConsultation,
  ClientConsultationActive,
  ClientGuide,
  ClientGuideActive,
  Clientlanguage,
  ClientNews,
  ClientNewsActive,
  ClientService,
  ClientServiceActive,
  SideBarLogo,
  GuideSelected,
  GuideUnSelected,
  AccountSelected,
  AccountUnSelected,
  NewsSelected,
  NewsUnselected,
  ServicesSelected,
  ServicesUnSelected,
  ConsultationSelected,
  ConsultationUnSelected
} from "../../assets/index";
// import { ReactComponent as HistoryIconSvg } from '../../assets/images/icons8-time.svg'
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../shared/styles/css/sidebar.css";
export const Side = () => {
  const { t, i18n } = useTranslation();

  return [
    {
      title: <span className="side">{t("home")}</span>,
      path: "/home",
      pathName: "/question",
      icon: {
        // default: <ClientGuide className="defaultSvg" />,
        // active: <ClientGuideActive className="defaultSvg" />,
        default: <GuideUnSelected className="defaultSvg" />,
        active: <GuideSelected className="defaultSvg" />,
      },
      cName: "nav-text",
    },
    // {
    //   title: <span className="side">{t('guide')}</span>,
    //   path: "/question",
    //   icon: <Guide />,
    //   cName: "nav-text",
    // },
    // {
    //   title: <span className="side">{t('his')}</span>,
    //   path: "/history",
    //   icon: <HistoryIconSvg />,
    //   cName: "nav-text",
    // },
    {
      title: <span className="side">{t("consultation")}</span>,
      path: "/schedule",
      icon: {
        // default: <ClientConsultation className="defaultSvg" />,
        // active: <ClientConsultationActive className="defaultSvg" />,
        default: <ConsultationUnSelected className="defaultSvg" />,
        active: <ConsultationSelected className="defaultSvg" />,
      },
      cName: "nav-text",
    },
    {
      title: <span className="side">{t("services")}</span>,
      path: "/services",
      icon: {
        // default: <ClientService className="defaultSvg" />,
        // active: <ClientServiceActive className="defaultSvg" />,
        default: <ServicesUnSelected className="defaultSvg" fill={"#827566"} />,
        active: < ServicesSelected className="defaultSvg" />,
      },
      cName: "nav-text",
    },
    {
      title: <span className="side">{t("news")}</span>,
      path: "/news",
      icon: {
        default: <NewsUnselected className="defaultSvg" />,
        active: <NewsSelected className="defaultSvg" />,
      },
      cName: "nav-text",
    },

    {
      title: <span className="side">{t("account")}</span>,
      path: "/account",
      icon: {
        default: <AccountUnSelected className="defaultSvg" />,
        active: <AccountSelected className="defaultSvg" />,
      },
      cName: "nav-text",
    },
    // {
    //   title: <span className="side">{t('payment')}</span>,
    //   path: "/payment",
    //   icon: <img src={Dollar} width={"20px"} />,
    //   cName: "nav-text",
    // },
    // {
    //   title: <span className="side">{t('switch')}</span>,
    //   path: "/language",
    //   icon: {
    //     default:<Clientlanguage />,
    //     active:<Clientlanguage />
    // },
    //   cName: "nav-text",
    // },
    // {
    //   title: <span className="side">{t('logout')}</span>,
    //   path: "/signin",
    //   icon: <Logout />,
    //   cName: "nav-text",
    // },
    // {
    //   title: <span className="side">{t('signup')}</span>,
    //   path: "/signup",
    //   icon: <Logout />,
    //   cName: "nav-text",
    // },
  ];
};
