import { HTTP_Request } from "../util/config";
import { Endpoint } from "../util/endpoint";
import axios from 'axios'
const getPackageServices = () => {
  return HTTP_Request.post(Endpoint.getPackagePlan);
};

const gettingPackagesID = () => {
  return HTTP_Request.get(Endpoint.getPackageList);
}

const getPackagesbyId = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(Endpoint.getPackagesbyId, formBody);
};

// const getPackagesbyId = (obj) => {
//   return HTTP_Request.post(Endpoint.getPackagesbyId, obj)
// }

export { getPackageServices, getPackagesbyId, gettingPackagesID };
