import React, { useState, useEffect } from "react";
import "../../shared/styles/css/account.css";
import Navigation from "../navigation";
import { Form, Dropdown, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { browserName, browserVersion, isIOS } from "react-device-detect";

import {
  getPackageServices,
  getPackagesbyId,
  gettingPackagesID,
  getServiceHistory
} from "../../shared";
import Loader from "../../shared/loader/loader";
import { clientInnerWidth } from "../../shared/util/constant";
import { useSelector } from "react-redux";
import { getAnalytics, logEvent } from "firebase/analytics";


const Services = () => {
  const { t, i18n } = useTranslation();
  const [loader, setLoader] = useState(true);
  const [clientWidth, setClientWidth] = useState(0);
  const history = useHistory();
  const [packageList, setPackageList] = useState([]);
  const [selectOptions, setSelectOptions] = useState(["All"]);
  const [dropdownTitle, setDropdownTitle] = useState("All");
  const [selectObj, setSelectObj] = useState({});
  const [purchasedIds, setPurchasedIds] = useState([]);

  const {
    lang: { lang },
    user: { user },
  } = useSelector((state) => state.root);



  const handleSelection = (e) => {
    setDropdownTitle(e.target.innerText);

    if (e.target.innerText == "All") {
      const obj = {
        package_id: 0,
      };
      setLoader(true);
      getPackagesbyId(obj)
        .then(({ data: { data } }) => {
          setLoader(false);
          setPackageList(data);
        })
        .catch((err) => { });
    } else {
      selectObj.map((i) => {
        if (i.name == e.target.innerText) {
          const obj = {
            package_id: i.id,
          };

          setLoader(true);
          getPackagesbyId(obj)
            .then(({ data: { data } }) => {
              setLoader(false);

              if (data.length > 0) {
                setPackageList(data);
              } else {
                setPackageList([]);
              }
            })
            .catch((err) => { });
        }
      });
    }
  };

  const getList = () => {
    gettingPackagesID()
      .then(({ data: { data } }) => {
        let temp = [];
        temp.push("All");
        data.map((i) => temp.push(i.name));
        setSelectOptions(temp);
        setSelectObj(data);
      })
      .catch((err) => { });
  };


  const PackagePlan = () => {
    getPackageServices()
      .then(({ data: { data } }) => {
        setPackageList(data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    PackagePlan();
    getList();
  }, []);
  const [sidebar, setSidebar] = useState(true);
  const showSidebar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(!sidebar);
    }
  };
  const hideSideBar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(false);
    }
  };

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'Services');
  }, []);

  return (
    <>
      <Navigation
        label={t("services")}
        width={setClientWidth}
        onPress={() => history.goBack()}
        sidebar={sidebar}
        showSidebar={showSidebar}
        setSidebar={setSidebar}
      />

      <div
        className={`main ${clientWidth > clientInnerWidth.width && "margin300"
          }`}
        onClick={hideSideBar}
      >
        {/* <div>
          <Form.Select className="selectionPackage" aria-label="Default select example" onChange={(e) => handleSelection(e)}>
            {selectOptions.map((i) => {
              return (
                <>
                  <option className="text-center">{i}</option>
                </>
              )
            })}
          </Form.Select>
        </div> */}
        <div className="container px-3">
            <p className="servicePara">{t("serviceDescription")} <strong>{t("serviceAffordable")}{' '}</strong>{t("serviceWith")} {' '}<strong> {t("serviceAffordablePayment")}</strong>{" "}{t("serviceDescriptionRemain")} {' '}

            </p>
            <p>{t("serviceDescriptionRemainOne")}  {' '}
            <strong><a href="https://bordercrossinglaw.com/contact/" className="removeDecoration">{t("serviceContact")}</a></strong>{' '}
            {t("servicePayment")}
            </p> 
            
          </div>
        <Container className="dropdownContainer">
        <div className="elfsight-app-d3d4db49-f40c-4b90-812d-ab3888b44206 dropdownReviewWidth"></div>
            <Dropdown
            className="dropdownPackage dropdownWidth"
            style={{ paddingTop: "1.5rem", }}
          >
            <Dropdown.Toggle id="dropdown-basic">
              {dropdownTitle}
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropMenu">
              {selectOptions.map((i) => {
                return (
                  <>
                    <Dropdown.Item
                      className="dropItem"
                      onClick={(e) => handleSelection(e)}
                    >
                      {i}
                    </Dropdown.Item>
                  </>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          
        </Container>
        
        {/* <p className="paragraph-home">{t("select-package")}</p> */}

        <div className="container cont-news mt-4">
          <div className="row d-flex">
            <div className="row" style={(browserName == "Mobile Safari" || isIOS) ? { marginBottom: "25%" } : {}}>
          
              {packageList.map((post) => {
                return (
                  <>

                    <div
                      className={`col-6 col-news ${packageList.length === 1 ? "single-card" : ""
                        }`}
                      style={{
                        height: "140px",
                        cursor: "pointer",
                        borderRadius: "10px",
                        width: "97%",
                      }}
                      onClick={() => {
                        history.push({
                          pathname: "/services/" + post.slug,
                          state: post,
                          ids: purchasedIds
                        });
                        // if (post.plan_type == "recurring") {
                        //   history.push({
                        //     pathname: "/package-detail/" + post.slug,
                        //     state: post,
                        //     ids: purchasedIds
                        //   });
                        // } else {

                        // }
                      }}
                    >
                      <img
                        className="img-news"
                        style={{
                          borderRadius: "5px",
                          margin: "30px 0px 30px 0px",
                          width: "125px",
                          height: "90px",
                          objectFit: "cover",
                          objectPosition: "25% 25%"
                        }}
                        src={
                          "https://admin.openborders.io/public/" +
                          post.image
                        }
                      />
                      <div
                        className="descrptn_cont"
                        style={{ marginLeft: "3%" }}
                      >
                        <h2 className="head-news"> {lang == "sp" ? post.plan_name_es : post.plan_name}</h2>

                        <h3 className="priceTags" style={{ fontSize: "21px" }}>
                          {post.plan_prices.length > 1 ? (
                            <span className="languageSpan">{t("From")} </span>
                          ) : (
                            ""
                          )}
                          $
                          {post.plan_type == "single" ? (
                            <span className="languageSpan">{(post.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                          ) : (
                            <>
                              {post.plan_prices.length > 1 ?
                                <span className="languageSpan">
                                  {/* reccurrint */}
                                  {/* every_month */}
                                  {(post.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} {t("amountPackage1")}
                                </span> : <span className="languageSpan">
                                  {/* reccurrint */}
                                  {/* every_month */}
                                  {(post.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} {t("amountPackage")} {post.duration}{" "}
                                  {t("months")}
                                </span>}

                            </>
                          )}
                        </h3>

                        {/* <div
                          className="desc-news"
                          dangerouslySetInnerHTML={{
                            __html: post.description,
                          }}
                        ></div> */}

                        {/* <p className="desc-news">{post.description}</p> */}
                        {/* <p
                          className="view-news"
                          onClick={() => {
                            if (post.plan_type == "recurring") {
                              history.push({
                                pathname: "/package-detail/" + post.id,
                                state: post,
                              });
                            } else {
                              history.push({
                                pathname: "/package/" + post.id,
                                state: post,
                              });
                            }
                          }}
                        >
                          {t("view-detail")}&gt;&gt;
                        </p> */}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </>
  );
};

export default Services;
