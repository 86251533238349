import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { Form, Row, Col, Dropdown } from "react-bootstrap";
import Select from "react-select";
import countryList from "react-select-country-list";
import CountryList from "country-list-with-dial-code-and-flag";

import PhoneInput from "react-phone-input-2";
function Detail(props) {
  const {
    fname,
    setFname,
    lname,
    setLname,
    setEmail,
    phone,
    setPhone,
    street,
    setStreet,
    country,
    setCountry,
    states,
    setStates,
    city,
    setCity,
    zip,
    setZip,
    check,
    setCheck,
    email,
    description,
    setDescription,
    setEmailForUS,
    emailForUS,
    fNameUS,
    setFNameUS,
    lNameUS,
    setLNameUS,
    loginCheck,
    setLoginCheck,
    setConfrmPassword,
    Confrmpassword,
    password,
    setpassword,
    countryCode,
    setCountryCode
  } = props;
  const { user } = useSelector((state) => state.root);
  const [countryFilter, setCountryFilter] = useState([]);
  
  const options = useMemo(() => countryList().getData(), []);
  const changeHandler = (value) => {
    setCountry(value);
  };

  const SelectCountry = (e) => {
    let temp = CountryList.filter(
      (country) => country.name + " " + country.flag == e.target.innerText
    );
    temp.unshift();
    setCountryCode(temp[0].dial_code);
  };

  const formatPhoneNumber = (phone) => {
    setPhone(phone.replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
  };

  const filterCountryList = (e) => {
    let countryCodeArr = [];
    let temp = CountryList.map((i) => {
      if (i.name.toLowerCase().includes(e.target.value.toLowerCase())) {
        countryCodeArr.push(i);
      }
    });
    setCountryFilter(countryCodeArr);
  };
  return (
    <form onSubmit={() => console.log("zain")}>
      <div className="container marginTop20">
        <p className="p-acc removeMargin">{t("information")}</p>
        <div className="row">
          <div className="col">
            <label for="fname">{t("fname")}</label>
          </div>
          <div className="col">
            <label for="lname">{t("lname")}</label>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <input
              onChange={(e) => setFname(e.target.value)}
              value={fname}
              type="text"
              className="acc-inpu-after"
              placeholder="Jake"
              id="fname"
              required
            />
          </div>
          <div className="col">
            <input
              onChange={(e) => setLname(e.target.value)}
              type="text"
              className="acc-inpu-after"
              placeholder="Muss"
              id="lname"
              value={lname}
              required
            />
          </div>
        </div>
        {/* <label for="PhoneNum">{t("phoneNo")}</label> */}
        <Row>
          {/* <Col sm={3} className="listCountryCol" >
                <label>Code</label>
              </Col> */}
          <Col>
            <label>{t("phoneNo")}</label>
          </Col>
        </Row>
        <Row>
          <Col sm={3} style={{ width: "auto" }}>
            <Dropdown>
              <Dropdown.Toggle
                style={{ background: "transparent" }}
                className="phoneCode"
                variant="success"
                id="dropdown-basic"
              >
                {countryCode}
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropCode">
                <input
                  className="countryList"
                  placeholder="Search Country..."
                  onChange={(e) => filterCountryList(e)}
                />
                {countryFilter.length > 0
                  ? countryFilter.map((country) => {
                      return (
                        <>
                          <Dropdown.Item
                            href="#"
                            onClick={(e) => SelectCountry(e)}
                          >
                            {country.name + " " + country.flag}
                          </Dropdown.Item>
                        </>
                      );
                    })
                  : CountryList.map((country) => {
                      return (
                        <>
                          <Dropdown.Item
                            href="#"
                            onClick={(e) => SelectCountry(e)}
                          >
                            {country.name + " " + country.flag}
                          </Dropdown.Item>
                        </>
                      );
                    })}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col>
            <input
              className="signin-input w-100"
              onChange={(e) => formatPhoneNumber(e.target.value)}
              type="phone"
              // pattern="[0-9\s]{13,19}"
              autocomplete="cc-number"
              maxLength="12"
              pattern="\d*"
              id="PhoneNum"
              value={phone}
              required
            />
          </Col>
        </Row>
        {/* <input
          onChange={(e) => setPhone(e.target.value)}
          type="number"
          className="acc-input-after"
          pattern="[0-9\s]{13,19}"
          autocomplete="cc-number"
          maxlength="16"
          id="PhoneNum"
          placeholder="0009872132"
          value={phone}
          required
        /> */}
        {/* <PhoneInput
          country={"us"}
          value={phone}
          onChange={(phone) => setPhone(phone)}
        /> */}
        <label for="email" className="mt-3">
          {t("email")}
        </label>
        {/* <div  placeholder="jakemuss0@gmail.com" className="acc-input-after">{email}</div> */}
        <input
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          className="acc-input-after"
          id="email"
          // disabled={true}
          value={email}
          placeholder="jakemuss0@gmail.com"
          required
        />
        {/* <p className="p-acc removeMargin">{t("address")}</p> */}
        {/* <p className="p-acc removeMargin">{t("question")}</p> */}
        {/* <p className="p-acc removeMargin">{t("complete-info")}</p> */}
        <label for="Street">{t("street")}</label>
        <input
          onChange={(e) => setStreet(e.target.value)}
          type="text"
          className="acc-input-after"
          id="Street"
          placeholder="115 Sunset. St."
          value={street}
          required
        />
        <label for="city">{t("city")}</label>
        <input
          onChange={(e) => setCity(e.target.value)}
          type="text"
          className="acc-input-after"
          id="city"
          placeholder="Inglewood"
          required
          value={city}
        />
        <label for="state">{t("state")}</label>
        <input
          onChange={(e) => setStates(e.target.value)}
          type="text"
          className="acc-input-after"
          id="state"
          placeholder="CA."
          value={states}
          required
        />
        <label for="postal">{t("zip")}</label>
        <input
          onChange={(e) => setZip(e.target.value)}
          type="number"
          className="acc-input-after"
          id="postal"
          placeholder="90301"
          value={zip}
          required
        />

        <label for="country">{t("country")}</label>
        {/* <input
          onChange={(e) => setCountry(e.target.value)}
          type="text"
          className="acc-input-after"
          id="country"
          value={country}
          placeholder="United States"
          required
        /> */}
        <Select
          options={options}
          value={country}
          onChange={changeHandler}
          className="country-input"
        />

        <label for="country">{t("TermsOnSchedule")}</label>
        <textarea
          onChange={(e) => setDescription(e.target.value)}
          rows={6}
          type="text"
          className="acc-input-after immigrationNeed"
          id="country"
          value={description}
          placeholder=""
          required
        ></textarea>
        {/* <label className="forget-pwd mb-3">
          <input
            type="checkbox"
            required
            value={check}
            onChange={(e) => setCheck(!check)}
          />{" "}
          <span>

            {t("CheckSchedule")}
          </span>
        </label> */}
        <Form>
          {["checkbox"].map((type) => (
            <div key={`default-${type}`} className="mb-3 checkSch">
              <Form.Check
                style={{ fontSize: "12px", color: "#4d5050", fontSize: "15px" }}
                className="mb-3"
                type={type}
                checked={check}
                id={`default-${type}`}
                label={t("CheckSchedule")}
                onChange={(e) => setCheck(!check)}
              />
            </div>
          ))}
        </Form>
        {user?.user?.is_guest ? (
          <>
            <Form>
              {["checkbox"].map((type) => (
                <div key={`default-${type}`} className="mb-3 checkSch">
                  <Form.Check
                    style={{
                      fontSize: "12px",
                      color: "#4d5050",
                      fontSize: "15px",
                    }}
                    className="mb-3"
                    checked={loginCheck}
                    type={type}
                    id={`default-${type}`}
                    label={t("publicSignin")}
                    onChange={(e) => setLoginCheck(!loginCheck)}
                  />
                </div>
              ))}
            </Form>
            {loginCheck ? (
              <>
                {" "}
                <InputTitle
                  title="Password"
                  type="password"
                  value={password}
                  handleChange={(value) => setpassword(value)}
                />
                <InputTitle
                  title="Confirm Password"
                  type="password"
                  value={Confrmpassword}
                  handleChange={(value) => setConfrmPassword(value)}
               />
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </form>
  );
}
const InputTitle = ({
  title,
  type,
  placeholder,
  value,
  handleChange,
  required,
}) => {
  return (
    <>
      <label>{title}</label>
      <input
        className="acc-input-after"
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={(e) => handleChange(e.target.value)}
        required={required}
      />
    </>
  );
};

export default Detail;
