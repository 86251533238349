import React, { useEffect, useState } from "react";
import "../../shared/styles/css/scheduleDate.css";
import moment from "moment";
import { Table, Form, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import momentTime from "moment-timezone";


function SchduleDate({
  dateArr,
  setDateArr,
  selectedDate,
  state,
  setState,
  getAvailabilityDate,
  dateLoader,
  timeZoneArr,
  selectedTimeAreas
}) {
  const { t, i18n } = useTranslation();

  let temp = timeZoneArr.find((i) => i.time_zone_value == momentTime.tz.guess())
  const [selectedTimeZone, setSelectedTimeZone] = useState(temp?.time_zone);
  const [selectedTimeArea, setSelectedTimeArea] = useState(temp?.time_zone_value);

  const getNextDate = () => {
    setState({
      ...state,
      dateobj: "",
    });
    let is_previous = 0

    getAvailabilityDate(
      null,
      dateArr?.filter((ii) => ii?.initialDate == selectedDate)[0].lastDate,
      true,
      false,
      is_previous
    );
  };
  const getPrevDate = () => {
    // let getLastEle = dateArr[0];
    // let lastDate = moment(getLastEle[0]?.time).subtract(1,'d').format("YYYY-MM-D");
    setState({
      ...state,
      dateobj: "",
    });
    let ClonedateAr = [...dateArr];
    let selectRemove = ClonedateAr?.findIndex(
      (ii) => ii?.initialDate == selectedDate
    );
    if (selectRemove > -1) {
      ClonedateAr.splice(selectRemove, 1);
    }

    let getLastEle = ClonedateAr[ClonedateAr.length - 1];

    let backDate = moment(getLastEle?.lastDate).format("YYYY-MM-D");
    let is_previous = 1
    getAvailabilityDate(null, backDate, true, ClonedateAr, is_previous);
  };


  const selectDate = (date) => {
    setState({
      ...state,
      dateobj: date.time,
    });
  };

  const TimeZoneSelection = (e) => {
    setSelectedTimeZone(e.target.value);
    let temp = timeZoneArr.find((i) => i.time_zone == e.target.value);
    setSelectedTimeArea(temp.time_zone_value);
  }


  return (
    <div className="marginTop20 mb-2">

      {/* <p className='p-acc removeMargin'>Select Appointment Time</p> */}
      {dateLoader ? <label>{t("Time")}</label> : ""}

      {dateArr?.length > 0 && (
        <>
          {/* <Container>
            <label>
              Time Zone:
            </label>
            <Form.Select className="selectTimeZone" value={selectedTimeZone} aria-label="Default select example" onChange={(e) => TimeZoneSelection(e)}>
              {
                timeZoneArr.map((i) => {
                  return (
                    <>
                      <option>{i.time_zone}</option>
                    </>
                  )
                })
              }
            </Form.Select>
          </Container> */}
          <div className="d-flex justify-content-between marginBottom12">
            {dateArr.length > 1 ? (
              <span className="next" onClick={getPrevDate}>
                {" "}
                &lt; Previous
              </span>
            ) : (
              <span></span>
            )}

            <span className="next" onClick={getNextDate}>
              More &gt;
            </span>
          </div>
          <div className="d-flex scroll tableCenter justifyingContent">
            {dateArr
              ?.filter((ii) => ii?.initialDate == selectedDate)[0]
              ?.AvailDate?.map((dateItem, inx) => {
                return (
                  <div key={inx}>
                    <div
                      className="text-center tableItem"
                      style={{ background: "#655a4e", padding: "10% 10%", color: "white" }}
                    >
                      <span className="tableHead">
                        {moment(dateItem[0].time).tz(selectedTimeAreas).format("dddd")}
                      </span>
                      <div className="headerDate">
                        {moment(dateItem[0].time).tz(selectedTimeAreas).format("MMM")}{" "}
                        {moment(dateItem[0].time).tz(selectedTimeAreas).format("DD")}
                      </div>
                    </div>
                    <hr style={{ height: "0px" }} />

                    {dateItem.map((sitem, sinx) => {
                      return moment(state.dateobj).format("DD h:mm") ==
                        moment(sitem.time).format("DD h:mm") ? (
                        <div
                          key={sinx}
                          onClick={() => selectDate(sitem)}
                          className="selectDate text-center tableItem tablebody"
                        >
                          {/* {moment(sitem.time).format("h:mm a")} */}
                          {moment(sitem.time).tz(selectedTimeAreas).format("h:mm a")}
                        </div>
                      ) : (
                        <div
                          key={sinx}
                          onClick={() => selectDate(sitem)}
                          className=" text-center tableItem tablebody"
                        >
                          {/* {moment(sitem.time).format("h:mm a")} */}
                          {moment(sitem.time).tz(selectedTimeAreas).format("h:mm a")}

                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
}

export default SchduleDate;
