import { Tick, EvaluationTriangle } from "../../../../assets";
import React, {
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { Button, Modal, Spinner } from "react-bootstrap";
import { language } from "../../../util/constant";
import "../../../styles/css/questionaire.css";
import { browserName, browserVersion, isIOS } from "react-device-detect";

import {
  ClientEmail,
  ClientEvaluation,
  ClientEmailSend,
  ShareIcon,
  ShareIcon1,
  ShareIconUpdated,
  ShareIconUpdated1,
  SendEmailIconSvg,
  SendEmailIconPng,
  ResultIcon,
  ResultIcon1,
} from "../../../../assets";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { toastMessage } from "../../../../shared/component/toast";
import { getAnalytics, logEvent } from "firebase/analytics";

const ViewSummary = ({
  immigrationHistory,
  factorsOptions,
  inadmissibility,
  summaryEmail,
  loaderE,
  multiLinkArr,
}) => {
  const [clientWidth, setClientWidth] = useState(0);
  const { t, i18n } = useTranslation();
  const [counter, setCounter] = useState(0);
  const [show, setShow] = useState(false);
  const [close, setClose] = useState(false);
  const handleModalClose = () => setShow(false);
  const handleModal = () => {
    setShow(true);
    setClose(false);
  };
  useLayoutEffect(() => {
    function updateSize() {
      setClientWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
  }, []);

  // useEffect(() => {
  //   if (!(multiLinkArr.length > 0)) {
  //     window.scrollTo(0, 0)
  //   }
  // })

  return (
    <div
      className={
        immigrationHistory.length > 1
          ? "sum-s scrolling d-flex"
          : "sum-s d-flex"
      }
    >
      <Button
        className={
          browserName == "Mobile Safari" || isIOS
            ? "btn evaluationBtn1"
            : "btn evaluationBtn"
        }
        onClick={handleModal}
      >
        <span style={{ marginRight: "10px" }}>
          <ClientEvaluation
            height={"50%"}
            width={"19px"}
            className="defaultSvg"
          />
        </span>
        <span style={{ color: "#FFFF" }}>{t("ViewYourEval")}</span>
      </Button>
      {counter > 0 && clientWidth < 768 ? (
        <button className="btn countBtn">{counter}</button>
      ) : null}
      {clientWidth > 768 ? (
        <>
          <ShowSummary
            summary={immigrationHistory}
            title={"immigrationHistory"}
            setShow={setShow}
            multiLinkArr={multiLinkArr}
          />
          <ShowSummary
            summary={factorsOptions}
            title={"factorsOptions"}
            setShow={setShow}
            multiLinkArr={multiLinkArr}
          />
          <ShowSummary
            summary={inadmissibility}
            title={"inadmissibility"}
            setShow={setShow}
            multiLinkArr={multiLinkArr}
          />
          <img
            src={ResultIcon1}
            onClick={handleModal}
            style={{ height: "45px" }}
            className="shareIcon"
          />
        </>
      ) : (
        ""
      )}
      <ModalSummary
        show={show}
        handleClose={handleModalClose}
        immigrationHistory={immigrationHistory}
        inadmissibility={inadmissibility}
        factorsOptions={factorsOptions}
        setCounter={setCounter}
        summaryEmail={summaryEmail}
        loaderE={loaderE}
      />
    </div>
  );
};
const ShowSummary = ({ summary, title, checkForScroll, setShow }) => {
  const { t, i18n } = useTranslation();
  const [scrollTop, setScrollTop] = useState(0);
  let factorEle = document.getElementById(
    "scroll-factorsOptions"
  )?.scrollHeight;
  let IndEle = document.getElementById("scroll-inadmissibility")?.scrollHeight;
  let ImmigrationEle = document.getElementById(
    "scroll-immigrationHistory"
  )?.scrollHeight;
  const contentRef = useRef(null);
  

  const {
    lang: { lang },
    user: { user },
  } = useSelector((state) => state.root);

  const handleModal = () => {
    setShow(true);
  };
  function handleScrollButton(direction) {
    const { current } = contentRef;
    if (current) {
      const scrollAmount = direction === "down" ? 200 : -200;
      current.scrollBy({ top: scrollAmount, behavior: "smooth" });
    }
  }

  return (
    <>
      
      {checkForScroll ? (
        <div
          onClick={() => handleModal()}
          className="EvaluationBox"
          style={
            checkForScroll
              ? { marginBottom: "5%", width: "100%" }
              : { width: "30%" }
          }
        >
          <div className="EvaluationHeading">
            {title == "immigrationHistory"
              ? t("ImmigrationHistory")
              : title == "inadmissibility"
              ? t("GroundsOfInadmissability")
              : t("FactorsRelating")}
          </div>
          <div
            className={checkForScroll ? "EvaluationinShare" : "Evaluations"}
            style={
              (summary.length > 2 || title == "inadmissibility") &&
              summary.length
                ? { height: "247px" }
                : { height: "auto" }
            }
          >
            {summary.map((post) => {
              return (
                <>
                  <div
                    className="IndiEvalutation"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang == "sp" ? post.description_ES : post.description,
                    }}
                  />
                </>
              );
            })}
          </div>
        </div>
      ) : (
        <div
          className="EvaluationBox ResponsiveBox"
          style={
            checkForScroll
              ? { marginBottom: "5%", width: "100%" }
              : { width: "30%" }
          }
        >
          <div className="EvaluationHeading">
            {title == "immigrationHistory"
              ? t("ImmigrationHistory")
              : title == "inadmissibility"
              ? t("GroundsOfInadmissability")
              : t("FactorsRelating")}
          </div>
          <div className="evaluationContainer">
            <div
              ref={contentRef}
              onClick={() => handleModal()}
              onScroll={() => {
                // for scroll bottom
                let bottom =
                  contentRef.current?.scrollHeight -
                  contentRef.current?.scrollTop;
                if (bottom === contentRef.current?.clientHeight) {
                  setScrollTop(contentRef.current?.clientHeight);
                }
                // for scroll top
                if (!contentRef.current?.scrollTop) {
                  setScrollTop(0);
                }
                console.log(
                  title,
                  contentRef.current?.scrollTop,
                  contentRef.current?.scrollHeight,
                  contentRef.current?.clientHeight
                );
              }}
              id={`scroll-${title}`}
              className={checkForScroll ? "EvaluationinShare" : "Evaluations"}
              style={
                (summary.length > 2 || title == "inadmissibility") &&
                summary.length
                  ? { height: "247px" }
                  : { maxHeight: "247px" }
              }
            >
              {summary.map((post, inx) => {
                return (
                  <>
                    <div
                      key={inx}
                      className="IndiEvalutation"
                      dangerouslySetInnerHTML={{
                        __html:
                          lang == "sp" ? post.description_ES : post.description,
                      }}
                    />
                  </>
                );
              })}
            </div>
            {ImmigrationEle > 247 && title == "immigrationHistory" ? (
              <div className="gridScrollContainer">
                {scrollTop == 0 ? (
                  ""
                ) : (
                  <img
                    className="arrowRotate180"
                    src={EvaluationTriangle}
                    width={"14"}
                    onClick={() => handleScrollButton("up")}
                  />
                )}
                {scrollTop == 247 ? (
                  ""
                ) : (
                  <img
                    className="arrowRotate117 bottomArrow"
                    src={EvaluationTriangle}
                    width={"14"}
                    onClick={() => handleScrollButton("down")}
                  />
                )}
              </div>
            ) : factorEle > 247 && title == "factorsOptions" ? (
              <div className="gridScrollContainer">
                {scrollTop == 0 ? (
                  ""
                ) : (
                  <img
                    className="arrowRotate180"
                    src={EvaluationTriangle}
                    width={"14"}
                    onClick={() => handleScrollButton("up")}
                  />
                )}
                {scrollTop == 247 ? (
                  ""
                ) : (
                  <img
                    className="arrowRotate117 bottomArrow"
                    src={EvaluationTriangle}
                    width={"14"}
                    onClick={() => handleScrollButton("down")}
                  />
                )}
              </div>
            ) : IndEle > 247 && title == "inadmissibility" ? (
              <div className="gridScrollContainer">
                {scrollTop == 0 ? (
                  ""
                ) : (
                  <img
                    className="arrowRotate180"
                    src={EvaluationTriangle}
                    width={"14"}
                    onClick={() => handleScrollButton("up")}
                  />
                )}
                {scrollTop == 247 ? (
                  ""
                ) : (
                  <img
                    className="arrowRotate117 bottomArrow"
                    src={EvaluationTriangle}
                    width={"14"}
                    onClick={() => handleScrollButton("down")}
                  />
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}

      {/* {
        summary?.length > 0 ? (
          <div onClick={() => handleModal()} style={{ cursor: "pointer" }} className={checkForScroll ? "sum" : summary.length > 1 ? "sum1" : summary[0].category == "Grounds of Inadmissibility" ? "sum1" : "sum"}>
            {summary?.map((item, index) => {
              if (index === 0) {
                return (
                  <>
                    <div className={(browserName == "Mobile Safari" || isIOS) ? "sum-h1" : "sum-h"}>
                      {item.category == "Your Immigration History" ? t("ImmigrationHistory") :
                        item.category == "Factors Relating to Your Options" ? t("FactorsRelating") :
                          t("GroundsOfInadmissability")
                      }
                    </div>
                    {item.description.includes("<") ? (
                      <p
                        className="sum-p"
                        dangerouslySetInnerHTML={{
                          __html:
                            lang === language.spanish
                              ? item.description_ES
                              : item.description,
                        }}
                      ></p>
                    ) : (
                      <p className="sum-p">
                        {lang === language.spanish
                          ? item.description_ES
                          : item.description}
                      </p>
                    )}
                  </>
                );
              } else {
                return item[
                  lang === language.spanish ? "description_ES" : "description"
                ].split("\n").length > 0 ? (
                  item[
                    lang === language.spanish ? "description_ES" : "description"
                  ]
                    .split("\n")
                    .map((summry, sInx) => {
                      return (
                        <div key={sInx}>
                          {summry.includes("<") ? (
                            <p
                              className="sum-p"
                              dangerouslySetInnerHTML={{ __html: summry }}
                            ></p>
                          ) : (
                            <p className="sum-p">{summry}</p>
                          )}
                        </div>
                      );
                    })
                ) : (
                  <div key={index}>
                    {lang === language.spanish &&
                      item.description_ES.includes("<") ? (
                      <p
                        className="sum-p"
                        dangerouslySetInnerHTML={{ __html: item.description_ES }}
                      ></p>
                    ) : item.description_ES.includes("<") ? (
                      <p
                        className="sum-p"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></p>
                    ) : (
                      <p className="sum-p">
                        {lang === language.spanish
                          ? item.description_ES
                          : item.description}
                      </p>
                    )}
                  </div>
                );
              }
            })}
          </div>
        ) : (
          <div className="sum" onClick={() => handleModal()} style={{ cursor: "pointer" }}>
            <div className={(browserName == "Mobile Safari" || isIOS) ? "sum-h1" : "sum-h"}>
              {title == "immigrationHistory"
                ? t("ImmigrationHistory")
                : title == "factorsOptions"
                  ? t("FactorsRelating")
                  : t("GroundsOfInadmissability")}
            </div>
            <p className="sum-p">{""}</p>
          </div>
        )
      } */}
    </>
  );
};
export default ViewSummary;

function ModalSummary({
  show,
  handleClose,
  immigrationHistory,
  inadmissibility,
  factorsOptions,
  setCounter,
  summaryEmail,
  loaderE,
}) {
  setCounter(
    immigrationHistory.length + inadmissibility.length + factorsOptions.length
  );

  const { t, i18n } = useTranslation();
  const {
    lang: { lang },
    user: { user },
  } = useSelector((state) => state.root);

  const handlingEmail = () => {
    if (user.is_guest == 1) {
      toastMessage(<span>{t("errorEmail")}</span>, "error");
    } else {
      summaryEmail();
    }
  };

  useEffect(() => {
    if (show) {
      const analytics = getAnalytics();
      logEvent(analytics, "Summary PopUp");
    }
  }, [show]);

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // })

  return (
    <>
      <Modal
        className="evaluation-modal"
        show={show}
        onHide={handleClose}
        scrollable={true}
      >
        <Modal.Header style={{ border: "none" }}>
          <Modal.Title
            className="mx-auto mt-3"
            style={{ fontSize: "17px", fontWeight: "bolder" }}
          >
            <span style={{ marginRight: "15px" }}>
              <ClientEvaluation height={"50%"} width={"19px"} className="" />
            </span>
            {t("YourEval")}
          </Modal.Title>
          <AiOutlineCloseCircle
            onClick={handleClose}
            size={20}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <ShowSummary
            summary={immigrationHistory}
            title={"immigrationHistory"}
            checkForScroll
          />
          <ShowSummary
            summary={factorsOptions}
            title={"factorsOptions"}
            checkForScroll
          />
          <ShowSummary
            summary={inadmissibility}
            title={"inadmissibility"}
            checkForScroll
          />
        </Modal.Body>
        <Modal.Footer>
          {immigrationHistory.length > 0 ||
          inadmissibility.length > 0 ||
          factorsOptions.length > 0 ? (
            <div className="warn">
              <p style={{ color: "#685e52" }}>{t("evalTab1")} </p>
              <p style={{ color: "#685e52", marginBottom: "0px" }}>
                {" "}
                {t("evalTab2")}
              </p>
            </div>
          ) : (
            ""
          )}
          {immigrationHistory.length > 0 ||
          inadmissibility.length > 0 ||
          factorsOptions.length > 0 ? (
            <button
              onClick={() => handlingEmail()}
              className="btn emailBtn mx-auto"
            >
              {loaderE ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <>
                  {/* <ClientEmail /> */}
                  <ClientEmailSend className="emailIcon" />
                  <span className="mx-2" style={{ marginTop: "3px" }}>
                    {t("EmailTheseResult")}
                  </span>
                </>
              )}
            </button>
          ) : (
            ""
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
