import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ImmigrationModal({ show, handleClose, resp }) {
  const { t, i18n } = useTranslation();
  const handleSubmit = (e) => {
    e.preventDefault();
    window.open(resp.link, "_blank");

    handleClose();
  };
  const openLink = () => {
    let a = document.createElement("a");
    if ((resp.link.includes("http://")) || (resp.link.includes("https://"))) {
      a.target = "_blank";
      a.href = resp.link;
      a.click();
     
    } else {
      let str = "https://" + resp.link;
      a.target = "_blank";
      a.href = str;
      a.click();
    }

    // window.location.href = "google.com"
    // window.open(resp.link,'_blank' );

    handleClose();
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ height: "294px" }}>
        <form onSubmit={handleSubmit}>
          <input
            type={"email"}
            required
            placeholder="Your@email.com"
            style={{ width: "100%" }}
            className="acc-inpu"
          />
          <button
            className="btn btn-ques"
            style={{ width: "100%", marginLeft: 0, textAlign: "left" }}
          >
            {t("sharing")}
          </button>
        </form>

        <div style={{}}>
          <button
            className="btn btn-ques"
            style={{ width: "100%", marginLeft: 0, textAlign: "left" }}
            onClick={openLink}
          >
            {t("withousharing")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ImmigrationModal;
