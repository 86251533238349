import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import {
  Menu,
  CloseSign,
  OpenBorderLogo,
  Clientlanguage,
  Torch,
  SidebarPng,
  SideBarLogo,
  Back,
  SideBarLogo1,
  SideBarLogo2,
  MenuIcon,
  CrossIcon
} from "../../assets";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { Side } from "../sidebar/index";
import "../../shared/styles/css/account.css";
import "../../shared/styles/css/tabbar.css";
import Loader from "../../shared/loader/loader";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, login } from "../../shared/Redux/Reducers/userSlice";
import ConfirmModal from "../../shared/component/Modals/confirmationModal";
import { useTranslation } from "react-i18next";
import { BiWorld } from "react-icons/bi"

import {
  AllRoute,
  clientInnerWidth,
  language,
} from "../../shared/util/constant";
import { Dropdown } from "react-bootstrap";
import { setLang } from "../../shared/Redux/Reducers/langSlice";
import { toastMessage, UpdateLanguageService } from "../../shared"
const Navigation = ({ label, width, sidebar, setSidebar, showSidebar, check }) => {
  // const [sidebar, setSidebar] = useState(true);
  const [clientWidth, setClientWidth] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [loader, setLoader] = useState(false)
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    user: { user },
    lang,
  } = useSelector((state) => state.root);

  const userRedux = useSelector((state) => state.root.user);


  const history = useHistory();
  const location = useLocation();
  const closeModal = () => setConfirmModal(false);

  const logoutUser = () => {
    closeModal();
    dispatch(logout());
  };
  useLayoutEffect(() => {
    function updateSize() {
      setClientWidth(window.innerWidth);
      width(window.innerWidth);
      if (window.innerWidth >= clientInnerWidth.width) {
        // console.log("window,", window.innerWidth);
        setSidebar(true);
      } else {
        setSidebar(false);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  const arr = ["/payment", "/history", "/signin", "/package-detail", "/package", "/news-detail"];
  const changeLanguage = (ln) => {


    let obj = {};
    if (ln == "en") {
      obj['language'] = 1
    }
    else {
      obj['language'] = 2
    }
    setLoader(true)
    UpdateLanguageService(obj)
      .then(({ data }) => {
        setLoader(false);
        let userObj = {
          access_token: userRedux.access_token,
          email: userRedux.email,
          password: userRedux.password,
          type: userRedux.type,
          user: data.data
        }
        dispatch(login(userObj))

        // toastMessage(data.message, "success");
      })
      .catch((err) => {

      });

    dispatch(setLang(ln));
    return () => {
      i18n.changeLanguage(ln);
    };
  };
  function handleBlur() {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(false);
    }
  }

  useEffect(() => {
    window.addEventListener("blur", handleBlur);
    return () => {
      window.removeEventListener("blur", handleBlur);
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="navigation">
        <IconContext.Provider>
          <nav className={sidebar ? "nav-menu active" : "nav-menu active"}>
            <ul className="nav-menu-items" onClick={showSidebar}>
              {clientWidth <= clientInnerWidth.width ? (
                <>
                  <li className="navbar-toggle">
                    <Link to="#" className="menu-bars2">
                      {/* <img src={CloseSign} /> */}
                      <CrossIcon />
                    </Link>
                  </li>
                  <img src={SideBarLogo2} width="120px" className="logoMargin" />
                  {/* <SideBarLogo1 style={{ width: "120px", height: "120px" }} /> */}
                </>
              ) : (
                <img src={SideBarLogo2} width="120px" className="logoMargin" />
                // <SideBarLogo1 className="sideBarlogos" />
              )}

              {Side().map((item, index) => {

                if (location.pathname.includes("/services/")) {
                  location['path'] = "/services"
                }
                if (location.pathname.includes("/news-detail")) {
                  location['path'] = "/news"
                }
                if (location.pathname.includes("/history") || location.pathname.includes("/payment") || location.pathname.includes("/account-detail") || location.pathname.includes("/about")) {
                  location['path'] = "/account"
                }



                return <li
                  key={index}
                  className={`${(AllRoute.includes(location.pathname) || AllRoute.includes(location.path)) &&
                    (location.pathname == item.path ||
                      location.pathname == item?.pathName
                      || location.path == item?.path) &&
                    "sideactive"
                    } height - 60px li-Nav`}
                >
                  <span className={item.cName}>
                    <Link to={item.path}>
                      {(AllRoute.includes(location.pathname) || AllRoute.includes(location.path)) &&
                        (location.pathname == item.path ||
                          location.pathname == item?.pathName
                          || location.path == item?.path)
                        ? item.icon.active
                        : item.icon.default}
                      <span className="items">{item.title}</span>
                    </Link>
                  </span>
                </li>
              })}
            </ul>
          </nav>
        </IconContext.Provider>

        {/* {clientWidth >= clientInnerWidth.width ? (
          <div
            className={`back ${clientWidth >= clientInnerWidth.width && "margin300"
              }`}
            onClick={() => history.goBack()}
          >
            {location?.pathname == `/ package / ${location?.state?.id} ` ||
              location?.pathname == `/ package - detail / ${location?.state?.id} ` ||
              location?.pathname.includes("/package-detail") ||
              location?.pathname.includes("/package") ? (
              <Back className="backAbsolute" style={{ marginTop: "28%" }} />
            ) : (
              ""
            )}
          </div>
        ) : (
          <>
            <div
              className={`back ${clientWidth >= clientInnerWidth.width && "margin300"
                } `}
              onClick={() => history.goBack()}
            >
              <Back className="backAbsolute" />
            </div>
            <div className="menu navbars">
              <Link to="#" className="menu-bars menuText">
                <p className="m-0" onClick={showSidebar}>
                  <MenuIcon className="MenuIcon" style={{ height: "24px", marginLeft: "5px" }} />
                </p>
              </Link>
            </div>
          </>
        )} */}

        <p className="navi-paragraph"> {label ? label : <>&nbsp;</>}</p>
        {/* {clientWidth <= clientInnerWidth.width && (
          <div className="menu navbars">
            <Link to="#" className="menu-bars menuText">
              <p onClick={showSidebar}>Menu</p>
            </Link>
          </div>
        )} */}
        <Dropdown className="mr-4 dropAbsolute">
          <Dropdown.Toggle id="dropdown-basic" className="drop-btn">
            {/* <Clientlanguage className="lng_mrgn defaultSvg" /> */}
            <BiWorld style={{ marginRight: "5px", marginTop: "-4px" }} />
            {lang.lang == language.eng || lang.lang == undefined
              ? "English"
              : "Español"}
            {/* {} */}
          </Dropdown.Toggle>

          <Dropdown.Menu className="menu-drop side">
            <Dropdown.Item className="dropItemsLang" onClick={() => changeLanguage("en")} href="#">
              English
            </Dropdown.Item>
            <Dropdown.Item className="dropItemsLang" onClick={() => changeLanguage("sp")} href="#">
              Español
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <ConfirmModal
        text={t("logoutMsg")}
        show={confirmModal}
        closeModal={closeModal}
        Call={logoutUser}
      />
      {loader && <Loader />}
    </>
  );
};

export default Navigation;
