import React, { useEffect, useState, useMemo } from "react";
import "../../shared/styles/css/account.css";
import countryList from "react-select-country-list";
// import Data from "../../data/data.json";
import Navigation from "../navigation";
import "../../shared/styles/css/schedule.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../shared/styles/css/datepicker.css";
import Modals from "../../shared/component/Modals/index";
import { browserName, browserVersion, isIOS } from "react-device-detect";
import { Form } from "react-bootstrap"
import "../../shared/styles/css/modal.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../shared/loader/loader";
import { getAnalytics, logEvent } from "firebase/analytics";
import DateComp from "./DateComp";
import { Spinner, Container } from "react-bootstrap";
import moment from "moment";
import momentTime from "moment-timezone";
import { ActiveSvg } from "../../assets";
import SchduleDate from "./ScheduleDate";
import GuestModal from "./GuestModel";
import PayModal from "../../shared/component/Modals/Pay";
import Congrats from "../../shared/component/Modals/Congrats";

import {
  getPackageServices,
  toastMessage,
  getAvailabilityType,
  getAvailabilityCalendar,
  getAvailabilityDate,
  bookConsultationService,
  getTimeZoneServices
} from "../../shared";
import { clientInnerWidth, language } from "../../shared/util/constant";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import Detail from "./detail";
const Schedule = () => {
  const {
    lang: { lang },
    user: { user },
  } = useSelector((state) => state.root);
  const [clientWidth, setClientWidth] = useState(0);
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("YYYY-MM-D")
  );
  const [show, setShow] = useState(false);
  const [showPay, setShowPay] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [loader, setLoader] = useState(false);
  const [dateArr, setDateArr] = useStateWithCallbackLazy([]);
  const [packageList, setPackageList] = useState([]);
  const [dateLoader, setDateLoader] = useState(false);
  const [acuityTypes, setAcuityTypes] = useState([]);
  const [acuityCalendars, setAcuitCalendars] = useState([]);
  const [tableSelectDate, setTableSelectedDate] = useState("");
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [selectedTimeArea, setSelectedTimeArea] = useState("Asia/Karachi");
  const [timeZoneArr, setTimeZoneArr] = useState([]);
  const [showPaidModal, setShowPaidModal] = useState(false);
  const modalClose = () => {
    setShowPaidModal(false);
    window.location.reload()

  }





  const TimeZone = () => {
    getTimeZoneServices().
      then((data) => {
        setTimeZoneArr(data.data)
        let temp = data.data.find((i) => i.time_zone_value == momentTime.tz.guess())
        setSelectedTimeZone(temp.time_zone)
        setSelectedTimeArea(temp.time_zone_value)
      })
      .catch((err) => { })
  }

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, "Consultation");
  }, [])

  const [state, setState] = useStateWithCallbackLazy({
    type: "",
    typeName: "",
    calendar: "",
    calendarName: "",
    dateobj: "",
    timeObj: null,
    withoutUtc: null,
  });
  const [fname, setFname] = useState(user?.first_name ? user?.first_name : "");
  const [lname, setLname] = useState(user?.last_name ? user?.last_name : "");
  const [email, setEmail] = useState(user?.is_guest ? "" : user?.email ? user?.email : "");
  const [phone, setPhone] = useState(
    user?.phone_no ? user.phone_no.slice(user.phone_no.length - 10).replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") : ""
  );
  const [street, setStreet] = useState(
    user?.user_address?.street_address ? user?.user_address?.street_address : ""
  );
  const [country, setCountry] = useState(
    user?.user_address?.country ? user?.user_address?.country : "United States"
  );
  const [states, setStates] = useState(
    user?.user_address?.state ? user?.user_address?.state : ""
  );
  const [city, setCity] = useState(
    user?.user_address?.city ? user?.user_address?.city : ""
  );
  const [zip, setZip] = useState(
    user?.user_address?.zip_code ? user?.user_address?.zip_code : ""
  );
  const [emailForUS, setEmailForUS] = useState("");
  const [fNameUS, setFNameUS] = useState("");
  const [lNameUS, setLNameUS] = useState("");
  const [check, setCheck] = useState(false);
  const [switchForm, setSwitchForm] = useState("pay");
  const [description, setDescription] = useState("");
  const [password, setpassword] = useState("");
  const [Confrmpassword, setConfrmPassword] = useState("");
  const [countryCode, setCountryCode] = useState(
    user?.user?.phone_no
      ? user.user.phone_no.substring(0, user.user.phone_no.length - 10)
      : "+1"
  );
  const [loginCheck, setLoginCheck] = useState(false);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const closeModal = () => setOpenModal(false);
  const options = useMemo(() => countryList().getData(), []);

  const getCountries = (options) => {
    let placehold = options.find((item) => item.label == country);
    setCountry(placehold);
  };






  const PackagePlan = () => {
    setLoader(true);
    Promise.all([getAvailabilityType(), getAvailabilityCalendar()])
      .then((res) => {
        setLoader(false);
        let accType = res[0];
        let accCalendar = res[1];
        const {
          data: { data },
        } = accType;
        let resp = accCalendar;
        let notInclude = [
          "Meeting with Current Client",
          "Reunión con el Cliente Actual",
        ];
        setAcuitCalendars(resp.data.data);
        let arr = data
          .map((response) => {
            if (!notInclude.includes(response.name)) {
              return response;
            }
            return;
          })
          .filter((ii) => ii !== undefined);
        setAcuityTypes(arr);
      })
      .catch((err) => setLoader(false));

    // getPackageServices()
    //   .then(({ data: { data } }) => {
    //     setPackageList(data);
    //     setLoader(false);
    //   })
    //   .catch((err) => {
    //
    //     setLoader(false);
    //   });
  };




  useEffect(() => {
    TimeZone();
    getCountries(options);
    PackagePlan();
  }, []);

  useEffect(() => {
    setDateArr(dateArr);
  }, [dateArr]);
  const openModalService = () => {
    if (!selectedDate) {
      toastMessage(t("consultationDate"), "error");
    } else if (!selectedTime) {
      toastMessage(t("consultationTime"), "error");
    } else {
      setOpenModal(true);
    }
  };

  // const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
  //   <button className="example-custom-input" onClick={onClick} ref={ref}>
  //     {value}
  //   </button>
  // ));
  const handleModalClose = () => {
    setShow(false)
  };
  const handleModalPay = () => setShowPay(false);

  const selectConsultantType = (item, stteParam) => {
    console.log("item", item, stteParam)
    // if (user?.is_guest == 1) {
    //   setShow(true);
    //   // history.push("/signIn")
    // } else {
    let cloneState = { ...state };
    setDateArr([]);
    let objState = cloneState;
    if (cloneState.type == "" && stteParam == "calendar") {
      toastMessage(t("consultantError"), "error");
    } else if (stteParam != "calendar") {
      objState = {
        ...cloneState,
        [stteParam]: item?.id,
        calendarName: item?.name,
        typeName: "",
        calendar: "",

        dateobj: null,
      };
    } else {
      objState = {
        ...cloneState,
        [stteParam]: item?.id,
        typeName: item?.name,
        dateobj: null,
      };
    }

    if ("calendar" == stteParam) {
      if (objState.type) {
        getDate(objState, selectedDate, true);
      }
    } else if (objState.type && objState.calendar) {
      getDate(objState, selectedDate, true);
    }

    setState(objState, (values) => {
      objState = values;

      setState(values);
    });


  };

  useEffect(() => {
    TimeZone();
    getDate({
      calendar: 16496767,
      calendarName: "Shahid Haque",
      dateobj: null,
      timeObj: null,
      type: 2420243,
      typeName: "Video or Phone Consultation",
      withoutUtc: null
    }, selectedDate, true)
  }, [])




  const getDate = (objst, selectDate, needLoaderOrNot, copyDateArr, is_previous) => {

    let stateObj = objst ? objst : state;
    var today = moment(selectDate).format("YYYY-MM-DD");
    // var dd = String(today.getDate()).padStart(2, "0");
    // var mm = String(today.getMonth() + 1).padStart(2, "0");
    // var yyyy = today.getFullYear();
    // today = yyyy + "-" + mm + "-" + dd;


    let obj = {
      month: today,
      appointmentTypeID: stateObj?.calendar
        ? stateObj?.calendar
        : state.calendar,
      calendarID: stateObj?.type ? stateObj?.type : state.type,
      // timezone: momentTime.tz.guess(),
      timezone: typeof copyDateArr == "string" ? copyDateArr : selectedTimeArea,
    };

    if (is_previous != undefined) {
      obj['is_previous'] = is_previous
    }

    if (needLoaderOrNot) {
      setDateLoader(true);
    }



    getAvailabilityDate(obj)
      .then(({ data }) => {
        setDateLoader(false);
        if (is_previous) {
          let temp = data.data.reverse();
        }
        let temp = data.data;

        if (temp?.length) {
          let arrClone = copyDateArr && (typeof copyDateArr != "string") ? copyDateArr : dateArr;
          let cloneArr = [...arrClone];
          let getLastEle = temp[temp.length - 1];
          let lastDate = moment(getLastEle[0]?.time)
            .add(1, "d")
            .format("YYYY-MM-D");
          let initDate = moment(data.data[0][0]?.time).format("YYYY-MM-D");
          setSelectedDate(initDate);

          // let respon = temp.map((ii) => new Date(ii.date));
          let findIndx = cloneArr.findIndex(
            (ii) => ii?.initialDate == initDate
          );

          if (findIndx < 0) {
            cloneArr.push({
              AvailDate: data.data,
              lastDate: lastDate,
              initialDate: initDate,
            });
          } else {
            cloneArr[findIndx] = {
              AvailDate: data.data,
              lastDate: lastDate,
              initialDate: initDate,
            };
          }

          setDateArr(cloneArr);
          setState(
            {
              ...stateObj,

              dateobj: null,
              timeObj: null,
              withoutUtc: null,
            },
            (values) => {
              setState(values);
            }
          );
        } else {
          setDateArr([]);
          setSelectedDate(new Date());
          setState(
            {
              ...stateObj,

              dateobj: null,
              timeObj: null,
              withoutUtc: null,
            },
            (values) => {
              setState(values);
            }
          );
        }
      })
      .catch((err) => {
        setDateLoader(false);
      });
  };
  const setDate = (dte) => {
    setState({
      ...state,
      dateobj: dte,
      timeObj: null,
      withoutUtc: null,
    });
  };
  const setTime = (tim) => {
    const { time, withoutUtc } = tim;

    setState({
      ...state,
      dateobj: state.dateobj,
      timeObj: time,
      withoutUtc: withoutUtc,
    });
  };


  const handleSubmit = () => {
    setShowPay(true);
    // if (!user?.is_guest) {
    //   if (user.is_complete_profile) {
    //     if (user.stripe_payment_method) {
    //       setLoader(true);
    //       let obj = {
    //         user_id: user.id,
    //         date: moment(state.dateobj).format("YYYY-MM-D"),
    //         consultation_time: moment(state.dateobj).format("HH:mm"),
    //         consultation_id: 1,
    //         consultation_with: state.calendarName,
    //         is_web: 1,
    //         amount: 300,
    //         stripe_token: user?.stripe_token,
    //         appointmentTypeID: state.calendar,
    //         calendarID: state.type,
    //         datetime: state.dateobj,
    //         street_address: street,
    //         city: city,
    //         state: states,
    //         zip_code: zip,
    //         country: country.label,
    //         description: description,
    //         term_and_condition: check,
    //       };
    //       bookConsultationService(obj)
    //         .then(({ data }) => {
    //           closeModal();
    //           setLoader(false);
    //           setState({
    //             type: "",
    //             typeName: "",
    //             calendar: "",
    //             calendarName: "",
    //             dateobj: null,
    //             timeObj: null,
    //             withoutUtc: null,
    //           });
    //           history.push("/history");
    //           toastMessage(data?.message, "success");
    //         })
    //         .catch((err) => {
    //           setLoader(false);
    //           toastMessage(err?.response?.data?.message, "error");
    //         });
    //     } else {
    //       toastMessage(t("addPaymentDetail"), "error");
    //     }
    //   } else {
    //     toastMessage(t("CompleteProfileError"), "error");
    //   }
    // } else {
    //   history.push("/signIn");
    //   // toastMessage("You are Guest User, First Create Your Account", "error");
    // }
  };
  const hideSideBar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(false);
    }
  };
  const [sidebar, setSidebar] = useState(true);
  const showSidebar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(!sidebar);
    }
  };

  const TimeZoneSelection = (e) => {
    setSelectedTimeZone(e.target.value);
    let temp = timeZoneArr.find((i) => i.time_zone == e.target.value);
    setSelectedTimeArea(temp.time_zone_value);
    setDateLoader(true)
    getDate({
      calendar: 16496767,
      calendarName: "Shahid Haque",
      dateobj: null,
      timeObj: null,
      type: 2420243,
      typeName: "Video or Phone Consultation",
      withoutUtc: null
    }, selectedDate, true, temp.time_zone_value)
  }



  // useEffect(() => {
  //   getDate();
  // }, [selectedTimeArea])
  const checkPassword = () => {
    if (password !== Confrmpassword) {
      toastMessage("The password confirmation does not match", "success")
      return false
    } else {
      return true
    }
  }
  const handleGuestPay = () => {
    if (loginCheck && password.length == "" && Confrmpassword.length == "") {
      toastMessage("Enter Password and Confirm Password", "error")
    } else {
      let check = checkPassword()
      if (check) {
        setShow(true)
      }
    }
  }
  return (
    <>
      <Navigation
        label={t("consultation")}
        width={setClientWidth}
        onPress={() => history.goBack()}
        sidebar={sidebar}
        showSidebar={showSidebar}
        setSidebar={setSidebar}
      />

      <div
        style={
          { height: "auto" }
        }
        className={`main ${clientWidth > clientInnerWidth.width && "margin300"
          }`}
        onClick={hideSideBar}
      >
        <div className="container cont-acc1 schContainer"
          style={(browserName == "Mobile Safari" || isIOS) ? { marginBottom: "50%" } : {}}
        >
          {loader ? (
            ""
          ) : (<>
            <h3 className="consultationHeading">{t("consultationNote")}</h3>
            <div className="w-100 consultationPara font16" style={{ paddingTop: "2%" }}>
              {/* <strong className="lightBlack">
              
              </strong> */}
              <div className="lightBlack">
                {t("consultationPara")}
              </div>



            </div>
            <p className="marginTop10 lightBlack font16">📥  {t("consultationDoc")}</p>

            <strong className="lightBlack font18">💳 {t("consultingFee")}</strong>
          </>)}
          {/* <div className="name-div">
            {loader ? "" : <label className="mb-2 labelTitle">{t("Attorneys")}</label>}
            <div>
              <div className="">
                {acuityCalendars?.map((item, inx) => {
                  return (
                    <div
                      onClick={() => selectConsultantType(item, "type")}
                      key={inx}
                      className={
                        state.type == item.id
                          ? "ipt-active my-1 mr-10 width-230 selected "
                          : "ipt-s  my-1 mr-10 width-230 selected"
                      }
                    >
                      <div className="schRespDiv">
                        <div className="schBtn">
                          <img
                            src={`http:${item.image}`}
                            className="consult_img"
                          />
                          <div style={{ margin: "2% 0% 0% 3%" }}>
                            <div className="consult-Name">{item?.name}</div>
                            <div
                              style={{ fontSize: "12px" }}
                              className="consult-Name"
                            >
                              {item?.name == "Erick Valencia"
                                ? "(English, Español)"
                                : "(English)"}
                            </div>
                          </div>
                        </div>
                        <div>
                         
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {state.type && (
            <div className="name-div">
              <label className="labelTitle">{t("Language")}</label>
              <div className="d-flex flex-wrap justify-content-between">
                {acuityTypes?.map((item, inx) => {
                  return (
                    item.calendarIDs.includes(state.type) && (
                      <div
                        disabled="true"
                        onClick={() => selectConsultantType(item, "calendar")}
                        key={inx}
                        style={{ width: "100%" }}
                        className={
                          state.calendar == item.id &&
                            item.name == state.typeName
                            ? "ipt-active my-1 mr-10 width-230 selected "
                            : "ipt-s  my-1 mr-10 width-230 selected"
                        }
                      >
                        <div
                          className="schRespDiv"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div className="typeDiv">{item?.name}</div>
                          <div>
                            
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>

            </div>
          )} */}

          <div>
            {dateArr?.length > 0 &&
              <>
                <label className="mt-4 labelTitle">{t("TimeSlots")}</label>
                <label className="w-100 text-center">
                  {t("TimeZone")}
                </label>
                <Form.Select className="selectTimeZone" aria-label="Default select example" value={selectedTimeZone} onChange={(e) => TimeZoneSelection(e)}>
                  {
                    timeZoneArr.map((i) => {
                      return (
                        <>
                          <option>{i.time_zone}</option>
                        </>
                      )
                    })
                  }
                </Form.Select>
              </>}

            {dateLoader ? (
              <Spinner animation="border" role="status" className="dateLoader">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <SchduleDate
                timeZoneArr={timeZoneArr}
                dateArr={dateArr}
                setDateArr={setDateArr}
                selectedDate={selectedDate}
                state={state}
                setState={setState}
                getAvailabilityDate={getDate}
                dateLoader={dateLoader}
                selectedTimeAreas={selectedTimeArea}
              />
            )}
            {state.dateobj && (
              <Detail
                fname={fname}
                setFname={setFname}
                lname={lname}
                setLname={setLname}
                email={email}
                setEmail={setEmail}
                phone={phone}
                setPhone={setPhone}
                street={street}
                setStreet={setStreet}
                country={country}
                setCountry={setCountry}
                states={states}
                setStates={setStates}
                city={city}
                setCity={setCity}
                zip={zip}
                setZip={setZip}
                check={check}
                setCheck={setCheck}
                description={description}
                setDescription={setDescription}
                setEmailForUS={setEmailForUS}
                emailForUS={emailForUS}
                fNameUS={fNameUS}
                setFNameUS={setFNameUS}
                lNameUS={lNameUS}
                setLNameUS={setLNameUS}
                setLoginCheck={setLoginCheck}
                loginCheck={loginCheck}
                setConfrmPassword={setConfrmPassword}
                Confrmpassword={Confrmpassword}
                password={password}
                setpassword={setpassword}
                setCountryCode={setCountryCode}
                countryCode={countryCode}
              />
            )}

            {/* <SchduleDate dateArr={dateArr} /> */}

            {user?.is_guest == 0 && state.typeName &&
              state.calendarName &&
              state.dateobj &&
              check &&
              fname &&
              lname &&
              email &&
              phone &&
              street &&
              country &&
              states &&
              city &&
              zip &&
              description ? (
              <button
                className="btn btnSch"
                disabled={
                  state.typeName &&
                    state.calendarName &&
                    state.dateobj &&
                    check &&
                    fname &&
                    lname &&
                    email &&
                    phone &&
                    street &&
                    country &&
                    states &&
                    city &&
                    zip &&
                    description
                    ? false
                    : true
                }
                onClick={handleSubmit}
              >
                {" "}
                {t("startCon")}{" "}
              </button>
            ) : user?.is_guest && state.typeName &&
              state.calendarName &&
              state.dateobj &&
              fname &&
              check &&
              lname &&
              email &&
              phone &&
              street &&
              country &&
              states &&
              city &&
              zip &&
              // Confrmpassword &&
              // password &&
              description.length > 0 ? (
              <button
                className="btn btnSch"
                onClick={() => handleGuestPay()}
              >
                {" "}
                {t("guestPay")}{" "}
              </button>
            ) : ""}
          </div>
        </div>
        <GuestModal resetCloseModal={"pay"} setShowPaidModal={setShowPaidModal} Logincheck={loginCheck} switchForm={switchForm} consultation={{ fname, check, lname, calendarID: state.type, appointmentTypeID: state.calendar, date: state.dateobj, email, phone: (countryCode + phone.split("-").join("")), street, country, states, city, zip, description, date: state?.dateobj, password, Confrmpassword, consultation_with: state?.calendarName }} setSwitchForm={setSwitchForm} show={show} handleClose={handleModalClose} />

        <PayModal
          show={showPay}
          handleClose={handleModalPay}
          description={description}
          typeName={state.typeName}
          calendarName={state.calendarName}
          dateobj={state.dateobj}
          state={state}
          timeZoneArea={selectedTimeArea}
          setShowPaidModal={setShowPaidModal}
        />
      </div>

      {loader && <Loader />}
      <Congrats
        show={showPaidModal}
        handleClose={modalClose}
      />
    </>
  );
};

{
  /* <DateComp
                  state={state}
                  check={state.calendar}
                  dateArr={dateArr}
                  setDate={setDate}
                  setTime={setTime}
                  datees={state.dateobj}
                  time={state.timeObj}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  getDate={(dte)=>getDate(state,dte,false)}
                /> */
}

{
  /* {openModal && (
        <Modals
          show={openModal}
          closeModal={closeModal}
          label={t("continue")}
          title={t("consultant")}
          datetoConsult={selectedDate}
          timetoConsult={selectedTime}
        />
      )} */
}

export default Schedule;
