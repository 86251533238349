import { HTTP_Request } from "../util/config";
import { Endpoint } from "../util/endpoint";

import axios from "axios";
const getAvailabilityDate = (obj) => {
  var FormData = require('form-data');
  var data = new FormData();
  Object.keys(obj).forEach((key) => {
    data.append(key, obj[key])
  })
  return HTTP_Request.post(Endpoint.accuityDate, data)
}

const getAvailabilityTime = (obj) => {
  var FormData = require('form-data');
  var data = new FormData();
  Object.keys(obj).forEach((key) => {
    data.append(key, obj[key])
  })
  return HTTP_Request.post(Endpoint.accuityTimes, data)
}

const getAvailabilityCalendar = () => {
  return HTTP_Request.get(Endpoint.accuityCalendar)
}
const getAvailabilityType = () => {
  return HTTP_Request.get(Endpoint.accuityTypes)
}
export {
  getAvailabilityType,
  getAvailabilityCalendar,
  getAvailabilityTime,
  getAvailabilityDate
}
