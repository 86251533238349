import React from "react";
import { AnimatedLogo, MainLogo, EndLogo, TMLogo } from "../../../assets";
import Animation from "./LogoAnimation";
function Logo() {
  return (
    <div className="logoOnGuide">
      <div>
        <Animation Pic={TMLogo} />
      </div>
    </div>
  );
}

export default Logo;
