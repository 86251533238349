import React, { useState, useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { ApplePay, GooglePay } from "../../assets/index";
import { useTranslation } from "react-i18next";
import GooglePayButton from "@google-pay/button-react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { hasLoadedNamespace } from "i18next";
import { getAnalytics, logEvent } from "firebase/analytics";
import CreditCardInput from "react-credit-card-input";
import { HiCreditCard } from "react-icons/hi";
import { FiUser } from "react-icons/fi";
import { paymentRequest } from '../util/constant'
import { toastMessage } from '../component/toast'
import { publicSubcriptionPaymentService, publicbookConsultationService } from "../../shared";
import { login } from "../Redux/Reducers/userSlice";
import { useDispatch } from 'react-redux'
import moment from 'moment'
function PayForm({
  handleClose,
  check,
  PackageDetail,
  quantity,
  Name,
  setSwitchForm,
  Logincheck,
  priceCheck, guestForm, setGuestForm, consultation, setShowPaidModal }) {
  const {
    lang: { lang },
    user: { user },
  } = useSelector((state) => state.root);


  const dispatch = useDispatch()
  const { t, i18n } = useTranslation();
  const [planName, setPlanName] = useState("");
  const [planSecret, setPlanSecret] = useState("");
  const [bookingStatus, setBookingStatus] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cvc, setCVC] = useState("");
  const [expiry, setExpiry] = useState("");
  const [errorCard, setErrorCard] = useState("");
  const [errorCVC, setErrorCVC] = useState("");
  const [errorExpiry, setErrorExpiry] = useState("");
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");

  const options = {
    // clientSecret: '{{CLIENT_SECRET}}',
  };

  //pk_test_51JzZuXHlJ57SdeJaeSzuMD7oHsPQa05OesccvVzDKFDsjpVVQWyQIT1EyrI4FfAQNWZNzWfkhc6cuiz5kk6NVqhP00EnouzOCE

  //pk_live_qnJs6x0H3LdgCPIOsSIoIVjw

  //pk_live_51JNtd5EEWahky04aHMAYMeda92xC0T3tGlRv1pxAlzMxVq9Dx26qRcGZoWg4UNlVBS2QH7P6iv4H5Nsz81lG1lpx00OsBH3ThR

  // const [token, setToken] = useState("");

  const history = useHistory();

  const [isSupported, setIsSupported] = useState(false);

  function beginApplePay() {
    try {
      var session = null;
      if (check) {
        var paymentRequest = {
          countryCode: "US",
          currencyCode: "USD",
          // lineItems: [
          //   {
          //     type: "final",
          //     label: "Payin",
          //     amount: "15.00",
          //   }
          // ],
          // merchantInfo: {
          //   merchantId: "merchant.com.cp.OpenBorders",
          //   merchantName: "Open Borders",
          // },

          total: {
            label: !priceCheck
              ? `${Name} for $${PackageDetail.price}`
              : priceCheck == 1
                ? `${Name} , $${PackageDetail.price} for every month`
                : `${Name}, $${PackageDetail.price} for every month for ${priceCheck} months `,
            amount: PackageDetail.price,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        };
        session = window.Stripe.applePay.buildSession(
          paymentRequest,
          function (result, completion) {
            if (consultation) {
              ConsultationPay(result.token.id);
            } else {
              ServicePay(result.token.id);
            }
            // ServicePay(result.token.id);
            completion(true);
          }
        );
      } else {
        var paymentRequest2 = {
          countryCode: "US",
          currencyCode: "USD",

          // lineItems: [
          //   {
          //     type: "final",
          //     label: "Payin",
          //     amount: "15.00",
          //   }
          // ],

          total: {
            label: "Border Crossing Law Firm for Consultation",
            amount: 300,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        };

        session = window.Stripe.applePay.buildSession(
          paymentRequest2,
          function (result, completion) {
            ServicePay(result.token.id);
            completion(true);
          }
        );
      }
      console.log("session", session);
      session.begin();
    } catch (err) {
      toastMessage("Apple Pay is not supported in this browser", "error");
    }
  }

  useEffect(() => {
    //change token here as well with live
    window.Stripe.setPublishableKey("pk_live_qnJs6x0H3LdgCPIOsSIoIVjw");
    // for internal window.Stripe.setPublishableKey("pk_test_51JzZuXHlJ57SdeJaeSzuMD7oHsPQa05OesccvVzDKFDsjpVVQWyQIT1EyrI4FfAQNWZNzWfkhc6cuiz5kk6NVqhP00EnouzOCE");
    // window.Stripe.setPublishableKey("pk_test_M4kc0ugwOFFrcPtUyxdpgKMG");

    window.Stripe.applePay.checkAvailability(function (available) {
      console.log("available", available);
      if (available) {
        setIsSupported(true);
        console.log("Supported");
      } else {
        setIsSupported(true);
        console.log("Not Supported");
      }
    });
  }, []);




  if (!check) {
    paymentRequest["transactionInfo"] = {
      totalPriceStatus: "FINAL",
      totalPriceLabel: "Total",
      totalPrice: "300.00",
      currencyCode: "USD",
      countryCode: "US",
    };
  } else {
    if (quantity == 1 && Object.keys(PackageDetail).length) {
      paymentRequest["transactionInfo"] = {
        totalPriceStatus: "FINAL",
        totalPriceLabel: "Total",
        totalPrice: PackageDetail.price.toString(),
        currencyCode: "USD",
        countryCode: "US",
      };
    } else {
      if (Object.keys(PackageDetail).length) {
        paymentRequest["transactionInfo"]['totalPrice'] = (
          PackageDetail.price * quantity
        ).toString();
      }
    }
  }



  const ServicePay = (token, cardId) => {
    let resp = {
      stripe_payment_method: token,
      plan_id: PackageDetail.plan_id,
      price_id: PackageDetail.id,
      user_id: user?.id,
      is_web: 1,
      first_name: guestForm?.fname,
      last_name: guestForm?.lname,
      email: guestForm?.email,
      phone_no: guestForm?.phone,
      street_address: guestForm?.city ? guestForm?.stAddress : "",
      city: guestForm?.city ? guestForm?.city : "",
      state: guestForm?.state ? guestForm?.state : "",
      zip_code: guestForm?.zip ? guestForm?.zip : "",
      country: guestForm?.country?.label ? guestForm?.country?.label : "",
    };
    // amount:guestForm?.fname,
    if (Logincheck) {
      resp["password"] = guestForm?.password
      resp["password_confirmation"] = guestForm?.confrmPassword
      resp["is_login"] = 1
    }
    if (guestForm?.pEmail && guestForm?.pEmail?.length > 0) {
      resp['petitioner_email'] = guestForm?.pEmail;
      resp['is_us_citizen'] = 1
    }
    if (guestForm?.pFname && guestForm?.pFname?.length > 0) {
      resp['petitioner_firstname'] = guestForm?.pFname;
    }
    if (guestForm?.plName && guestForm?.plName?.length > 0) {
      resp['petitioner_lastname'] = guestForm?.plName;
    }

    if (quantity) {
      resp["quantity"] = Number(quantity);
    } else {
      resp["quantity"] = 1;
    }
    setLoader(true)
    publicSubcriptionPaymentService(resp).then(({ data }) => {
      if (data?.status) {
        if (data?.data?.access_token) {
          dispatch(login(data.data));
        }

        setSwitchForm("payment");
        setShowPaidModal(true)
        handleClose()
        resetForm()
        // toastMessage(data?.message, "success");
      } else {
        toastMessage(data?.message, "error");
      }
    }).catch(err => {
    }).finally(() => {
      setLoader(false)
    })
  };
  const ConsultationPay = (token, cardId) => {
    let resp = {
      appointmentTypeID: consultation?.appointmentTypeID,
      calendarID: consultation?.calendarID,
      stripe_token: token,
      consultation_id: 1,
      consultation_with: consultation?.consultation_with,
      user_id: user?.id,
      is_web: 1,
      amount: 300,
      first_name: consultation?.fname,
      last_name: consultation?.lname,
      email: consultation?.email,
      phone_no: consultation?.phone,
      description: consultation?.description,
      country: consultation?.country ? consultation?.country?.label : "",
      zip_code: consultation?.zip,
      state: consultation?.states,
      city: consultation?.city,
      street_address: consultation?.street,
      term_and_condition: consultation?.check,
      date: moment(consultation?.date).format("YYYY-MM-DD"),
      consultation_time: moment(consultation?.date).format("h:mm"),
      datetime: consultation?.date,
    };
    if (Logincheck) {
      resp["password"] = consultation?.password
      resp["password_confirmation"] = consultation?.Confrmpassword
      resp["is_login"] = 1
    }

    setLoader(true)
    publicbookConsultationService(resp).then(({ data }) => {
      if (data?.status) {
        if (data?.data?.access_token) {
          dispatch(login(data.data));

        }
        setShowPaidModal(true)
        // toastMessage(data?.message, "success");
        // setTimeout(() => {
        //   window.location.reload()
        // }, 2000);
        handleClose()

      } else {
        toastMessage(data?.message, "error");
      }
    }).catch(err => {
      toastMessage(err?.response?.data?.message, "error");
    }).finally(() => {
      setLoader(false)
    })
  }



  const ELEMENTS_OPTIONS = {
    // clientSecret: '{{CLIENT_SECRET}}',
  };

  const paymentViaCard = () => {
    if (cardNumber.length == 0 || cvc.length == 0 || expiry.length == 0) {
      toastMessage("Please complete all of the required information.", "error");
    } else {
      window.Stripe.createToken(
        {
          number: cardNumber,
          cvc: cvc,
          exp_month: expiry.split("/")[0].replace(" ", ""),
          exp_year: expiry.split("/")[1].replace(" ", ""),
          name: name,
        },
        function (result, response) {
          if (response?.hasOwnProperty("error")) {
            toastMessage(response?.error?.message, "error");
            // handleClose(true);
          } else {
            if (response.hasOwnProperty("id")) {
              if (consultation) {
                ConsultationPay(response.id, response.card.id);
              } else {

                ServicePay(response.id, response.card.id);
              }
              //   handleClose(true);
            }
          }
          // Handle result.error or result.token
        }
      );
    }
  };

  const getCVC = (e) => {
    if (e.target.value < 0) {
      setCVC(0);
    } else if (e.target.value > 999) {
      setCVC(999);
    } else {
      setCVC(e.target.value);
    }
  };
  const resetForm = () => {
    console.log("hevyguestForm", guestForm)
    Object.keys(guestForm).forEach((key, inx) => {

      console.log("key", key)
      setGuestForm({
        ...guestForm,
        [key]: ""
      })
    })
  }

  return (
    <>
      <Button
        className="btnGuest modalPay payMarginBottom applePay"
        onClick={() => beginApplePay()}
        disabled={!isSupported}
      >
        <img
          src={ApplePay}
          style={{ height: "25px", marginBottom: "2px" }}
        />{" "}
        Pay
      </Button>
      <GooglePayButton
        buttonSizeMode="fill"
        className="googlePlaybtn"
        buttonType="short"
        environment="PRODUCTION"
        // environment="TEST"
        paymentRequest={paymentRequest}
        onPaymentAuthorized={() => ({ transactionState: "SUCCESS" })}
        onLoadPaymentData={(paymentRequest) => {
          console.log("paymentRequest", paymentRequest)
          if (consultation) {
            ConsultationPay(
              JSON.parse(
                paymentRequest.paymentMethodData.tokenizationData.token
              ).id
            );
          } else {
            ServicePay(
              JSON.parse(
                paymentRequest.paymentMethodData.tokenizationData.token
              ).id
            );
          }

        }}
      />
      <div className="lineDiv w-100">
        <div className="line"></div>
        <div className="lineText">{t("continueWith")}</div>
        <div className="line"></div>
      </div>

      <div
        style={{ height: "125px", position: "relative", margin: "0 auto", marginBottom: "91px" }}
      >
        <div
          className="w-100"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <CreditCardInput
            style={{ width: "inherit" }}
            onError={({ inputName, err }) =>
              console.log(`credit card input error: ${err}`)
            }
            cardExpiryInputProps={{
              onBlur: (e) => console.log("expiry blur", e),
              onChange: (e) =>
                console.log("expiry change", setExpiry(e.target.value)),
              onError: (err) => console.log(`expiry error: ${err}`),
            }}
            cardNumberInputProps={{
              onBlur: (e) => console.log("number blur", e),
              onChange: (e) =>
                console.log("number change", setCardNumber(e.target.value)),
              onError: (err) => console.log(`number error: ${err}`),
            }}
            customTextLabels={{
              cardNumberPlaceholder: "Card Number",
            }}
            fieldClassName="ClassError"
          />
        </div>

        <div className="cardDiv">
          <input
            className="inputCard firstCardIpt"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Card Holder"
          />
          <input
            className="inputCard secondCardIpt"
            placeholder="CVC"
            value={cvc}
            onChange={(e) => getCVC(e)}
          />
        </div>
      </div>
      <div className="guestFootShadow text-center">
        <button
          onClick={() => paymentViaCard()}
          disabled={loader}
          className="btn  btn-main btn-pack"
          style={{ fontSize: "18.72px" }}
        >
          {
            loader ? <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner> : t("guestPay")
          }


        </button>
        <div className="text-center">
          <span className="font15">{t("already-account")}</span>
          <span
            className="font15 marginleft6 font600 pointer"
            onClick={() => setSwitchForm("signin")}
          >
            {t("signIn")}

          </span>
        </div>
      </div>

    </>
  )
}

export default PayForm
