import React, { useEffect, useState } from "react";
import "../../shared/styles/css/account.css";
import Data from "../../data/data.json";
import Navigation from "../navigation";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getServiceHistory } from "../../shared";
import Loader from "../../shared/loader/loader";
import { clientInnerWidth } from "../../shared/util/constant";
import { getAnalytics, logEvent } from "firebase/analytics";
import Congrats from "../../shared/component/Modals/Congrats";
import moment from "moment";
import { browserName, browserVersion, isMobile, isIOS } from "react-device-detect";


const History = () => {
  let img_Url = "https://admin.openborders.io/public/";
  const history = useHistory();
  const location = useLocation();


  const [showModal, setShowModal] = useState(location?.paid ? true : false);
  const modalClose = () => setShowModal(false);

  const [clientWidth, setClientWidth] = useState(0);
  const [pkgHistory, setPkgHisotry] = useState([]);
  const [schHistory, setSchHistory] = useState([]);
  const [loader, setLoader] = useState(false);
  const { t, i18n } = useTranslation();


  const ServiceHisotry = () => {
    setLoader(true);

    getServiceHistory()
      .then(({ data: { data } }) => {
        setLoader(false);
        setPkgHisotry(data?.purches_plan_history);
        setSchHistory(data?.consultation_booking_detail);
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  useEffect(() => {
    ServiceHisotry();
  }, []);
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(!sidebar);
    }
  };
  const hideSideBar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(false);
    }
  };

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'Services History');
  }, [])
  return (
    <>
      <Navigation
        label={t("order-history")}
        width={setClientWidth}
        onPress={() => history.goBack()}
        sidebar={sidebar}
        showSidebar={showSidebar}
        setSidebar={setSidebar}
      />

      <div
        className={`main ${clientWidth > clientInnerWidth.width && "margin300"
          }`}
        onClick={hideSideBar}
        style={(browserName == "Mobile Safari" || isIOS) ? { marginBottom: "25%" } : {}}
      >
        <ul class="nav nav-tabs nav-his" id="myTab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="home-tab"
              data-toggle="tab"
              href="#home"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              {t("services")}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="profile-tab"
              data-toggle="tab"
              href="#profile"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              {t("consultation")}
            </a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            {pkgHistory.length > 1 ? <p className="paragraph-home">{t("recently")}</p> : ""}

            <div className="container cont-news">
              <div className="row d-flex justify-content-center w-100">
                <div className="row">
                  {pkgHistory &&
                    pkgHistory.map(({ plan }) => {
                      return (
                        <>
                          <div
                            className="col-6 col-his packageHistory mt-4"
                            style={
                              pkgHistory.length == 1
                                ? { width: "90%" }
                                : { width: "48%" }
                            }
                            onClick={() => {
                              history.push({
                                pathname: "/services/" + plan.slug,
                                state: plan,
                                type: "history",
                              });
                              // if (plan.plan_type == "recurring") {
                              //   history.push({
                              //     pathname: "/package-detail/" + plan.slug,
                              //     state: plan,
                              //     type: "history",
                              //   });
                              // } else {

                              // }
                            }}
                          >
                            <img
                              className="img-news"
                              src={img_Url + plan?.image}
                              style={{
                                borderRadius: "5px",
                                margin: "30px 0px 30px 0px",
                                width: "125px",
                                height: "90px",
                                objectFit: "cover",
                                objectPosition: "25% 25%"
                              }}
                            />
                            <div
                              className="descrptn_cont"
                              style={{ marginLeft: "2%" }}
                            >
                              <p className="head-news"> {plan?.plan_name}</p>
                              {/* <div className="desc-news">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: plan?.description,
                                  }}
                                ></div>
                              </div> */}
                              {/* <p
                                className="view-news"
                                
                              >
                                View Details&gt;&gt;
                              </p> */}
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            {schHistory.length > 1 ? <p className="paragraph-home">{t("schedule-history")}</p> : ""}

            <div className="container cont-news">
              <div className="row d-flex justify-content-center widthHistory marginHistory">
                <div className="row">
                  {schHistory &&
                    schHistory
                      .sort((a, b) => b - a)
                      .map((plan, inx) => {
                        return (
                          <>
                            <div
                              className="col-6 col-his mt-4"
                              key={inx}
                              style={{ width: "100%" }}
                            >
                              {/* <img className="img-news" src={img_Url + plan?.image} /> */}
                              <div
                                className="descrptn_cont"
                                style={{ marginLeft: "2%" }}
                              >
                                <p
                                  className="head-news"
                                  style={{ paddingTop: "2%" }}
                                >
                                  {" "}
                                  {plan?.consultation_with}
                                </p>
                                {/* moment(timetoConsult).format("HH:mm") */}
                                <div className="sch-history">
                                  <p>
                                    {t("sch-date")}{" "}
                                    {moment(plan?.date).format("MMMM Do YYYY")}
                                    &nbsp;
                                  </p>
                                  <p>
                                    {t("at")} {plan?.consultation_time}
                                  </p>
                                </div>
                                {/* <div className="sch-status">
                                {t("status")} {plan?.status}
                              </div> */}
                                {/* <div> on date:
                                {plan?.date}
                              </div>
                              <div> at:
                                {plan?.consultation_time}
                              </div> */}
                                <div className="desc-sch"></div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <p className="paragraph-home">{t('recently')}</p>

        <div className="container cont-news">
          <div className="row d-flex justify-content-center">
            <div className="row">
              {pkgHistory &&
                pkgHistory.map(({ plan }) => {
                  return (
                    <>
                      <div className="col-6 col-his">
                        <img className="img-news" src={img_Url + plan?.image} />
                        <div className="descrptn_cont">
                          <p className="head-news"> {plan?.plan_name}</p>
                          <div className="desc-news">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: plan?.description,
                              }}
                            ></div>
                          </div>
                          <p
                            className="view-news"
                            onClick={() => {
                              if (plan.plan_type == 'recurring') {
                                history.push({
                                  pathname: '/package-detail/' + plan.id,
                                  state: plan,
                                  type: 'history',
                                })
                              } else {
                                history.push({
                                  pathname: '/package/' + plan.id,
                                  state: plan,
                                  type: 'history',
                                })
                              }
                            }}
                          >
                            View Details&gt;&gt;
                          </p>
                        </div>
                      </div>
                    </>
                  )
                })}
            </div>
          </div>
        </div> */}
      </div>
      {loader && <Loader />}
      <Congrats
        show={showModal}
        handleClose={modalClose}
      />
    </>
  );
};

export default History;
