import React, { useState } from "react";
import { login } from "../Redux/Reducers/userSlice";
import { LoginService, toastMessage } from "../";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
export default function SigninForm({ setSwitchForm, handleClose }) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    // setLoader(true);
    LoginService({
      email: email,
      password: password,
    })
      .then(({ data }) => {
        setEmail("");
        setPassword("");
        // setLoader(false);
        // setStatus(data.status)
        let verifyEmail = data.data?.user?.email_verified_at;
        dispatch(login(data.data));
        // if (!verifyEmail) {
        //   history.push("/otp");
        // } else {
        //   history.push("/home");
        // }
        handleClose();
      })
      .catch((err) => {
        // setLoader(false);
        toastMessage(err?.response?.data?.message, "error");
      });
  };
  return (
    <>
      <form
        className="signin-form"
        onSubmit={(e) => handleSubmit(e)}
        style={{ marginBottom: "0px", overflowY: "hidden" }}
      >
        <label>Email</label>
        <input
          value={email}
          className="signin-input"
          type="email"
          placeholder=""
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <label>{t("password")}</label>
        <input
          value={password}
          className="signin-input"
          type="password"
          required
          onChange={(e) => setPassword(e.target.value)}
        />
        <label
          onClick={() => setSwitchForm("forgot")}
          className="forget-pwd w-100 mb-2"
          style={{ textAlign: "right", cursor: "pointer" }}
        >
          {t("forget-password")}
        </label>
        <Button
          className="w-100 btnGuest"
          style={{ background: "black" }}
          type="submit"
        >
          {t("signIn")}
        </Button>
        <Button
          className="w-100 btnGuest my-2"
          style={{ background: "#655a4e" }}
          onClick={() => setSwitchForm("signup")}
        >
          {t("signup")}
        </Button>
        <Button
          className="w-100 modalGuestbtn my-2"
          onClick={() => {
            setSwitchForm("payment");
            // handleClose();
          }}
        >
          {t("ContinueGuest")}
        </Button>
      </form>
    </>
  );
}
