import React, { useEffect } from 'react';

export default function Layout(props) {
  const changeTitle = () => {
    document.title = props.title + " " +  `${ props.path == '/home' ? "" :  '| Open Borders'}`
  }
  useEffect(() => {
    changeTitle()
  })
  return <div id="layout" className='layout'>
    <props.component />
  </div>;
}
