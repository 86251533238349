import React, { useState, useEffect } from "react";
import "../../shared/styles/css/account.css";
import Navigation from "../navigation";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  getQuestionService,
  toastMessage,
  getQuestionStateService,
  guestLoginService
} from "../../shared";
import { useDispatch } from "react-redux";
import Loader from "../../shared/loader/loader";
import { OpenBorderLogo } from "../../assets";
// import OpenBorderGif from '../../assets/media/open.gif'
import OpenVideo from "../../assets/media/open.mp4";
import "../../shared/styles/css/sidebar.css";
import QuestModal from "../../shared/component/Modals/QuestModal";
import { useSelector } from "react-redux";
import { clientInnerWidth } from "../../shared/util/constant";
import Logo from "../../shared/component/Logo/Logo";
import { getAnalytics, logEvent } from "firebase/analytics";
import CryptoJS from "crypto-js";
import { decryption } from "../../shared/util/constant";
import { login } from "../../shared/Redux/Reducers/userSlice";
import { useLocation } from "react-router-dom";
import { browserName, browserVersion, isMobile, isIOS } from "react-device-detect";
import {
  GooglePlayIcon,
  MacAppStoreIcon,
  AppleStore
} from "../../assets/index";


const Home = () => {
  const {
    lang: { lang },
    user: { user },
  } = useSelector((state) => state.root);
  const dispatch = useDispatch();
  const location = useLocation();
  const userRedux = useSelector((state) => state.root.user);


  const { t, i18n } = useTranslation();
  const [loader, setLoader] = useState(true);
  const [clientWidth, setClientWidth] = useState(0);
  const [shoModal, setShowModal] = useState(false);

  const history = useHistory();
  const [question, setQuestion] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [disclaimer, setDesclaimer] = useState([]);
  const [guideBool, setGuideBool] = useState(false);
  const [userCheck, setUserCheck] = useState((loader && !user) ? false : true)


  const getQuestionState = (e) => {
    if (question.id == allQuestions[0].id && !guideBool) {
      let temp = allQuestions.filter(
        (newItem) => newItem.id === question.data.child[0].targetNode
      );
      setQuestion(temp[0]);
    }
    else if (guideBool) {
      history.push("/question")
    }
    else {
      setShowModal(true);
      let temp = allQuestions.filter(
        (newItem) => newItem.id === "08d6214d-638d-4e35-aee4-2c015ebda981"
      );
      setDesclaimer(temp);
    }
  };


  const getQuestion = () => {
    getQuestionService()
      .then(({ data: { data } }) => {
        let bytes = CryptoJS.AES.decrypt(data, decryption.key);
        let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        let ele = decryptedData.questions[0]?.data?.description?.replace(
          /&lt;/gi,
          "<"
        );
        setQuestion(decryptedData.questions[0]);
        setAllQuestions(decryptedData.questions);
      })
      .catch((err) => {
        toastMessage(err?.response?.data?.message, "error");
      })
      .finally(() => setLoader(false));
  };

  const getContinueSave = () => {
    if (user) {
      let obj = {
        user_id: user.id,
      };

      getQuestionStateService(obj)
        .then(({ data: { data } }) => {
          if (data != null) {
            setGuideBool(true);
          }
        })
        .catch((err) => { });
    }
  };

  useEffect(() => {
    if (((userRedux?.user?.is_guest == 0 && !userRedux?.user?.email_verified_at) || !user) && !(userRedux?.user?.hasOwnProperty('email_verified_at'))) {
      guestLoginService()
        .then(({ data: { data } }) => {
          dispatch(login(data));
          history.push("/home");
          if (data) {
            setUserCheck(true)
          }
        })
        .catch((err) => console.log(err));
    }
  }, [userRedux])

  useEffect(() => {
    getQuestion();
    getContinueSave();
  }, []);
  const closeModal = () => setShowModal(!shoModal);
  // useEffect(()=>{
  //     let html = document.getElementById("zain");
  //     if(html.children[1]){

  //         html.children[1]['id'] = 'title'
  //     }
  //     if(html.children[0]){

  //         html.children[0]['id'] = 'heading'
  //     }
  //     if(html.children[2]){

  //         html.children[2]['id'] = 'quesPara'
  //     }

  //  console.log( "paragraph",html.children[1])
  // })

  useEffect(() => {
    document.getElementById("vido")?.play();
  });

  const [sidebar, setSidebar] = useState(true);
  const showSidebar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(!sidebar);
    }
  };

  const hideSideBar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(false);
    }
  };

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, "Splash");
  }, []);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, "Welcome Guide");
  }, []);

  useEffect(() => {
    const analytics = getAnalytics();
    // analytics.setDefaultEventParameters("");
    // analytics.setUserId('');
  }, []);


  return (
    <>
      {<Navigation
        label={t("home")}
        width={setClientWidth}
        onPress={() => history.goBack()}
        sidebar={sidebar}
        showSidebar={showSidebar}
        setSidebar={setSidebar}
      />}

      <div
        className={`main homepage ${clientWidth > clientInnerWidth.width && "margin300"
          }`}
        onClick={hideSideBar}
      >
        <div className="d-flex justify-content-center">
          {/* {
                        !loader && <video id="vido" className="vido" muted loop autoplay width={'400px'}>
                            <source type="video/mp4" src={OpenVideo}>
                            </source>
                        </video>
                    } */}

          {!loader && <Logo />}

          {/* <img src={OpenBorderLogo} width={'273px'} /> */}
        </div>
        <div
          className="padding mp4"
          style={isIOS ? { marginBottom: "35%" } : {}}
          dangerouslySetInnerHTML={{
            __html:
              lang == "sp"
                ? question?.data?.description_ES?.replace(/&lt;/gi, "<")
                : question?.data?.description?.replace(/&lt;/gi, "<"),
          }}
        ></div>
        
        <div className="footer footer-fix  home_footerContainer">
          {/*  */}
          {!loader && (<>
            <div className="footerIn home_footer_center">
              <button
                onClick={(e) => getQuestionState(e)}
                className="btn btn-main btn-pack getstart"
              >
                {guideBool ? (
                  <span>{t("ContinueGuide")}</span>
                ) : lang == "sp" ? (
                  question?.data?.child[0]?.text_ES
                ) : (
                  question?.data?.child[0]?.text
                )}
              </button>
              <div style={{ display: "flex" }}>
                <button
                  onClick={() => window.open("https://apps.apple.com/us/app/open-borders/id1591040913", '_blank')}
                  className="btn btn-main btn-pack getstart"
                  style={{ marginBottom: "5%", height: "auto" }}
                >
                  <img src={AppleStore} className="imgPlayButton" />

                </button>
                <button
                  onClick={() => window.open("https://apps.apple.com/us/app/open-borders/id1591040913", '_blank')}
                  className="btn btn-main btn-pack getstart"
                  style={{ marginBottom: "5%", height: "auto", marginLeft: "5%", marginRight: "5%" }}
                >
                  <img src={MacAppStoreIcon} className="imgPlayButton" />
                </button>
                <button
                  onClick={() => window.open("https://play.google.com/store/apps/details?id=com.open.borders", '_blank')}
                  className="btn btn-main btn-pack getstart"
                  style={{ marginBottom: "5%", height: "auto" }}
                >
                  <img src={GooglePlayIcon} className="imgPlayButton" />
                </button>
              </div>
                
            </div>
            <div className="elfsight-app-d3d4db49-f40c-4b90-812d-ab3888b44206 reviewPadding displayNone"></div>
            </>
          )}
          
        </div>
      </div>
      {loader && <Loader />}
      <QuestModal
        show={shoModal}
        disclaimer={disclaimer[0]}
        text={
          <div
            className="padding"
            dangerouslySetInnerHTML={{
              __html: question?.data?.description?.replace(/&lt;/gi, "<"),
            }}
          ></div>
        }
        closeModal={closeModal}
      />
    </>
  );
};

export default Home;
