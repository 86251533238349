import { t } from "i18next";
import React, { useState, useMemo, useEffect } from "react";
import { Info, Dollar, Location, Additional, PaymentIconFilled } from "../../assets";
import "../../shared/styles/css/account.css";
import Navigation from "../navigation";
import { updateProfileService } from "../../shared";
import { toastMessage } from "../../shared/component/toast";
import { Dropdown, Row, Col } from "react-bootstrap";
import Loader from "../../shared/loader/loader";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../shared/Redux/Reducers/userSlice";
import { clientInnerWidth } from "../../shared/util/constant";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import countryList from "react-select-country-list";
import CountryList from "country-list-with-dial-code-and-flag";
import { getAnalytics, logEvent } from "firebase/analytics";
import { browserName, browserVersion, isIOS } from "react-device-detect";
import { getProfileService, getUserDetails } from "../../shared/services";
import { useLocation, useHistory } from "react-router-dom";
import CreditCardInput from "react-credit-card-input";
import { updateCardDetails } from "../../shared/services/account"
import { paymentCardService } from "../../shared/services/paymentPackage";




// import StripeCard from "./stripe";


const Payment = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.root);
  const location = useLocation();
  const history = useHistory();


  const [cardNumber, setCardNumber] = useState("");
  const [cvc, setCVC] = useState("");
  const [expiry, setExpiry] = useState("");
  const [name, setName] = useState("");
  const [cardBool, setCardBool] = useState(false);
  const [checkEmail, setCheckEmail] = useState("");
  const [emailBool, setEmailBool] = useState(false);
  const [isGuestUser, setIsGuestUser] = useState(false);




  const [clientWidth, setClientWidth] = useState(0);
  const [countryFilter, setCountryFilter] = useState([]);
  const [countryCode, setCountryCode] = useState(
    user.user?.phone_no
      ? user.user.phone_no.substring(0, user.user.phone_no.length - 10)
      : ""
  );
  const [fname, setFname] = useState(
    user.user?.first_name ? user.user?.first_name : ""
  );
  const [lname, setLname] = useState(
    user.user?.last_name ? user.user?.last_name : ""
  );
  const [email, setEmail] = useState(user.user?.email ? user.user?.email : "");
  const [phone, setPhone] = useState(
    user.user?.phone_no
      ? user.user.phone_no
        .slice(user.user.phone_no.length - 10)
        .replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
      : ""
  );
  const [street, setStreet] = useState(
    user.user?.user_address?.street_address
      ? user.user?.user_address?.street_address
      : ""
  );
  const [country, setCountry] = useState(
    user.user?.user_address?.country ? user.user?.user_address?.country : ""
  );
  const [state, setState] = useState(
    user.user?.user_address?.state ? user.user?.user_address?.state : ""
  );
  const [city, setCity] = useState(
    user.user?.user_address?.city ? user.user?.user_address?.city : ""
  );
  const [zip, setZip] = useState(
    user.user?.user_address?.zip_code ? user.user?.user_address?.zip_code : ""
  );
  const [loader, setLoader] = useState(false);
  const [countriesArr, setCountriesArr] = useState([]);
  const [emailForUS, setEmailForUS] = useState("");
  const [fNameUS, setFNameUS] = useState("");
  const [lNameUS, setLNameUS] = useState("");
  const options = useMemo(() => countryList().getData(), []);



  // const showSidebar = () => setSidebar(!sidebar);
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(!sidebar);
    }
  };

  const hideSideBar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(false);
    }
  };





  useEffect(() => {
    window.Stripe.setPublishableKey("pk_live_qnJs6x0H3LdgCPIOsSIoIVjw");
  }, [])

  const handleCard = (callBack) => {

    if (cardNumber.length == 0 || cvc.length == 0 || expiry.length == 0) {
      toastMessage("Please complete all of the required information.", "error");
    }
    else {
      setLoader(true);
      let obj = {
        number: cardNumber,
        cvc: cvc,
        exp_month: expiry.split("/")[0].replace(" ", ""),
        exp_year: expiry.split("/")[1].replace(" ", ""),
        name: name,
      }

      // setLoader(true);

      window.Stripe.createToken(
        {
          number: cardNumber,
          cvc: cvc,
          exp_month: expiry.split("/")[0].replace(" ", ""),
          exp_year: expiry.split("/")[1].replace(" ", ""),
          name: name,
        },
        function (result, response) {
          if (response?.error) {

            toastMessage(response?.error?.message, "error");
            setLoader(false)
          }
          if (response?.id) {
            let obj = {
              email: user?.user?.is_guest ? checkEmail : user?.user?.email,
              stripe_token: response?.id,
              pm_last_four: response?.card?.last4,
              card_holder_name: name,
              expire_date: expiry
            }

            setLoader(false)


            callBack(obj)
              .then(({ data } = data) => {
                if (data) {
                  toastMessage(data?.message, "success")
                }
                if (location?.redirect) {
                  history.push({
                    pathname: location?.redirect,
                    redirect: true
                  })
                } else {
                  history.push({
                    pathname: "/account"
                  })
                }
              })
              .catch((err) => {
                setLoader(false);
                toastMessage(err?.response?.data?.message, "error")
              })
          }
        }
      );
    }


  }


  const getCVC = (e) => {
    if (e.target.value < 0) {
      setCVC(0);
    } else if (e.target.value > 999) {
      setCVC(999);
    } else {
      setCVC(e.target.value);
    }
  };

  useEffect(() => {
    setLoader(true)
    getProfileService()
      .then(({ data } = data) => {
        setLoader(false)
        if (data?.data?.user?.pm_last_four) {
          setCardNumber("**** **** **** " + data?.data?.user?.pm_last_four)
          setCardBool(true);
          setName(data?.data?.user?.card_holder_name)
          setExpiry((data?.data?.user?.expire_date).split(" ").join(""))
        }


      })
      .catch(() => {
        setLoader(false)
      })
  }, [])


  const handleCheckEmail = () => {
    setEmailBool("")
    setCVC("");
    setName("");
    setCardNumber("");
    setExpiry("");
    setIsGuestUser(false);
    setEmailBool(false);

    if (checkEmail.length) {
      let obj = {
        email: checkEmail
      }

      setLoader(true)

      getUserDetails(obj)
        .then(({ data } = data) => {

          if (data?.data?.is_guest == 0) {
            toastMessage(t("failGetUser"), "error");
          }

          if (data?.data?.is_guest == 1) {
            setIsGuestUser(true)
          }



          setLoader(false)
          // setName(data?.data?.first_name ? data?.data?.first_name + " " + data?.data?.last_name : "")

          if (data?.data?.pm_last_four) {
            setCardNumber("**** **** **** " + data?.data?.pm_last_four)
            setCardBool(true)
          }
        })
        .catch((err) => {
          toastMessage(err?.response?.data?.message, "error")
          setLoader(false)

        })
    } else {
      toastMessage("Please Enter email first", "error")
    }
  }




  return (
    <>
      <Navigation
        label={t("profile")}
        width={setClientWidth}
        sidebar={sidebar}
        showSidebar={showSidebar}
        setSidebar={setSidebar}
      />

      {user?.user?.is_guest ? <div
        className={`signin-form ${clientWidth > clientInnerWidth.width && "margin300"
          }`}
        style={
          browserName == "Mobile Safari" || isIOS ? { marginBottom: "45%" } : {}
        }
      >
        <div className="container cont-acc-after">
          <div className="container">
            <TitleHeader title={t("EnterYourEmail")} customClass={"emailTitle"} />
            <Title for="state" title={t("email")} />
            <Input
              onChange={(e) => setCheckEmail(e.target.value)}
              type="text"
              id="state"
              placeholder=""
              required
              value={checkEmail}
            />
            <button className="btn btn-main" onClick={() => handleCheckEmail()}>
              {t("checkEmail")}
            </button>
          </div>
        </div>
      </div> : " "}

      {
      !user?.user?.is_guest || isGuestUser ? <div
        className={`signin-form ${clientWidth > clientInnerWidth.width && "margin300"
          }`}
        style={
          browserName == "Mobile Safari" || isIOS ? { marginBottom: "45%" } : {}
        }
      >
        <div className="container cont-acc-after">
          <div className="container">
            <TitleHeader icon={PaymentIconFilled} title={t("paymentDetails")} />
            <Title for="state" title={t("creditCardNumber")} />
            <div className="paymentInitial">
              <CreditCardInput
                onError={({ inputName, err }) =>
                  console.log(`credit card input error: ${err}`)
                }
                cardExpiryInputProps={{
                  onBlur: (e) => console.log("expiry blur", expiry),
                  onChange: (e) =>
                    console.log("expiry change", setExpiry(e.target.value)),
                  onError: (err) => console.log(`expiry error: ${err}`),
                }}
                cardNumberInputProps={{
                  value: cardNumber,
                  onBlur: (e) => console.log("number blur", e),
                  onChange: (e) =>
                    console.log("number change", setCardNumber(e.target.value)),
                  onError: (err) => console.log(`number error: ${err}`),
                }}
                customTextLabels={{
                  cardNumberPlaceholder: t("cardNumber"),
                }}
                fieldClassName="PaymentClass"
              />
            </div>
            <Title for="state" title={t("ExpiryDate")} />
            <div className="ExpireInitial">
              <CreditCardInput
                onError={({ inputName, err }) =>
                  console.log(`credit card input error: ${err}`)
                }
                cardExpiryInputProps={{
                  value: expiry,
                  onBlur: (e) => console.log("expiry blur", e),
                  onChange: (e) =>
                    console.log("expiry change", setExpiry(e.target.value)),
                  onError: (err) => console.log(`expiry error: ${err}`),
                }}
                customTextLabels={{
                  cardNumberPlaceholder: "Card Number",
                }}
                fieldClassName="ExpireClass"
              />
            </div>
            <Title for="state" title={t("cardHolder")} />
            <Input
              onChange={(e) => setName(e.target.value)}
              type="text"
              id="state"
              placeholder=""
              required
              value={name}
            />
            <Title for="state" title="CVC" />
            <Input
              onChange={(e) => getCVC(e)}
              type="text"
              id="state"
              placeholder="123"
              value={cvc}
              required
            />
          </div>
        </div>
        <div className="footer container cont-acc-after marginBottomSpaceOnTab">
          <div className="container">
            <button onClick={() => handleCard(user?.user?.is_guest ? updateCardDetails : paymentCardService)} className="btn  btn-main">
              {cardBool ? t("updateCard") : t("addCard")}
            </button>
          </div>
        </div>

      </div> : ""}
      {loader && <Loader />}
    </>
  );
};
const TitleHeader = ({ icon, title, customClass }) => {
  return (
    <>
      <ul className="acc-after1">
        <li>
          <img src={icon} />
        </li>
        <li>
          <p className="p-acc" style={customClass ? { marginLeft: "0px" } : {}}>{title}</p>
        </li>
      </ul>
    </>
  );
};
const Input = (props) => {
  return (
    <>
      <input className="acc-input-after" {...props} />
    </>
  );
};
const Title = (props) => {
  const { title } = props;
  return <label {...props}>{title}</label>;
};

export default Payment;
