import { Modal } from "bootstrap";
import React from "react";

const Footers = ({ label, onPress, img, title, Svg }) => {
  return (
    <div>
      {title ? (
        <>
          <button type="button" className="btn btn-foot" onClick={onPress}>
            {img ? (
              <img src={img} className="footer-btn-icon" />
            ) : Svg ? (
              Svg
            ) : (
              ""
            )}

            <span className="ques-foo">{label}</span>
          </button>
          <button type="button" className="btn btn-foot"></button>
        </>
      ) : (
        <button type="button" className="btn btn-foot" onClick={onPress}>
          {img ? <img src={img} className="footer-btn-icon" /> : Svg ? Svg : ""}
          <span className="ques-foo">{label}</span>
        </button>
      )}
    </div>
  );
};
export default Footers;
