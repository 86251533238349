export const Endpoint = {
  getQuestion: "general/getQuestionare",
  signUP: '/sign-up',
  Login: '/sign-in',
  verifyOtp: "/verify-otp",
  sendOtp: "/send-otp",
  forgetPassword: "/forget-password",
  recoverPassword: "/recover-password",
  getProfile: "/get-profile",
  updateProfile: "/update-profile",
  bookConsultationFee: "/consultation/v1/booking",
  publicbookConsultationFee: "/v1/booking",
  getServiceHistory: "/user/buy-plan-history",
  getPackagePlan: "/payments/packages/plan",
  addOrUpdateQuestion: "/questionnaire-state/store",
  getQuestionState: "questionnaire-state/show",
  deleteQuestionState: "/questionnaire-state/delete",
  singlePayment: "/payments/packages/plan",
  subPayment: "/payments/v1/subscription",
  publicSubscriptionPayment: "/v1/subscription",
  serviceHistory: "/user/buy-plan-history",
  addPaymentCard: "/payments/v1/add-card",
  accuityDate: '/availability/date-time',
  accuityTimes: '/availability/times',
  accuityTypes: '/appointment-types',
  accuityCalendar: "/calendars",
  guestLogin: "/login-as-gust",
  updateGuestUser: "/update-guest-user",
  sendSummmaryInEmail: "/questionnaire-state/send-email",
  getPackageList: "/payments/packages/list",
  getPackagesbyId: "/payments/packages/plan",
  reportBug: "/report-bugs",
  updateDate: "/update-language",
  timezone: "/timezone-list",
  getUser: "/v1/get-user",
  updateCard: "/v1/update-card"
}
export const openEndpoint = {
  getNews: "https://bordercrossinglaw.com/wp-json/wp/v2/posts/"
}