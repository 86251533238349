import React, { useEffect, useState } from "react";
import { Placeholder } from "../../assets/index";
import "../../shared/styles/css/account.css";
import "../../shared/styles/css/news.css";
import Navigation from "../navigation";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getNewsService } from "../../shared";
import { useLocation } from "react-router-dom";
import Loader from "../../shared/loader/loader";
import moment from "moment";
import { clientInnerWidth } from "../../shared/util/constant";
import axios from "axios";
import { getAnalytics, logEvent } from "firebase/analytics";
import { browserName, browserVersion, isMobile, isIOS } from "react-device-detect";


const NewsDetail = () => {
  const location = useLocation();
  const { state } = location;

  const [stateRes, setState] = useState(state?.post);
  const [resp, setResp] = useState("");
  const [clientWidth, setClientWidth] = useState(0);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [loader, setLoader] = useState(false);


  // axios.get(state.x_metadata.passthrough_url)
  //     .then(({ data }) => {
  //         console.log("daat", data)
  //     })
  //   state.content.rendered.split('<').forEach(element => {
  //       console.log('resp',element.replace(new RegExp('[^>]*>', 'ig'),""));
  //   });

  // useEffect(() => {
  //   const concernedElement = document.querySelector(".click-sidebarr");

  //   document.addEventListener("mousedown", (event) => {
  //     console.log("check", state?.check);
  //     console.log("Event", concernedElement.contains(event.target));
  //     if (concernedElement.contains(event.target)) {
  //       console.log("here");
  //     } else {
  //     }
  //   });
  // }, []);
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(!sidebar);
    }
  };
  const hideSideBar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(false);
    }
  };


  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, `News Detail-(${state?.post?.title?.rendered})`);
  })
  return (
    <>
      <Navigation
        label={t("news")}
        width={setClientWidth}
        onPress={() => history.goBack()}
        sidebar={sidebar}
        showSidebar={showSidebar}
        setSidebar={setSidebar}
      />
      {/* <Navigation label={t("news")} onPress={() => history.goBack()} /> */}

      <div
        className={`news-dets ${clientWidth > clientInnerWidth.width && "margin300"
          }`}
        style={(browserName == "Mobile Safari" || isIOS) ? { marginTop: "-42px", marginBottom: "45%" } : { marginTop: "-42px" }}
        onClick={hideSideBar}
      >
        <Container style={{ width: "90%" }} className="mt-5">
          <iframe
            onLoad={() => setLoader(true)}
            src={stateRes?.link}
            style={{ width: "100%", height: "100vh" }}
          ></iframe>
        </Container>
        {/* <div>
                    <img className="img-n" src={stateRes?.x_featured_media_large} />
                    <div className="cont-n">
                        <p className="date-price">{stateRes?.x_date}</p>
                        <p>{stateRes?.title?.rendered}</p>
                        < p className="news-p">
                            {
                                stateRes?.content?.rendered?.replace(new RegExp('<[^>]*>', 'ig'), "")
                            }
                        </p>

                    </div>
                </div> */}

        {!loader && <Loader />}
      </div>
    </>
  );
};

export default NewsDetail;
