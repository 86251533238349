import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toastMessage } from "..";
import { forgotPasswordService } from "..";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
const ForgotForm = ({setSwitchForm}) => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [errorfor, setError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const validate = () => {
    if (email === "") {
      setError("email");
      setErrorMsg("Email is requied!!");
      return false;
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setError("email");
      setErrorMsg("You have entered an invalid email address!");
      return false;
    } else {
      return true;
    }
  };
  const getEmail = (e) => {
    e.preventDefault();
    let valid = validate();

    if (valid) {
      setLoader(true);
      let obj = {
        email: email,
      };
      forgotPasswordService(obj)
        .then(({ data }) => {
          setLoader(false);

          history.push({
            pathname: "/otp",
            type: { type: "forgot", email: email },
          });
        })
        .catch((err) => {
          setLoader(false);
          toastMessage(err?.response?.data?.message, "error");
        });
    }
  };
  const resetError = () => {
    setError("");
    setErrorMsg("");
  };
  return (
    <div>
      <form>
        <div className="mb-3">
          <div className="mail-input">
            <input
              type="email"
              className="enter-mail"
              onChange={(e) => {
                resetError();
                setEmail(e.target.value);
              }}
            />
          </div>
          <div>
            {errorfor == "email" && errorMsg !== "" && (
              <div style={{ color: "red" }}>{errorMsg}</div>
            )}
          </div>
        </div>
        <Button
          disabled={loader}
          onClick={getEmail}
          className="w-100 btnGuest"
          style={{ background: "black" }}
          type="submit"
        >
          {loader ? (
            <div class="spinner-border" role="status">
              <span class="sr-only"></span>
            </div>
          ) : (
            t("submit")
          )}
        </Button>
        <Button
        onClick={()=>setSwitchForm("signin")}
          className="w-100 btnGuest my-2"
          style={{ background: "#F9F5EC",color:"black" }}
        >
          {t("backSignIn")}
        </Button>
      </form>
    </div>
  );
};

export default ForgotForm;
