import React, { useState, useEffect } from "react";
import "../../shared/styles/css/account.css";
import Navigation from "../navigation";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionService, toastMessage } from "../../shared";
import Loader from "../../shared/loader/loader";
import { getAnalytics, logEvent } from "firebase/analytics";
import UpdateCardModal from "../../shared/component/Modals/UpdateCardModal.js"

import {
  Info,
  Logout,
  AccIco,
  HistoryIconSvg,
  Dollar,
  ClientAccount,
  AboutIcon,
  SignOutIcon,
  ProfileIcon,
  OrdersIcon,
  PaymentIconBorder
} from "../../assets";
import "../../shared/styles/css/sidebar.css";
import ConfirmModal from "../../shared/component/Modals/confirmationModal";
import { clientInnerWidth } from "../../shared/util/constant";
import { logout } from "../../shared/Redux/Reducers/userSlice";
const Account = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    user: { user },
  } = useSelector((state) => state.root);
  const [loader, setLoader] = useState(true);
  const [clientWidth, setClientWidth] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const history = useHistory();
  const closeModal = () => setConfirmModal(false);
  const closeUpdateModal = () => setUpdateModal(false);
  const logoutUser = () => {
    closeModal();
    dispatch(logout());
  };
  const [sidebar, setSidebar] = useState(true);
  const showSidebar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(!sidebar);
    }
  };
  const hideSideBar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(false);
    }
  };

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'Profile');
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Navigation
        label={t("Account")}
        width={setClientWidth}
        onPress={() => history.goBack()}
        sidebar={sidebar}
        showSidebar={showSidebar}
        setSidebar={setSidebar}
      />

      <div
        className={`main ${clientWidth > clientInnerWidth.width && "margin300"
          }`}
        onClick={hideSideBar}
      >
        {!user.is_guest && (
          <div className="accountCont" onClick={() => history.push("/history")}>
            {/* <HistoryIconSvg /> */}
            <OrdersIcon />
            <span className="accountItem">{t("order")}</span>
          </div>
        )}
        {!user.is_guest && (<>
          <div
            className="accountCont"
            onClick={() => history.push("/account-detail")}
          >
            {/* <ClientAccount className="defaultSvg" /> */}
            <ProfileIcon />
            <span className="accountItem">{t("accountDetail")}</span>
          </div>
          <div
            className="accountCont"
            onClick={() => history.push("/payment")}
          >
            {/* <ClientAccount className="defaultSvg" /> */}
            <PaymentIconBorder className="paymentIcon" />
            <span className="accountItem">{t("payment")}</span>
          </div>
        </>
        )}

        {!user.is_guest && (
          <div className="accountCont" onClick={() => history.push("/about")}>
            {/* <img src={Dollar} width={"22px"} /> */}
            <AboutIcon />
            <span className="accountItem">{t("about")}</span>
          </div>
        )}

        {!user.is_guest ? (
          <div className="accountCont" onClick={() => setConfirmModal(true)}>
            <SignOutIcon />
            <span className="accountItem">{t("logout")}</span>
          </div>
        ) : (
          <>
            <div className="accountCont" onClick={() => history.push("/about")}>
              <AboutIcon />
              <span className="accountItem">{t("about")}</span>
            </div>
            <div className="accountCont" onClick={() => setUpdateModal(true)}>
              <PaymentIconBorder className="paymentIcon" />
              <span className="accountItem">{t("updateCreditCard")}</span>
            </div>
            <div
              className="accountCont"
              onClick={() => history.push("/signIn")}
            >
              <Logout />
              <span className="accountItem">{t("signInOrAccount")}</span>
            </div>
          </>
        )}
      </div>
      <ConfirmModal
        text={t("logoutMsg")}
        show={confirmModal}
        closeModal={closeModal}
        Call={logoutUser}
      />
      <UpdateCardModal
        show={updateModal}
        closeModal={closeUpdateModal}
      />
    </>
  );
};

export default Account;
