import SignIn from "../../pages/auth/signin";
import Otp from "../../pages/auth/OTP";
import Home from "../../pages/home/home";
import Account from "../../pages/account/account";
import AccountDetail from "../../pages/account/index";
import Question from "../component/Question";
import Fee from "../../pages/flat-services";
import History from "../../pages/history";
import News from "../../pages/news";
import Schedule from "../../pages/schedule";
import Package from "../../pages/package_detail";
import Forget from "../../pages/auth/ForgetPassword";
import PackageDetail from "../../pages/package_detail/packagedetail";
import LanguageComp from "../../pages/language";
import Signup from "../../pages/auth/signup";
import Payment from "../../pages/payment/payment";
import ViewNews from "../../pages/news/news-detail";
import About from "../../pages/about/index.js";

const publicRoute = [
  {
    component: LanguageComp,
    path: "/language",
    title: "Language",
  },
  {
    component: SignIn,
    path: "/signIn",
    title: "Login",
  },
  {
    component: Signup,
    path: "/signup",
    title: "Sign Up",
  },
  {
    component: Forget,
    path: "/reset-password",
    title: "Reset Password",
  },
  {
    component: Otp,
    path: "/otp",
    title: "Verify Email",
  },
  {
    component: Home,
    path: "/home",
    title: "Open Borders – The free immigration guide",
  }
];
const PrivateRoute = [
  {
    component: SignIn,
    path: "/signIn",
    title: "Login",
  },
  {
    component: Forget,
    path: "/reset-password",
    title: "Reset Password",
  },
  {
    component: Otp,
    path: "/otp",
    title: "Verify Email",
  },
  {
    component: Payment,
    path: "/payment",
    title: "Payment",
  },
  {
    component: Signup,
    path: "/signup",
    title: "Sign Up",
  },
  {
    component: ViewNews,
    path: "/news-detail",
    title: "News",
  },
  {
    component: LanguageComp,
    path: "/language",
    title: "Language",
  },
  {
    component: Home,
    path: "/home",
    title: "Open Borders – The free immigration guide",
  },
  {
    component: Account,
    path: "/account",
    title: "Account",
  },
  {
    component: AccountDetail,
    path: "/account-detail",
    title: "Profile",
  },
  {
    component: Question,
    path: "/question",
    title: "Guide",
  },
  {
    component: History,
    path: "/history",
    title: "History",
  },
  {
    component: News,
    path: "/news",
    title: "News",
  },
  {
    component: Schedule,
    path: "/schedule",
    title: "Consultation",
  },
  {
    component: Package,
    path: "/services/:id",
    title: "Services",
  },
  {
    component: Fee,
    path: "/services",
    title: "Services",
  },
  {
    component: PackageDetail,
    path: "/package-detail/:id",
    title: "Package Detail",
  },
  {
    component: About,
    path: "/about",
    title: "About",
  },
];

export { publicRoute, PrivateRoute };
