import React, { useState, useEffect } from "react";
import initialElements from "../../initialElements.json/initialElements";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import styles from "./question.module.scss";
import { getAnalytics, logEvent } from "firebase/analytics";
import { browserName, browserVersion, isMobile, isIOS } from "react-device-detect";
import {
  SaveIco,
  ClientSave,
  ClientBack,
  ClientRestart,
  ClientEmail,
  ClientRestart1,
  Report,
  ReportQuestion,
  Reload,
  GetHelp,
  GoToBack,
  ContinueLater,
} from "../../../assets";
import ViewSummary from "./viewSummary";
import { getQuestionService, getQuestionStateService } from "../../index";
import Loader from "../../loader/loader";
import Footer from "../footers";
import Navigation from "../../../pages/navigation";
import ModalRestart from "../../component/Modals/ModalRestart";
import ReportModal from "../../component/Modals/ReportModal";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { language, decryption } from "../../util/constant";
import {
  addOrUpdateQuestionService,
  deleteStateService,
  sendSummaryThroughEmail,
} from "../../services";
import { toastMessage } from "..";
import { clientInnerWidth } from "../../util/constant";
import ImmigrationModal from "../Modals/immigrationModal";
import { Spinner, Button } from "react-bootstrap";
import CryptoJS from "crypto-js";
const Question = () => {
  const [boolforButton, setBoolforButton] = useState(false);
  const [questionScreenView, setQuestionScreenView] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [loaderE, setLoaderE] = useState(false);
  const [clientWidth, setClientWidth] = useState(0);
  const [actionModal, setactionModal] = useState(false);
  const [actionLinkContent, setActionLinkContent] = useState(null);
  const [smallGerk, setSmallGerk] = useState(true);
  const [firstElement, setFirstElement] = useState(null);
  const [allQuestion, setAllQuestion] = useState([]);
  const [allQuest, setAllQuest] = useState([]);
  const [tempQuestion, setTempQuestion] = useState([]);
  const [child, setChild] = useState([]);
  const [path, setPath] = useState([]);
  const [labelArr, setLabelArr] = useState([]);
  const [label, setLabel] = useState("");
  const [description, setDescription] = useState("");
  const [summary, setSummary] = useState([]);
  const [immigrationHistory, setImmigrationHistory] = useState([]);
  const [factorsOptions, setFactorsOptions] = useState([]);
  const [inadmissibilty, setInadmissibilty] = useState([]);
  const [loader, setLoader] = useState(false);
  const [multiLinkArr, setMultiLinkArr] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [des, setdes] = useState([]);
  const { t, i18n } = useTranslation();
  let enumObj = {
    link: "link",
    multilink: "multilink",
    customLink: "customLink",
  };
  const {
    lang: { lang },
    user: { user },
  } = useSelector((state) => state.root);

  const history = useHistory();
  const evaluateAnd = (newItem) => {
    let tempAND = newItem.split("&&");
    for (const item of path) {
      if (item.nodeId === tempAND[0] && item.optionId === tempAND[1]) {
        return true;
      }
    }
    return false;
  };


  const evaluateWithoutAnd = (newItem) => {
    for (const item of path) {
      if (item.nodeId === newItem) {
        return true;
      }
    }
    return false;
  };

  const solveFinalCondition = (result) => {
    let firstCondition = result[0];
    let operation = result[1];
    let thirdCondition = result[2];
    return evaluateCondition(firstCondition, operation, thirdCondition);
  };

  const findNodeInPath = (newItem) => {
    if (newItem.includes("||")) {
      let tempOR = newItem.split("||");
      let check = false;
      tempOR.forEach((newItem) => {
        if (newItem.includes("&&")) {
          check = check || evaluateAnd(newItem);
        } else {
          check = check || evaluateWithoutAnd(newItem);
        }
      });
      return check;
    } else if (newItem.includes("&&")) {
      return evaluateAnd(newItem);
    } else {
      return evaluateWithoutAnd(newItem);
    }
  };

  const evaluateCondition = (firstCondition, operation, thirdCondition) => {
    if (operation === "AND") {
      if (firstCondition && thirdCondition) {
        return true;
      } else {
        return false;
      }
    } else if (operation === "OR") {
      if (firstCondition || thirdCondition) {
        return true;
      } else {
        return false;
      }
    } else if (operation === "AND NOT") {
      if (firstCondition && !thirdCondition) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    let tempImmigration = [];
    let tempFactors = [];
    let tempInadmissibilty = [];
    summary.forEach((item, index) => {
      if (item.category === "immigrationHistory") {
        tempImmigration.push({
          id: item.id,
          category: "Your Immigration History",
          description: item?.description?.includes("&lt;")
            ? item?.description?.replace(/&lt;/gi, "<")
            : item?.description,
          description_ES: item?.description_ES?.includes("&lt;")
            ? item?.description_ES?.replace(/&lt;/gi, "<")
            : item?.description_ES
              ? item?.description_ES
              : "",
        });
      } else if (item.category === "factorsOptions") {
        tempFactors.push({
          id: item.id,
          category: "Factors Relating to Your Options",
          description: item?.description?.includes("&lt;")
            ? item?.description?.replace(/&lt;/gi, "<")
            : item?.description,
          description_ES: item?.description_ES?.includes("&lt;")
            ? item?.description_ES?.replace(/&lt;/gi, "<")
            : item?.description_ES
              ? item?.description_ES
              : "",
        });
      } else if (item.category === "inadmissibility") {
        tempInadmissibilty.push({
          id: item.id,
          category: "Grounds of Inadmissibility",
          description: item?.description?.includes("&lt;")
            ? item?.description?.replace(/&lt;/gi, "<")
            : item?.description,
          description_ES: item?.description_ES?.includes("&lt;")
            ? item?.description_ES?.replace(/&lt;/gi, "<")
            : item?.description_ES
              ? item?.description_ES
              : "",
        });
      } else {
      }
    });
    setImmigrationHistory(tempImmigration);
    setFactorsOptions(tempFactors);
    setInadmissibilty(tempInadmissibilty);
    // setSmallGerk(false)
    // setTimeout(() => {
    //   setSmallGerk(false)
    // }, );
  }, [summary]);

  useEffect(() => {
    let conditions = firstElement?.data?.conditions;

    if (!conditions?.length > 0) {
      let cloneChild = [];
      let resp = firstElement?.data?.actionLink;
      if (resp) {
        setChild(resp?.concat(firstElement?.data?.child));
      } else {
        setChild(firstElement?.data?.child);
      }
      // setChild(firstElement?.data?.child);
      setLabel(firstElement?.data?.label);

      setDescription({
        eng: firstElement?.data?.description?.replace(/&lt;/gi, "<"),

        span: firstElement?.data?.description_ES?.replace(/&lt;/gi, "<"),
      });
    } else {
      let cloneDescription = [...des];
      for (const conditionItem of conditions) {
        let condition = conditionItem.condition.split(">");
        var result = [];

        var flag = false;
        condition.forEach((newItem, newIndex) => {
          if (newItem === "AND" || newItem === "OR" || newItem === "AND NOT") {
            result.push(newItem);
          } else {
            result.push(findNodeInPath(newItem));
          }
        });

        if (result.length === 3) {
          flag = solveFinalCondition(result);
        } else if (result.length > 3) {
          let temp = [];
          result.forEach((item, index) => {
            if (temp.length === 3) {
              temp = [solveFinalCondition(temp)];
              temp.push(item);
            } else {
              temp.push(item);
            }
          });
          flag = solveFinalCondition(temp);
        } else {
          flag = result[0];
        }

        let bool = false;

        if (flag) {
          let resp = conditionItem?.actionLink;
          if (resp) {
            resp = resp.concat(conditionItem.conditionOptions);
            conditionItem?.conditionOptions?.map((i) => {
              if (i.targetNode == null) {
                bool = bool || true;
              } else {
                bool = bool || false;
              }
            });
          } else {
            resp = conditionItem.conditionOptions;
            conditionItem?.conditionOptions?.map((i) => {
              if (i.targetNode == null) {
                bool = bool || true;
              } else {
                bool = bool || false;
              }
            });
          }
          setBoolforButton(bool);

          let arr = [];
          let obj = {
            eng: conditionItem.conditionDescription?.replace(/&lt;/gi, "<"),
            span: conditionItem?.conditionDescription_ES?.replace(
              /&lt;/gi,
              "<"
            ),
            child: resp,
            label: {
              eng: conditionItem.conditionLabel,
              span: conditionItem?.conditionLabel_ES,
            },
          };
          // arr.push(obj)
          // console.log('arr',arr);
          cloneDescription = [...cloneDescription, obj];
          // cloneDescription.push(obj);
          // break;
          // setChild([])
          // setLabel("");
          // setDescription("")
          setdes(cloneDescription);
        } else {
          setChild(firstElement.data.child);
          setLabel(firstElement.data.label);

          setDescription({
            eng: firstElement?.data?.description?.replace(/&lt;/gi, "<"),

            span: firstElement?.data?.description_ES?.replace(/&lt;/gi, "<"),
          });
        }
      }
    }

    // google analytic object
    if (firstElement) {
      const analytics = getAnalytics();
      logEvent(analytics, `Questionnaire-(${firstElement.data.label})`);
    }

    if (firstElement) {
      let obj = {
        QuestionNodeId: firstElement.id,
        QuestionTitle: firstElement.data.label,
      };
      const analytics = getAnalytics();
      logEvent(analytics, "QuestionView", obj);
    }


  }, [firstElement]);


  useEffect(() => {
    if (questionScreenView) {
      const analytics = getAnalytics();
      logEvent(analytics, "Questionnaire");
    }
  }, [questionScreenView]);
  // for the top view
  useEffect(()=>{
    window.scrollTo(0, 0);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    document.getElementsByClassName('question')[0].scrollIntoView({ behavior: 'smooth' });
  })    


  const getPathMultLinkNodefromOption = (item) => {
    for (const node of allQuestion) {
      let findNodeId = node.data?.child.filter((ii) => ii.id === item.id);
      if (!findNodeId?.length) {
        getNodeidFromConditionOptions(node, item, (resp) => {
          if (resp) {
            findNodeId = resp;
          }
        });

        if (findNodeId?.length > 0) {
          return findNodeId;
        }
      } else if (findNodeId?.length) {
        return [node];
      }
    }
  };

  const handleReport = () => {
    setShowReport(true);
  };

  const getNodeidFromConditionOptions = (node, item, callback) => {
    let exceptions = {};
    try {
      node?.data?.conditions?.forEach((element) => {
        let val = element?.conditionOptions.filter(
          (cond) => cond.id === item.id
        );

        if (val.length) {
          callback([node]);

          throw exceptions;
        }
      });
    } catch (error) {
      if (error !== exceptions) throw error;
    }
  };
  let hardtext = {
    ageUnder21: "I am under 21 years old.",
    ageOver21: "I am 21 years old, or older.",
    notMarried: "I am not married.",
    Married: "I am legally married.",
    click: "Please Click to Proceed",
  };
  const findNext = (item) => {
    document.body.scrollTop = 0;
    // console.log('path',path);
    if (multiLinkArr.filter((i) => i.id == item.id).length > 0) {
      let indexToRemove = multiLinkArr.findIndex((i) => i.id == item.id);
      let temp = [...multiLinkArr];
      temp.splice(indexToRemove, 1);
      setMultiLinkArr(temp);
      let TempPath = [...path];
      let indexToRemoveinPath = TempPath.findIndex(
        (i) => i.optionId == item.id
      );
      TempPath.splice(indexToRemoveinPath, 1);
      setPath(TempPath);
      if (item.summaryCatagory != "") {
        let tempSummary = [...summary];
        let indexToRemoveinSummary = tempSummary.findIndex(
          (i) => i.optionId == item.id
        );
        tempSummary.splice(indexToRemoveinSummary, 1);
        setSummary(tempSummary);
      }
    } else {
      if (item.targetNode === null) {
        let getNodeId = getPathMultLinkNodefromOption(item);
        let cloneMultiLinkArr = [...multiLinkArr];
        let newTemp = [...path];
        item["nodeId"] = getNodeId[0].id;

        let addNewOptionIndx = newTemp.findIndex(
          (ii) => ii?.nodeId == firstElement.id && ii?.optionId == item.id
        );
        if (addNewOptionIndx < 0) {
          cloneMultiLinkArr.push(item);
          newTemp.push({
            nodeId: getNodeId[0].id,
            optionId: item.id,
            text: item.text,
            type: enumObj.multilink,
          });
          setPath(newTemp);
          setMultiLinkArr(cloneMultiLinkArr);
        }
        if (item.summaryCatagory) {
          let temp = [...summary];
          let findSummaryInd = temp.findIndex((ii) => ii.optionId == item.id);
          if (findSummaryInd < 0) {
            temp.push({
              id: firstElement.id,
              optionId: item.id,
              type: enumObj.multilink,
              category: item.summaryCatagory,
              description: item.summaryDescription,
              description_ES: item.summaryDescription_ES,
            });
            setSummary(temp);
          }
        }

        // setFirstElement(null);
      } else {
        //---------- set Next Node start ---------
        let newTemp = [...path];
        // let addNewOptionIndx = newTemp.findIndex(ii=>ii?.nodeId == firstElement.id && ii?.optionId == item.id);
        // if(addNewOptionIndx < 0){

        // }

        let temp = allQuestion.filter(
          (newItem, index) => newItem.id === item.targetNode
        );
        let cloneSummary = [...summary];
        // console.log(cloneSummary,'temp',temp[0],cloneSummary.filter(ii=> ii.id !== temp[0].id));
        let findExisting = newTemp.filter((ii) => ii.nodeId == temp[0].id);
        if (findExisting.length) {
          let cloneMultiLinkArr = [...multiLinkArr];
          let multiArr = cloneMultiLinkArr.filter(
            (multi) => multi?.nodeId === temp[0].id
          );
          if (multiArr.length) {
            cloneMultiLinkArr = cloneMultiLinkArr.filter(
              (multi) => multi.nodeId !== temp[0].id
            );
            setMultiLinkArr(cloneMultiLinkArr);
          }

          newTemp = newTemp.filter((ii) => ii.nodeId !== temp[0].id);

          cloneSummary = cloneSummary.filter((ii) => ii.id !== temp[0].id);
          if (item.summaryCatagory) {
            cloneSummary.push({
              id: firstElement.id,
              type: enumObj.link,
              category: item.summaryCatagory,
              description: item.summaryDescription,
              description_ES: item.summaryDescription_ES,
            });
          }
          setSummary(cloneSummary);
        } else if (item.summaryCatagory) {
          let temp = [...summary];
          temp.push({
            id: firstElement.id,
            type: enumObj.link,
            category: item.summaryCatagory,
            description: item.summaryDescription,
            description_ES: item.summaryDescription_ES,
          });
          setSummary(temp);
        }
        newTemp.push({
          nodeId: firstElement.id,
          optionId: item.id,
          text: item.text,
          type: enumObj.link,
        });

        setPath(newTemp);
        setdes([]);
        setChild([]);
        // console.log("temp[0]", temp[0]);

        setFirstElement(temp[0]);

        // ----------- set Next Node End ----------------
        //------------ set Summary Start ----------------

        //------------ set Summary Delete ----------------
      }
    }
  };

  // Method for Showing Errors
  const setToastify = () => {
    toast.error("Refresh");
  };

  const setToastifySaved = () => {
    toast.success("Saved");
  };

  // Method for Previous Page
  const goBack = () => {
    // console.log('zain');
    let clonePath = [...path];

    let pathEndNode = clonePath[clonePath.length - 1];

    //----- find summary and delete
    let temp = [...summary];

    let pathLength = path.length;

    temp = summary.filter(
      (item, index) =>
        item.type == enumObj.multilink &&
        item.id == clonePath[clonePath.length - 1]?.nodeId
    );

    if (temp.length) {
      let resp = summary.filter(
        (item, index) =>
          item.type != enumObj.multilink &&
          item.id != clonePath[clonePath.length - 1]?.nodeId
      );
      clonePath = clonePath.slice(0, clonePath.length - 1);
      temp = resp.filter(
        (item, index) =>
          item.type != enumObj.multilink &&
          item.id != clonePath[clonePath.length - 1]?.nodeId
      );
      setSummary(temp);
    } else {
      setSummary(
        summary.filter(
          (item, index) => item.id != clonePath[clonePath.length - 1]?.nodeId
        )
      );
      clonePath = clonePath.slice(0, clonePath.length - 1);

      //do some here
    }
    // console.log('check',temp);
    // debugger
    // clonePath = clonePath.slice(0,(clonePath.length-1))
    // temp = temp.filter(
    //   (item, index) => item.id != clonePath[(clonePath.length-1)]?.nodeId
    // );
    // console.log(temp);
    // debugger

    // setSummary(temp);
    //------ end find summary and delete

    if (pathEndNode.type == enumObj.multilink) {
      let ind = clonePath.length - 1;
      while (ind) {
        if (clonePath[ind]?.type != enumObj.link) {
          clonePath = clonePath.slice(0, clonePath.length - 1);
        } else {
          pathEndNode = clonePath[clonePath.length - 1];
          clonePath = clonePath.slice(0, clonePath.length - 1);
          break;
        }
        ind--;
      }
    } else if (pathEndNode.type == enumObj.link) {
      if (clonePath.length) {
        let ind = clonePath.length - 1;
        while (ind) {
          if (clonePath[ind]?.type != enumObj.link) {
            clonePath = clonePath.slice(0, clonePath.length - 1);
          } else {
            // pathEndNode = clonePath[clonePath.length -1]
            // clonePath = clonePath.slice(0,(clonePath.length-1))
            break;
          }
          ind--;
        }
      }
    }
    temp = allQuestion.filter((item, index) => item.id === pathEndNode.nodeId);
    setPath(clonePath);
    let dumArr = [];
    clonePath
      .filter(
        (ii) =>
          ii.type === enumObj.multilink ||
          ii?.text === hardtext.Married ||
          ii?.text === hardtext.notMarried ||
          ii?.text === hardtext.ageOver21 ||
          ii?.text === hardtext.ageUnder21
      )
      .forEach((resp) => {
        let findElement = multiLinkArr.find(
          (ii) => ii?.nodeId === resp?.nodeId && ii?.id === resp?.optionId
        );
        if (findElement) {
          dumArr.push(findElement);
        }
      });

    // console.log('back elements',temp);

    // console.log('traverse elements',path);
    setFirstElement(temp[0]);
    setMultiLinkArr(dumArr);
    setdes([]);
  };
  const handleRestart = () => {
    sessionStorage.removeItem("OPTIONS");
    sessionStorage.removeItem("SUMMARY");
    let temp = allQuestion[0];
    setChild(temp.data.child);
    setLabel(temp.data.label);
    setDescription({
      eng: temp.data.description,
      span: temp.data.description_ES,
    });
    setSummary([]);
    setdes([]);
    setPath([]);
    setMultiLinkArr([]);
    setImmigrationHistory([]);
    setFactorsOptions([]);
    setInadmissibilty([]);
    setFirstElement(temp);
    setLoader(false);
    let obj = {
      user_id: user.id,
    };
    deleteStateService(obj)
      .then(({ data }) => {
        setLoader(true);
      })
      .catch((err) => {
        setLoader(true);
        console.log("err", err);
      });
    history.push("/home");
  };

  const saveAndContinueLater = () => {
    let arr = path.map((ii) => ii.nodeId);

    //   let hav = []
    //   let obj = {}
    //  const newobj = path.map((item,ind)=>{
    //   return{
    //     [item.nodeId]:item?.optionId,
    //     type:item.type,
    //     id:ind+1
    //   }
    //   })
    // let resp = {}
    // for (let index = 0; index < newobj.length; index++) {
    //   console.log('index',newobj[index],'response',newobj[index]);
    //   obj[Object.keys(newobj[index])[0]] = newobj[index][Object.keys(newobj[index])[0]]
    //   obj['index'] = index
    // }
    // newobj.map((item,inx)=>{
    // debugger
    // console.log("kjfhbjksdfxbf",newobj[inx]);

    // obj[Object.keys(item)[0]] = item[Object.keys(item)[0]];

    // })
    let obj = {};
    // console.log('zianadsfa',sortObject(obj));
    // console.log("dfbv,dfxbxfdgb",newobj);
    path.forEach((resp, inx) => {
      if (resp.type === enumObj.multilink) {
        if (obj[resp.nodeId]) {
          obj[resp.nodeId] += `~${resp.optionId}`;
        } else {
          obj[resp.nodeId] = resp.optionId;
        }
      } else {
        if (obj[resp.nodeId]?.includes("~")) {
          obj[resp.nodeId] += `~${resp.optionId}`;
        } else {
          obj = {
            ...obj,
            [resp.nodeId]: resp.optionId,
          };
        }
      }
    });

    let ServerResp = {
      user_id: user.id,
      last_question: firstElement.id,
      prev_selections: JSON.stringify(obj),
      questions_order: JSON.stringify(arr),
      current_summary: JSON.stringify(summary),
    };

    setLoader(false);
    addOrUpdateQuestionService(ServerResp)
      .then(({ data }) => {
        // let temp = (data.messgae).split(".")
        toastMessage(
          <div style={{ textAlign: "left" }}>
            Your answers are saved.
            <br /> You may continue the guide later.
          </div>,
          "success"
        );

        setLoader(true);
      })
      .catch((err) => {
        setLoader(true);
        console.log("err", err);
      });
    // sessionStorage.setItem("OPTIONS", JSON.stringify(path));
    // sessionStorage.setItem("SUMMARY", JSON.stringify(summary));
    // setToastifySaved();
  };


  const loadPreviousQuestion = (questions) => {
    let convertedOptions = JSON.parse(sessionStorage.getItem("OPTIONS"));
    if (convertedOptions) {
      setPath(convertedOptions);
      let lastSelectedOption = convertedOptions[convertedOptions.length - 1];
      let lastQuestion = questions?.filter(
        (newItem, index) => newItem.id === lastSelectedOption.nodeId
      );
      let selectedChildOption = lastQuestion[0].data.child.filter(
        (newItem, index) => newItem.id === lastSelectedOption.optionId
      );
      var BreakException = {};

      if (!selectedChildOption.length) {
        lastQuestion[0].data.conditions?.forEach((conditionitem) => {
          try {
            let getVal = conditionitem?.conditionOptions?.filter(
              (ii) => ii.id === lastSelectedOption.optionId
            );
            if (getVal.length > 0) {
              selectedChildOption = getVal;
              throw BreakException;
            }
          } catch (error) {
            if (error !== BreakException) throw error;
          }
        });
      }

      let nextQuestion = questions?.filter(
        (newItem, index) => newItem.id === selectedChildOption[0]?.targetNode
      );
      setFirstElement(nextQuestion[0]);
      setSummary(JSON.parse(sessionStorage.getItem("SUMMARY")));
    }

    //MOVE TO HOME SCREEN
  };
  const getQuesetionState = (allQustn) => {
    let obj = {
      user_id: user.id,
    };
    setSummary([]);

    getQuestionStateService(obj)
      .then(({ data: { data } }) => {
        let parseSumary = JSON.parse(data.current_summary);
        let lastQuestionId = data.last_question;
        let parsePrevSelect = JSON.parse(data.prev_selections);
        let allQuestionsIds = JSON.parse(data.questions_order);

        if (parsePrevSelect) {
          let arr = [];

          let clonePath = [...path];
          let childArr = [];
          let cloneMultiLinkArr = [...multiLinkArr];

          allQuestionsIds.forEach((ndId) => {
            if (parsePrevSelect[ndId]?.includes("~")) {
              let findArr = arr.filter((ii) => ii.nodeId === ndId);
              // debugger
              // if(findArr.length === (parsePrevSelect[ndId].split("~").length - 1)){
              let getQuestion = allQustn.find((ii) => ii.id == ndId);
              let matchVal = null;
              getQuestion.data.conditions.filter((echCondtn) => {
                let val = echCondtn.conditionOptions.find(
                  (iii) =>
                    iii.id === parsePrevSelect[ndId].split("~")[findArr.length]
                );
                if (val) {
                  matchVal = val;
                }
              });
              if (matchVal && matchVal.targetNode) {
                arr.push({
                  type: enumObj.link,
                  nodeId: ndId,
                  optionId: parsePrevSelect[ndId].split("~")[findArr.length],
                });
              } else {
                arr.push({
                  type: enumObj.multilink,
                  nodeId: ndId,
                  optionId: parsePrevSelect[ndId].split("~")[findArr.length],
                });
                cloneMultiLinkArr.push({
                  type: enumObj.multilink,
                  id: parsePrevSelect[ndId].split("~")[findArr.length],
                  nodeId: ndId,
                  optionId: parsePrevSelect[ndId].split("~")[findArr.length],
                });
              }
            } else {
              if (parsePrevSelect[ndId]) {
                let selectedNode = allQustn?.find((ii) => ii.id === ndId);
                let selectOption = selectedNode.data.child.find(
                  (findEl) =>
                    findEl.text === hardtext.Married ||
                    findEl.text === hardtext.notMarried ||
                    findEl.text === hardtext.ageUnder21 ||
                    findEl.text === hardtext.ageOver21
                );
                if (selectOption) {
                  cloneMultiLinkArr.push({
                    type: enumObj.link,
                    id: selectOption.id,
                    nodeId: selectedNode.id,
                    optionId: selectOption.id,
                    text: selectOption.text,
                  });
                  arr.push({
                    type: enumObj.link,
                    nodeId: ndId,
                    optionId: parsePrevSelect[ndId],
                    text: selectOption.text,
                  });
                } else {
                  arr.push({
                    type: enumObj.link,
                    nodeId: ndId,
                    optionId: parsePrevSelect[ndId],
                  });
                }
              } else {
                // clonePath.push({
                //   type:enumObj.link,
                //   nodeId:ndId,
                //   optionId:parsePrevSelect[ndId]
                // })
              }
            }
          });
          setPath(arr);
          setMultiLinkArr(cloneMultiLinkArr);
        }
        if (lastQuestionId) {
          let findQuestion = allQustn.filter(
            (item, index) => item.id === lastQuestionId
          );
          setFirstElement(findQuestion[0]);
        }

        if (parseSumary && parseSumary.length > 0) {
          setSummary(parseSumary);
        }
      })
      .catch((err) => { });
  };
  const getAllQuestion = () => {
    setLoader(false);
    getQuestionService()
      .then(({ data: { data } }) => {
        let bytes = CryptoJS.AES.decrypt(data, decryption.key);
        let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        if (decryptedData) {
          setQuestionScreenView(true);
        }
        setLoader(true);

        setPath([
          {
            nodeId: decryptedData.questions[0]?.id,
            optionId: decryptedData.questions[0]?.data?.child[0]?.id,
            text: decryptedData.questions[0]?.data?.child[0]?.text,
            type: "link",
          },
        ]);
        setAllQuestion(decryptedData.questions);
        setAllQuest(decryptedData.questions);
        setTempQuestion(decryptedData.questions);
        // sessionStorage.removeItem("OPTIONS");
        if (sessionStorage.getItem("OPTIONS") != null) {
          loadPreviousQuestion(decryptedData.questions);
        } else {
          setFirstElement(decryptedData.questions[1]);
        }
        getQuesetionState(decryptedData.questions);
      })
      .catch((err) => {
        setLoader(true);
        console.log("err", err);
      });
  };
  useEffect(() => {
    setLoader(true);
    // loadPreviousQuestion(initialElements)
    getAllQuestion();
  }, []);
  const openActionLinkModal = (ele) => {
    let a = document.createElement("a");
    console.log(
      ele.link,
      ele.link.includes("http://"),
      ele.link.includes("https://")
    );
    if (ele.link.includes("http://") || ele.link.includes("https://")) {
      a.target = "_blank";
      a.href = ele.link+`${ele?.id ? '?id='+ele?.id:""}`;
      a.click();
    } else {
      let str = "https://" + ele.link+`${ele?.id ? '?id='+ele?.id:""}`;
      a.target = "_blank";
      a.href = str;
      a.click();
    }
    // setActionLinkContent(ele);
    // setactionModal(true)
  };
  const closeActionModal = () => setactionModal(!actionModal);
  const closeReportModal = () => setShowReport(!showReport);

  const summaryEmail = () => {
    setLoaderE(true);
    let obj = {
      immigrationHistory: immigrationHistory,
      factorsOptions: factorsOptions,
      inadmissibilty: inadmissibilty,
    };

    sendSummaryThroughEmail(obj)
      .then(({ data }) => {
        toastMessage(
          <div style={{ textAlign: "left" }}>
            E-mail sent successfully.
            <br /> Please check your inbox.
          </div>,
          "success"
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setLoaderE(false));
  };

  const [sidebar, setSidebar] = useState(true);
  const showSidebar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(!sidebar);
    }
  };
  const hideSideBar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(false);
    }
  };


  return (
    <div className="question">
      {path?.length ? (
        <Navigation
          label={""}
          width={setClientWidth}
          onPress={() => goBack()}
          sidebar={sidebar}
          showSidebar={showSidebar}
          setSidebar={setSidebar}
        />
      ) : (
        <Navigation
          label={""}
          width={setClientWidth}
          onPress={() => history.goBack()}
          sidebar={sidebar}
          showSidebar={showSidebar}
          setSidebar={setSidebar}
        />
      )}
      <div
        className={`main ${clientWidth >= (clientInnerWidth.width+1) && "margin300"
          }`}
        onClick={hideSideBar}
      >
        <div style={{ paddingBottom: "10px" }}>
          <ToastContainer />
          <ViewSummary
            immigrationHistory={immigrationHistory}
            factorsOptions={factorsOptions}
            inadmissibility={inadmissibilty}
            summaryEmail={summaryEmail}
            loaderE={loaderE}
            multiLinkArr={multiLinkArr}
          />
          <br />
          <div className={(browserName == "Mobile Safari" || isIOS) ? "opt AllNode" : "opt"} style={{ width: "95%", paddingLeft: "12px" }}>
            {des?.length > 0 ? (
              des?.map((desitem, sinx) => {
                return (
                  <div
                    style={{
                      // borderBottom: `${des?.length > 1 ? "1px solid black" : ""
                      //   }`,
                      marginBottom: `${des?.length > 1 ? "4rem" : ""}`,
                    }}
                  >
                    <div
                      key={sinx}
                      style={{ marginLeft: "5%" }}
                      dangerouslySetInnerHTML={{
                        __html:
                          lang == language.spanish ? desitem.span : desitem.eng,
                      }}
                    ></div>
                    <div className="options" style={{ marginTop: "2rem" }}>
                      {desitem?.child?.map((sitem, index) => {
                        return sitem?.title ? (
                          <div key={index} className="opt-s">
                            <button
                              className="btn btn-ques btnActionLink"
                              onClick={() => openActionLinkModal(sitem)}
                            >
                              {lang == language.spanish
                                ? sitem.title_ES
                                : sitem.title}
                            </button>
                          </div>
                        ) : multiLinkArr.find((ii) => ii?.id === sitem?.id)
                          ?.id == sitem.id ? (
                          <div key={index} className="opt-s">
                            <button
                              className="btn btn-active"
                              onClick={() => findNext(sitem)}
                            >
                              {lang == language.spanish
                                ? sitem.text_ES
                                : sitem.text}
                            </button>
                          </div>
                        ) : (
                          <div key={index} className="opt-s">
                            <button
                              className={
                                boolforButton && sitem.targetNode != null
                                  ? "btn btn-ques btnMultiLinkPersist"
                                  : sitem.targetNode == null ? "btn btn-ques btnForMultiLink" : "btn btn-ques"
                              }
                              onClick={() => findNext(sitem)}
                              style={
                                boolforButton && sitem.targetNode != null
                                  ? { background: "black", color: "white" }
                                  : {}
                              }
                            >
                              {lang == language.spanish
                                ? sitem.text_ES
                                : sitem.text}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
                //   return multiLinkArr.find(ii=>ii.id === item.id)?.id == item.id? (
                //     <div key={index} className="opt-s">
                //      <button
                //         className="btn btn-active"
                //         onClick={() => findNext(item)}
                //       >
                //        {item.text}
                //       </button>
                //     </div>
                //   ):<div key={index} className="opt-s">
                //  <button
                //     className="btn btn-ques"
                //     onClick={() => findNext(item)}
                //   >
                //     {item.text}
                //   </button>

                // </div>
              })
            ) : (
              <>
                <div
                  className={(browserName == "Mobile Safari" || isIOS) ? "ques1" : "ques"}
                  dangerouslySetInnerHTML={{
                    __html: lang == "sp" ? description.span : description.eng,
                  }}
                ></div>
                <div className="options" style={{ marginTop: "2rem" }}>
                  {child?.map((item, index) => {
                    return item?.title ? (
                      <div key={index} className="opt-s">
                        <button
                          className="btn btn-ques btnActionLink"
                          onClick={() => openActionLinkModal(item)}
                        >
                          {lang == language.spanish
                            ? item?.title_ES
                            : item?.title}
                        </button>
                      </div>
                    ) : multiLinkArr.find((ii) => ii?.id === item?.id)?.id ==
                      item.id ? (
                      <div key={index} className="opt-s">
                        <button
                          className="btn btn-active"
                          onClick={() => findNext(item)}
                        >
                          {item.text}
                        </button>
                      </div>
                    ) : (
                      <div key={index} className="opt-s">
                        {lang == language.spanish && item?.text_ES ? (
                          <button
                            className="btn btn-ques"
                            onClick={() => findNext(item)}
                          >
                            {item.text_ES}
                          </button>
                        ) : item?.text ? (
                          <button
                            className="btn btn-ques"
                            onClick={() => findNext(item)}
                          >
                            {item.text}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
        <div className={(browserName == "Mobile Safari" || isIOS) ? "foot foot1 mbtm" : "foot foot1"}>
          {path.length > 1 ? (
            <>
              <div className="foots">
                <Footer
                  label={t("GoBackPage")}
                  Svg={
                    <GoToBack
                      className="footer-btn-icon questionBtnSvgs"
                      style={{ marginLeft: "-10px" }}
                    />
                  }
                  onPress={() => goBack()}
                />
                <Footer
                  label={t("SaveForLater")}
                  onPress={saveAndContinueLater}
                  Svg={
                    <ContinueLater
                      className="footer-btn-icon questionBtnSvgs"
                      style={{ marginLeft: "-11px" }}
                    />
                  }
                />
                <ModalRestart onPress={handleRestart} />
                <button onClick={() => handleReport()} className="btn btn-foot btn-foot1">
                  <GetHelp
                    className="footer-btn-icon questionBtnSvg1 marginReport"
                    style={{ marginLeft: "-10px" }}
                  />
                  <span className="ques-foo ques-foo1" style={{ top: "0px" }}>
                    {t("help")}
                  </span>
                </button>
                {/* {
                !user?.is_guest && (immigrationHistory.length || factorsOptions.length || inadmissibilty.length ?
                  <Footer
                    label={
                      loaderE ? <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner> :
                        "Email these results"}
                    onPress={summaryEmail}
                    Svg={!loaderE ? <ClientEmail /> : ""}
                  /> : "")
              } */}
              </div>

              {/* <div className="footer container">
                <button type="submit" className="btn btn-main" onClick={()=>history.push("/schedule")}>
                  {t("consultant")}
                </button>
              </div> */}
            </>
          ) : null}
        </div>
      </div>

      {!loader && <Loader />}
      <ImmigrationModal
        show={actionModal}
        handleClose={closeActionModal}
        resp={actionLinkContent}
      />
      <ReportModal
        show={showReport}
        handleClose={closeReportModal}
        path={path}
        allQuestion={allQuest}
        currentNode={firstElement}
      />
    </div>
  );
};

export default Question;
