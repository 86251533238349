import React from "react";
import { Helmet } from "react-helmet";

function MetaInject({ description, title, url, imageUrl }) {
  return (
    <div>
      <Helmet>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={url} />
      </Helmet>
    </div>
  );
}

export default MetaInject;
