import { t } from "i18next";
import React, { useState, useMemo, useEffect } from "react";
import { Info, Dollar, Location, Additional } from "../../assets";
import "../../shared/styles/css/account.css";
import Navigation from "../navigation";
import { updateProfileService } from "../../shared";
import { toastMessage } from "../../shared/component/toast";
import { Dropdown, Row, Col } from "react-bootstrap";
import Loader from "../../shared/loader/loader";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../shared/Redux/Reducers/userSlice";
import { clientInnerWidth } from "../../shared/util/constant";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import countryList from "react-select-country-list";
import CountryList from "country-list-with-dial-code-and-flag";
import { getAnalytics, logEvent } from "firebase/analytics";
import { browserName, browserVersion, isIOS } from "react-device-detect";
import { getProfileService } from "../../shared/services"

const Account = () => {

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.root);


  const [clientWidth, setClientWidth] = useState(0);
  const [countryFilter, setCountryFilter] = useState([]);
  const [countryCode, setCountryCode] = useState(
    user.user?.phone_no
      ? user.user.phone_no.substring(0, user.user.phone_no.length - 10)
      : ""
  );
  const [fname, setFname] = useState(
    user.user?.first_name ? user.user?.first_name : ""
  );
  const [lname, setLname] = useState(
    user.user?.last_name ? user.user?.last_name : ""
  );
  const [email, setEmail] = useState(user.user?.email ? user.user?.email : "");
  const [phone, setPhone] = useState(
    user.user?.phone_no
      ? user.user.phone_no.slice(user.user.phone_no.length - 10).replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
      : ""
  );
  const [street, setStreet] = useState(
    user.user?.user_address?.street_address
      ? user.user?.user_address?.street_address
      : ""
  );
  const [country, setCountry] = useState(
    user.user?.user_address?.country ? user.user?.user_address?.country : ""
  );
  const [state, setState] = useState(
    user.user?.user_address?.state ? user.user?.user_address?.state : ""
  );
  const [city, setCity] = useState(
    user.user?.user_address?.city ? user.user?.user_address?.city : ""
  );
  const [zip, setZip] = useState(
    user.user?.user_address?.zip_code ? user.user?.user_address?.zip_code : ""
  );
  const [loader, setLoader] = useState(false);
  const [countriesArr, setCountriesArr] = useState([]);
  const [emailForUS, setEmailForUS] = useState("");
  const [fNameUS, setFNameUS] = useState("");
  const [lNameUS, setLNameUS] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const getProfileData = () => {
    getProfileService()
      .then((data) => {
        if (data && data?.data?.data?.user.hasOwnProperty('petitioner_detail')) {
          if (data?.data?.data?.user.petitioner_detail != null) {
            console.log("data", data?.data?.data?.user.petitioner_detail)
            setEmailForUS(data?.data?.data?.user.petitioner_detail.email)
            setFNameUS(data?.data?.data?.user.petitioner_detail?.first_name)
            setLNameUS(data?.data?.data?.user.petitioner_detail?.last_name)
          }
        }

      })
      .catch((err) => {
        console.log("err", err)
      })
  }

  useEffect(() => {
    getProfileData();
  }, [])

  const getCountries = (options) => {
    let placehold = options.find((item) => item.label == country);
    setCountry(placehold);
    let temp = [];
    for (let i = 0; i < options.length; i++) {
      temp.push(options[i].label);
    }
    setCountriesArr(temp);
  };

  useEffect(() => {
    getCountries(options);
  }, []);


  const changeHandler = (value) => {
    setCountry(value);
  };

  const SelectCountry = (e) => {
    let temp = CountryList.filter(
      (country) => country.name + " " + country.flag == e.target.innerText
    );
    temp.unshift();
    setCountryCode(temp[0].dial_code);
  };

  const formatPhoneNumber = (e) => {
    setPhone((e.target.value).replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
  };




  const handleSubmit = () => {
    // e.preventDefault()
    let obj = {
      first_name: fname,
      last_name: lname,
      email: email,
      phone_no: countryCode + phone.split("-").join(""),
      street_address: street,
      country: country.label,
      state: state,
      city: city,
      zip_code: zip,
    };
    console.log("lNameUS",{emailForUS,fNameUS,lNameUS})

    if (emailForUS &&  emailForUS.length > 0   ) {
      obj['petitioner_email'] = emailForUS;
      obj['is_us_citizen'] = 1
    }
    if( fNameUS && fNameUS.length > 0){
      obj['petitioner_firstname'] = fNameUS;
    }
    if(lNameUS && lNameUS.length > 0){
      obj['petitioner_lastname'] = lNameUS;
    }

    setLoader(true);

    updateProfileService(obj)
      .then(({ data: { data } }) => {
        let userObj = {
          access_token: user.access_token,
          user: data?.user,
        };
        dispatch(login(userObj));

        setLoader(false);
        toastMessage(
          <div style={{ textAlign: "left" }}>
            Successfully updated your profile.
          </div>,
          "success"
        );
      })
      .catch((err) => {
        setLoader(false);
        toastMessage(err?.response?.data?.message, "error");
      });
  };

  const filterCountryList = (e) => {
    let countryCodeArr = [];
    let temp = CountryList.map((i) => {
      if (i.name.toLowerCase().includes(e.target.value.toLowerCase())) {
        countryCodeArr.push(i);
      }
    });
    setCountryFilter(countryCodeArr);
  };

  // const showSidebar = () => setSidebar(!sidebar);
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(!sidebar);
    }
  };

  const hideSideBar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(false);
    }
  };

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'Profile Details');
  }, [])
  return (
    <>
      <Navigation
        label={t("accountDetail")}
        width={setClientWidth}
        sidebar={sidebar}
        showSidebar={showSidebar}
        setSidebar={setSidebar}
      />

      <div
        className={`signin-form ${clientWidth > clientInnerWidth.width && "margin300"
          }`}
        // onSubmit={(e) => handleSubmit(e)}
        // onClick={hideSideBar}
        style={(browserName == "Mobile Safari" || isIOS) ? { marginBottom: "45%" } : {}}
      >
        <div className="container cont-acc-after">
          <div className="container">
            <ul className="acc-after1">
              <li>
                <img src={Info} />
              </li>
              <li>
                <p className="p-acc">{t("information")}</p>
              </li>
            </ul>
            <div className="row">
              <div className="col">
                <label for="fname">{t("fname")}</label>
              </div>
              <div className="col">
                <label for="lname">{t("lname")}</label>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <input
                  onChange={(e) => setFname(e.target.value)}
                  value={fname}
                  type="text"
                  className="acc-inpu-after"
                  placeholder="Jake"
                  id="fname"
                  required
                />
              </div>
              <div className="col">
                <input
                  onChange={(e) => setLname(e.target.value)}
                  type="text"
                  className="acc-inpu-after"
                  placeholder="Muss"
                  id="lname"
                  value={lname}
                  required
                />
              </div>
            </div>

            <Row>
              {/* <Col sm={3} className="listCountryCol" >
                <label>Code</label>
              </Col> */}
              <Col>
                <label>{t("phoneNo")}</label>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ width: "auto" }}>
                <Dropdown>
                  <Dropdown.Toggle
                    className="phoneCode"
                    variant="success"
                    id="dropdown-basic"
                  >
                    {countryCode}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropCode">
                    <input
                      className="countryList"
                      placeholder="Search Country..."
                      onChange={(e) => filterCountryList(e)}
                    />
                    {countryFilter.length > 0
                      ? countryFilter.map((country) => {
                        return (
                          <>
                            <Dropdown.Item
                              href="#"
                              onClick={(e) => SelectCountry(e)}
                            >
                              {country.name + " " + country.flag}
                            </Dropdown.Item>
                          </>
                        );
                      })
                      : CountryList.map((country) => {
                        return (
                          <>
                            <Dropdown.Item
                              href="#"
                              onClick={(e) => SelectCountry(e)}
                            >
                              {country.name + " " + country.flag}
                            </Dropdown.Item>
                          </>
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col>
                <input
                  className="signin-input w-100"
                  onChange={(e) => formatPhoneNumber(e)}
                  type="phone"
                  // pattern="[0-9\s]{13,19}"
                  autocomplete="cc-number"
                  maxLength="12"
                  // pattern="\d*"
                  inputMode={"numeric"}
                  id="PhoneNum"
                  value={phone}
                  required
                />
              </Col>
            </Row>

            {/* <label for="PhoneNum">{t("phoneNo")}</label>
            <PhoneInput
              country={"us"}
              value={phone}
              onChange={(phone) => setPhone(phone)}
            /> */}
            {/* <input
              onChange={(e) => setPhone(e.target.value)}
              type="number"
              className="acc-input-after"
              pattern="[0-9\s]{13,19}"
              autocomplete="cc-number"
              maxlength="16"
              id="PhoneNum"
              placeholder="0009872132"
              value={phone}
              required
            /> */}
            <label for="email" className="mt-3">
              {t("email")}
            </label>
            <div placeholder="jakemuss0@gmail.com" className="acc-input-after">
              {email}
            </div>
            {/* <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="acc-input-after"
                id="email"
                disabled={true}
                value={email}
                placeholder="jakemuss0@gmail.com"
                required
              /> */}
            <ul className="acc-after1">
              <li>
                <img src={Location} />
              </li>
              <li>
                <p className="p-acc">{t("address")}</p>
              </li>
            </ul>
            <label for="Street">{t("street")}</label>
            <input
              onChange={(e) => setStreet(e.target.value)}
              type="text"
              className="acc-input-after"
              id="Street"
              placeholder="115 Sunset. St."
              value={street}
              required
            />
            <label for="city">{t("city")}</label>
            <input
              onChange={(e) => setCity(e.target.value)}
              type="text"
              className="acc-input-after"
              id="city"
              placeholder="Inglewood"
              required
              value={city}
            />

            <label for="state">{t("state")}</label>
            <input
              onChange={(e) => setState(e.target.value)}
              type="text"
              className="acc-input-after"
              id="state"
              placeholder="CA."
              value={state}
              required
            />
            <label for="postal">{t("zip")}</label>
            <input
              onChange={(e) => setZip(e.target.value)}
              type="number"
              className="acc-input-after"
              id="postal"
              placeholder="90301"
              value={zip}
              required
            />
            <label for="country">{t("country")}</label>
            {/* <input
              onChange={(e) => setCountry(e.target.value)}
              type="text"
              className="acc-input-after"
              id="country"
              value={country}
              placeholder="United States"
              required
            /> */}
            <div style={{ position: "relative" }}>
              <Select
                options={options}
                // inputProps={inputProps}
                value={country}
                onChange={changeHandler}
                className="country-input1"
              />
            </div>
            <ul className="acc-after1">
              <li>
              👨‍👩‍👧‍👦
                {/* <img src={Additional} style={{ height: "35px" }} /> */}
              </li>
              <li>
                <p className="p-acc">{t("InformationForUS")}</p>
              </li>
            </ul>
            {/* <label for="postal" className="mt-2" style={{ opacity: "1" }}>{t("InformationForUS")}</label> */}
            <label for="postal" className="mt-2">{t("InformationStatement")}</label>

            <label for="postal" className="mt-4">{t("fname")}</label>
            <input
              onChange={(e) => setFNameUS(e.target.value)}
              type="text"
              className="acc-input-after"
              id="usFName"
              placeholder=""
              value={fNameUS}
            />
            <label for="postal">{t("lname")}</label>
            <input
              onChange={(e) => setLNameUS(e.target.value)}
              type="text"
              className="acc-input-after"
              id="usLName"
              placeholder=""
              value={lNameUS}
            />


            {/* <label for="postal">{t("EmailForUs")}</label> */}
            <label for="emailForUs">{t("EmailForUs").substring(0,5)}<b className="signUpPertitionEmailBold">{t("EmailForUs").slice(5,110)}</b></label>
            <input
              onChange={(e) => setEmailForUS(e.target.value)}
              type="email"
              className="acc-input-after"
              id="email"
              placeholder=""
              value={emailForUS}
            />
          </div>
        </div>

        <div className="footer container cont-acc-after marginBottomSpaceOnTab">
          <div className="container">
          <button onClick={()=> handleSubmit()} className="btn  btn-main">
            {t("done")}
          </button>
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </>
  );
};

export default Account;
