import React, { useState } from "react";
import SigninForm from "../../shared/form/signinFormPopup";
import SignupForm from "../../shared/form/signupFromPopup";
import ForgotForm from "../../shared/form/forgotFormPopup";
import GuestPaymentForm from "../../shared/form/guestPayment";
import PayForm from "../../shared/form/payForm";
export default function GuestWrapper({
  guestForm,
  setGuestForm,
  switchForm,
  PackageDetail,
  quantity,
  priceCheck,
  check,
  Name,
  setSwitchForm,
  handleSumit,
  handleClose,
  consultation,
  Logincheck,
  setLogincheck,
  phoneNumber,
  setPhoneNumber,
  countryCode,
  setCountryCode,
  resetCloseModal,
  setShowPaidModal

}) {
  
  switch (switchForm) {
    case "payment":
      return (
        <GuestPaymentForm
          setSwitchForm={setSwitchForm}
          guestForm={guestForm}
          setGuestForm={setGuestForm}
          check={Logincheck}
          setCheck={setLogincheck}
          handleSumit={handleSumit}
          handleClose={handleClose}
          phone={phoneNumber}
          setPhone={setPhoneNumber}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
        />
      );
    case "pay":
      return (
        <PayForm
          setSwitchForm={setSwitchForm}
          PackageDetail={PackageDetail}
          quantity={quantity}
          priceCheck={priceCheck}
          check={check}
          Name={Name}
          Logincheck={Logincheck}
          guestForm={guestForm}
          setGuestForm={setGuestForm}
          handleClose={handleClose}
          consultation={consultation}
          setShowPaidModal={setShowPaidModal}
        />
      );
    case "signin":
      return (
        <SigninForm setSwitchForm={setSwitchForm} handleClose={handleClose} />
      );
    case "signup":
      return <SignupForm setSwitchForm={setSwitchForm} />;
    case "forgot":
      return <ForgotForm setSwitchForm={setSwitchForm} />;
    default:
      return (
        <SigninForm setSwitchForm={setSwitchForm} handleClose={handleClose} />
      );
  }
}
