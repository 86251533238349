import React, { useState, useEffect } from "react";
import Navigation from "../navigation";
import { Facebook, Twitter } from "../../assets";
import "../../shared/styles/css/package.css";
import News from "../../data/dataforNews.json";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  browserName,
  browserVersion,
  isMobile,
  isIOS,
} from "react-device-detect";
import {
  getPackageServices,
  subPaymentService,
  getServiceHistory,
} from "../../shared";
import { Dropdown } from "react-bootstrap";
import Loader from "../../shared/loader/loader";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useSelector } from "react-redux";
import { toastMessage } from "../../shared";
import ConfirmModal from "../../shared/component/Modals/confirmationModal";
import { clientInnerWidth } from "../../shared/util/constant";
import PayModal from "../../shared/component/Modals/Pay";
import GuestModal from "../../pages/schedule/GuestModel";
import Congrats from "../../shared/component/Modals/Congrats";
import MetaInject from "../../shared/component/metaInject";

const Package = () => {
  const {
    user: { user },
    lang: { lang },
  } = useSelector((state) => state.root);

  const [switchForm, setSwitchForm] = useState("payment");
  const [Logincheck, setLogincheck] = useState(false);
  const [packageList, setPackageList] = useState([]);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(true);
  const [clientWidth, setClientWidth] = useState(0);
  const [amount, setAmount] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [showPay, setShowPay] = useState(false);
  const [priceCheck, setPriceCheck] = useState(0);
  const [duration, setDuration] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [total, setTotal] = useState(0);
  const location = useLocation();

  const [dropBool, setDropBool] = useState(false);
  const [priceId, setPriceId] = useState({});
  const [planName, setPlanName] = useState("");
  const [purchasedId, setPurchasedId] = useState(false);
  const [showPaidModal, setShowPaidModal] = useState(false);
  const [guestForm, setGuestForm] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    stAddress: "",
    city: "",
    state: "",
    zip: "",
    country: { value: "US", label: "United States" },
    pFname: "",
    plName: "",
    pEmail: "",
    password: "",
    confrmPassword: "",
  });
  const [countryCode, setCountryCode] = useState("+1");
  const [phoneNumber, setPhoneNumber] = useState("");
  const queryString = new URLSearchParams(location.search).get("id");
  const modalClose = () => {
    setShowPaidModal(false);
    window.location.reload();
  };
  const params = useParams();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const closeModal = () => setConfirmModal(false);
  const handleModalPay = () => setShowPay(false);
  const handleModalClose = () => setShow(false);
  const PackagePlan = () => {
    if (/\s/g.test(params.id)) {
      history.replace({
        pathname: `/package/${params.id.replace(/\s/g, "-").toLowerCase()}`,
      });
    }
    getPackageServices()
      .then(({ data: { data } }) => {
        setLoader(false);
        let temp = data.filter(
          (item) => item.slug == params.id.replace(/\s/g, "-")
        );
        if (!temp.length) {
          temp = data.filter((item) => item.id == Number(queryString));
          history.replace({
            pathname: `/services/${temp[0].slug
              .replace(/\s/g, "-")
              .toLowerCase()}`,
          });
        } else {
          history.replace({
            pathname: `/services/${temp[0].slug
              .replace(/\s/g, "-")
              .toLowerCase()}`,
          });
        }
        setPriceCheck(temp[0].plan_prices.length);
        setDuration(temp[0].duration);

        if (temp[0].plan_prices.length > 0 && temp[0].plan_prices.length == 1) {
          setDropBool(false);
          setPriceId(temp[0].plan_prices[0]);
        } else {
          setPriceId(temp[0].plan_prices[0]);
          setDropBool(true);
        }
        setPlanName(temp[0].plan_name);
        setPackageList(temp);
        setTotal(temp[0]?.plan_prices[0]?.price);
        setAmount(temp[0]?.plan_prices[0]?.price);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    PackagePlan();
  }, [guestForm.pEmail]);

  const itemsEvent = (e) => {
    setQuantity(e.target.value);
    let temp = amount * Number(e.target.value);
    setTotal(temp);
  };

  const gettingAmount = (e, pr) => {
    setPriceId(pr);
    setDropBool(true);
    let eText = Number(e.target.innerText.replace(",", "").match(/\d+/)[0]);
    setAmount(eText);
    let temp = eText * quantity;
    setTotal(temp);
  };

  const subscriptionPackage = (e) => {
    // setShowPay(true)
    // setConfirmModal(false);
    // e.preventDefault();
    // if (!user?.is_guest) {
    //   if (user.is_complete_profile) {
    //     if (user.stripe_payment_method) {
    //       setLoader(true);
    //       closeModal()
    //       let getState = location.state;
    //       let obj = {
    //         stripe_payment_method: user.stripe_payment_method,
    //         plan_id: params.id,
    //       };
    //       subPaymentService(obj)
    //         .then(({ data }) => {
    //           console.log("data", data);
    //           if (data.status) {
    //             toastMessage(t("packageSubscription"), "success");
    //           } else {
    //             toastMessage(t("planAlreadyBuy"), "error");
    //           }
    //           setLoader(false);
    //         })
    //         .catch((err) => {
    //           setLoader(false);
    //           console.log(err);
    //         });
    //     } else {
    //       toastMessage(t("addPaymentDetail"), "error");
    //     }
    //   } else {
    //     toastMessage(t("CompleteProfileError"), "error");
    //   }
    // } else {
    //   history.push('/signIn')
    //   // toastMessage("You are Guest User, First Create Your Account", "error");
    // }
  };

  const handleSubmit = () => {
    if (purchasedId) {
      toastMessage(t("Already"), "error");
    } else {
      if (user?.is_guest == 1) {
        setShow(true);
        // history.push("/signin")
      } else {
        setShowPay(true);
      }
    }
  };

  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(!sidebar);
    }
  };
  const hideSideBar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(false);
    }
  };

  useEffect(() => {
    if (planName) {
      const analytics = getAnalytics();
      logEvent(analytics, `Service Details-(${planName})`);
    }
  }, [planName]);

  useEffect(() => {
    setTimeout(function () {
      let link = document.getElementsByTagName("a");
      for (let i = 0; i < link.length; i++) {
        let temp = link[i].href;
        if (temp.includes("bordercrossinglaw.as.me")) {
          link[i].href = "https://openborders.io/schedule";
          link[i].target = "_blank";
        }
      }
    }, 1000);
  }, [location, loader]);

  const ServiceHisotry = () => {
    getServiceHistory()
      .then(({ data: { data } }) => {
        let temp1 = data?.purches_plan_history;

        let tempID = temp1.filter(
          (i) => i.plan.slug == params.id.replace(/\s/g, "-")
        );
        if (
          tempID.length > 0 &&
          tempID.find((ii) => ii.plan.plan_type == "single")
        ) {
          setPurchasedId(false);
        } else if (tempID.length) {
          setPurchasedId(true);
        } else {
          setPurchasedId(false);
        }

        let temp = [];
        data?.purches_plan_history?.forEach((i) => {
          temp.push(i.plan_id);
        });
      })
      .catch((err) => {});
  };
  useEffect(() => {
    ServiceHisotry();
  }, [packageList]);

  function extractTextFromHTML(html) {
    // Create a temporary element to parse the HTML
    var tempElement = document.createElement("div");
    tempElement.innerHTML = html;

    // Use innerText to extract text and trim any leading/trailing spaces
    var text = tempElement.innerText.trim();

    return text;
  }

  console.log("packageList", packageList[0]);

  return (
    <>
      <Navigation
        label={t("package-detail")}
        width={setClientWidth}
        onPress={() => history.goBack()}
        sidebar={sidebar}
        showSidebar={showSidebar}
        setSidebar={setSidebar}
      />

      <form
        className={`${clientWidth > clientInnerWidth.width && "margin300"}`}
        onSubmit={(e) => {
          e.preventDefault();
          // setConfirmModal(true)
        }}
        onClick={hideSideBar}
      >
        <div className="mains">
          {packageList ? (
            <div>
              <img
                className="imgPackageDetails"
                src={
                  "https://admin.openborders.io/public/" + packageList[0]?.image
                }
              />
            </div>
          ) : (
            ""
          )}
          <div className="cont-n">
            {packageList.map((post) => {
              return (
                <>
                  {/* <div className="imgBackDiv mb-4">
                    <img
                      className="imgPackage"
                      src={
                        "https://admin.openborders.io/public/" +
                        post.image
                      }
                    />
                  </div> */}

                  <h1 className="news-h">
                    {" "}
                    {!lang || lang == "en" ? post.plan_name : post.plan_name_es}
                  </h1>

                  <p className="date-price">
                    <span className="planPrice">
                      {/* {post.plan_type == "recurring" ? t("From") : ""} */}
                      {post.plan_type == "single" && post.plan_prices.length > 1
                        ? `From $${post.amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        : post.plan_type == "recurring" &&
                          post.plan_prices.length > 1
                        ? `From $${post.amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${t(
                            "amountPackage1"
                          )}`
                        : post.plan_type == "single"
                        ? `$${post.amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        : `$${post.amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${t(
                            "amountPackage"
                          )} ${post.duration} ${t("months")}`}
                      {/* ${post.amount} {t("amountPackage")} {post.duration} {t("months")} */}
                      {/* {total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                      {/* {post.plan_prices.length > 1 ? t("From") : ""}$
                      {total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                    </span>
                  </p>
                  {!lang || lang == "en" ? (
                    <div
                      className="news-p"
                      dangerouslySetInnerHTML={{
                        __html: post.description,
                      }}
                    ></div>
                  ) : (
                    <div
                      className="news-p"
                      dangerouslySetInnerHTML={{
                        __html: post.description_es,
                      }}
                    ></div>
                  )}

                  {/* <a target="_blank" href="https://facebook.com/">
                    <img src={Facebook} />
                  </a>
                  <a target="_blank" href="https://twitter.com/">
                    <img className="m-2" src={Twitter} />
                  </a> */}
                  <div className="pack"></div>
                  <div className="am-div">
                    <ul className="ul-pack">
                      <li className="li-pack">
                        <p style={{ color: "black" }}>{t("amount")}</p>
                        {post.plan_type == "single" &&
                        post.plan_prices.length == 1 ? (
                          <div style={{ position: "relative" }}>
                            <span
                              style={{
                                position: "absolute",
                                top: "7px",
                                left: "5%",
                                color: "white",
                              }}
                            >
                              $
                            </span>
                            <input
                              className="ipt-pack"
                              style={{
                                paddingLeft: "19px",
                                top: "7px",
                                left: "3%",
                                // width: "270px",
                                color: "white",
                              }}
                              placeholder="1"
                              maxLength={9}
                              value={post.amount
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              min="1"
                              max="10"
                              required
                              disabled
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "-25%",
                                top: "7px",
                                color: "white",
                              }}
                              className="w-100"
                            >
                              {" "}
                              {/* {t("amountPackage")} {post.duration} {t("months")}{" "} */}
                            </span>
                          </div>
                        ) : // <div style={{ position: "relative" }}>
                        //   asdflk;asdjf;lajsdfl;
                        //   <span
                        //     style={{
                        //       position: "absolute",
                        //       top: "7px",
                        //       left: "5%",
                        //       color: "white"
                        //     }}
                        //   >
                        //     {" "}
                        //     ${" "}
                        //   </span>
                        //   <input
                        //     className="ipt-pack packInput"
                        //     // style={{ paddingLeft: "25px", top: "7px", left: "3%", color: "#827564" }}
                        //     placeholder="1"
                        //     maxLength={9}
                        //     value={post.amount}
                        //     min="1"
                        //     max="10"
                        //     required
                        //     disabled
                        //   />
                        // </div>
                        post.plan_prices.length > 1 &&
                          post.plan_type == "single" ? (
                          <div>
                            <Dropdown className="dropDetail">
                              <Dropdown.Toggle id="dropdown-basic">
                                $
                                {amount
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="priceDrop">
                                {post.plan_prices.map((pr) => {
                                  return (
                                    <>
                                      <Dropdown.Item
                                        onClick={(e) => gettingAmount(e, pr)}
                                      >
                                        $
                                        {pr.price
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </Dropdown.Item>
                                    </>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        ) : post.plan_prices.length > 1 &&
                          post.plan_type == "recurring" ? (
                          <div>
                            <Dropdown className="dropDetail">
                              <Dropdown.Toggle id="dropdown-basic">
                                $
                                {amount
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                                / {t("month")}
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="priceDrop">
                                {post.plan_prices.map((pr) => {
                                  return (
                                    <>
                                      <Dropdown.Item
                                        onClick={(e) => gettingAmount(e, pr)}
                                      >
                                        $
                                        {pr.price
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}{" "}
                                        / {t("month")}
                                      </Dropdown.Item>
                                    </>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        ) : (
                          <div style={{ position: "relative" }}>
                            <span
                              style={{
                                position: "absolute",
                                top: "7px",
                                left: "5%",
                                color: "white",
                              }}
                            >
                              $
                            </span>
                            <input
                              className="ipt-pack"
                              style={{
                                paddingLeft: "22px",
                                top: "7px",
                                left: "3%",
                                width: "270px",
                                color: "white",
                              }}
                              placeholder="1"
                              maxLength={9}
                              value={post.amount
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              min="1"
                              max="10"
                              required
                              disabled
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "-25%",
                                top: "7px",
                                color: "white",
                              }}
                              className="w-100"
                            >
                              {" "}
                              {t("amountPackage")} {post.duration} {t("months")}{" "}
                            </span>
                          </div>
                          // <div style={{ position: "relative" }}>
                          //   <span
                          //     style={{
                          //       position: "absolute",
                          //       top: "7px",
                          //       left: "5%",
                          //       color: "white",
                          //     }}
                          //   >
                          //     {" "}
                          //     ${" "}
                          //   </span>
                          //   <input
                          //     className="ipt-pack widthControl"
                          //     style={{
                          //       paddingLeft: "25px",
                          //       top: "7px",
                          //       left: "3%",
                          //       color: "#827564",
                          //     }}
                          //     placeholder="1"
                          //     maxLength={9}
                          //     value={post.amount}
                          //     type="number"
                          //     min="1"
                          //     max="10"
                          //     required
                          //     disabled
                          //   />
                          // </div>
                        )}
                        {/* <input
                            className="ipt-pack"
                            value={"$  " + post.amount * change}
                          /> */}
                        {/* </div> */}
                        {/* <div className="dropdown">
                          <button
                            className="btn dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            $ {change}
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => setChange(16000)}
                              >
                                $ 16000
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => setChange(16000)}
                              >
                                $ 16000
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => setChange(16000)}
                              >
                                $ 16000
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </li>
                      {post.is_quantity_enable == 0 ? (
                        ""
                      ) : (
                        <li className="li-pack mx-4">
                          <p style={{ color: "black" }}>{t("quantity")} </p>
                          <input
                            style={{ color: "#827564" }}
                            className="ipt-pack widthControl"
                            placeholder="1"
                            maxLength={9}
                            onChange={itemsEvent}
                            value={quantity}
                            type="number"
                            min="1"
                            max="10"
                            required
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        <div
          className={
            browserName == "Mobile Safari" || isIOS
              ? "footer packageBtn packagePd"
              : "footer packageBtn "
          }
          style={
            browserName == "Mobile Safari" || isIOS
              ? { marginTop: "-25px", marginBottom: "25%" }
              : { marginTop: "-25px" }
          }
        >
          {!location.type && !loader && packageList?.length ? (
            <button
              onClick={handleSubmit}
              className="btn btnMargin btn-main btn-pack sideBarResp"
              style={{ fontSize: "18.72px" }}
            >
              {packageList[0]?.plan_type == "recurring"
                ? t("BuySub")
                : t("BuyPackage")}
            </button>
          ) : (
            ""
          )}
        </div>
      </form>

      <GuestModal
        phoneNumber={phoneNumber}
        setShowPaidModal={setShowPaidModal}
        setPhoneNumber={setPhoneNumber}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        guestForm={guestForm}
        setGuestForm={setGuestForm}
        resetCloseModal={"payment"}
        Logincheck={Logincheck}
        setLogincheck={setLogincheck}
        switchForm={switchForm}
        setSwitchForm={setSwitchForm}
        packageName={packageList[0]?.plan_name}
        priceCheck={priceCheck > 1 ? false : duration}
        check
        Name={planName}
        PackageDetail={priceId}
        quantity={Number(quantity)}
        show={show}
        handleClose={handleModalClose}
      />

      <ConfirmModal
        text={t("buyPkg")}
        show={confirmModal}
        closeModal={closeModal}
        Call={subscriptionPackage}
      />
      <PayModal
        show={showPay}
        handleClose={handleModalPay}
        PackageDetail={priceId}
        dropBool={dropBool}
        quantity={Number(quantity)}
        check
        Name={planName}
        priceCheck={priceCheck > 1 ? false : duration}
        setShowPaidModal={setShowPaidModal}
        setPurchasedId={setPurchasedId}
      />
      {loader && <Loader />}
      <Congrats show={showPaidModal} handleClose={modalClose} />

      {packageList ? (
        <MetaInject
          description={extractTextFromHTML(packageList[0]?.description).split(
            "."
          )[0]}
          title={packageList[0]?.plan_name}
          url={window?.location?.href}
          imageUrl={
            "https://admin.openborders.io/public/" + packageList[0]?.image
          }
        />
      ) : null}
    </>
  );
};

export default Package;
