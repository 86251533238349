import React, { useState, useEffect } from "react";
import Navigation from "../navigation";
import { Facebook, Twitter } from "../../assets";
import "../../shared/styles/css/package.css";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { browserName, browserVersion, isMobile, isIOS } from "react-device-detect";

import {
  getPackageServices,
  subPaymentService,
  toastMessage,
  getServiceHistory,

} from "../../shared";
import { getAnalytics, logEvent } from "firebase/analytics";
import Loader from "../../shared/loader/loader";
import ConfirmModal from "../../shared/component/Modals/confirmationModal";
import { clientInnerWidth } from "../../shared/util/constant";
import GuestModal from "../../pages/schedule/GuestModel";
import PayModal from "../../shared/component/Modals/Pay";
import Congrats from "../../shared/component/Modals/Congrats";

const PackageDetail = () => {
  const {
    user: { user },
    lang: { lang },
  } = useSelector((state) => state.root);
  const location = useLocation();

  const { t, i18n } = useTranslation();
  const [clientWidth, setClientWidth] = useState(0);
  const [show, setShow] = useState(false);
  const [showPay, setShowPay] = useState(false);
  const [showPaidModal, setShowPaidModal] = useState(false);
  const modalClose = () => setShowPaidModal(false);
  const [amount, setAmount] = useState(
    location?.state?.plan_prices ? location?.state?.plan_prices[0]?.price : 0
  );
  const [packageList, setPackageList] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [priceId, setPriceId] = useState({});
  const [loader, setLoader] = useState(true);
  const [dropBool, setDropBool] = useState(false);
  const [planName, setPlanName] = useState("");
  const [purchasedId, setPurchasedId] = useState(false);
  const [priceCheck, setPriceCheck] = useState(0);
  const [duration, setDuration] = useState(0);
  const history = useHistory();
  const params = useParams();
  const closeModal = () => setConfirmModal(false);
  const PackagePlan = () => {
    if (/\s/g.test(params.id)) {
      history.replace({
        pathname: `/package-detail/${params.id.replace(/\s/g, "-").toLowerCase()}`,
      })
    }
    getPackageServices()
      .then(({ data: { data } }) => {
        setLoader(false);
        let tempID = data.filter((item) => item.slug == params.id.replace(/\s/g, "-"));
        setPriceCheck(tempID[0].plan_prices.length);
        setDuration(tempID[0].duration)
        if (
          tempID[0].plan_prices.length > 0 &&
          tempID[0].plan_prices.length == 1
        ) {
          setDropBool(false);
          setPriceId(tempID[0].plan_prices[0]);
        } else {
          setPriceId(tempID[0].plan_prices[0]);
          setDropBool(true);
        }

        setPlanName(tempID[0].plan_name)

        setPackageList(tempID);
        setAmount(data?.plan_prices[0]?.price);



        // if (tempID[0].plan_type == "recurring") {

        // } else {
        //   history.push({
        //     pathname: "/package",
        //     state: tempID[0],
        //   });
        // }
      })
      .catch((err) => {
        setLoader(false);
      });
  };


  useEffect(() => {
    if (planName) {
      const analytics = getAnalytics();
      logEvent(analytics, `Service Details-(${planName})`);
    }
  }, [planName])
  const handleModalClose = () => setShow(false);
  const handleModalPay = () => setShowPay(false);

  const gettingAmount = (e, pr) => {
    setPriceId(pr);
    setDropBool(true);
    setAmount(Number(e.target.innerText.replace(",", "").match(/\d+/)[0]));
    // let temp = Number(e.target.innerText) * quantity;
    // setTotal(temp)
  };

  useEffect(() => {
    PackagePlan();
  }, []);



  const subscriptionPackage = (e) => {
    // setShowPay(true)
    // setConfirmModal(false);
    // e.preventDefault();
    // if (!user?.is_guest) {
    //   if (user.is_complete_profile) {
    //     if (user.stripe_payment_method) {
    //       setLoader(true)
    //       let getState = location.state
    //       let obj = {
    //         stripe_payment_method: user.stripe_payment_method,
    //         plan_id: params.id
    //       }
    //       closeModal()
    //       subPaymentService(obj).then(({ data }) => {
    //         console.log('data', data);
    //         if (data.status) {
    //           toastMessage(t("packageSubscription"), "success")
    //         } else {
    //           toastMessage(t("planAlreadyBuy"), "error")
    //         }
    //         setLoader(false)
    //       }).catch(err => {
    //         setLoader(false)
    //         console.log(err);
    //       })
    //     } else {
    //       toastMessage(t("addPaymentDetail"), "error")
    //     }
    //   } else {
    //     toastMessage(t("CompleteProfileError"), "error")
    //   }
    // } else {
    //   history.push('/signIn')
    //   // toastMessage("You are Guest User, First Create Your Account", "error");
    // }
  };

  const handleSubmit = () => {
    if (purchasedId) {
      toastMessage(t("Already"), "error")
    } else {
      if (user?.is_guest == 1) {
        // setShow(true);
        history.push("/signin")
      } else {
        setShowPay(true);
      }
    }

  };
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(!sidebar);
    }
  };
  const hideSideBar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(false);
    }
  };

  useEffect(() => {
    setTimeout(function () {
      let link = document.getElementsByTagName('a');

      for (let i = 0; i < link.length; i++) {
        let temp = link[i].href;

        if (temp.includes("bordercrossinglaw.as.me")) {
          link[i].href = "https://openborders.io/schedule"
          link[i].target = "_blank"
        }

        if (temp.includes("/package-detail/") || temp.includes("/package/")) {
          let temp1 = temp.split("/");
        }
      }
    }, 1000);
  }, [location, loader]);

  const ServiceHisotry = () => {
    getServiceHistory()
      .then(({ data: { data } }) => {
        let temp1 = data?.purches_plan_history
        let tempID = temp1.filter((i) => i.plan.slug == params.id.replace(/\s/g, "-"));
        if (tempID.length) {
          setPurchasedId(true)
        }
        else {
          setPurchasedId(false);
        }


        let temp = [];
        data?.purches_plan_history?.forEach((i) => {
          temp.push(i.plan_id);
        })
      })
      .catch((err) => {
      });
  };
  useEffect(() => {
    ServiceHisotry();
  }, [packageList]);


  return (
    <>
      <Navigation
        label={t("package-detail")}
        width={setClientWidth}
        onPress={() => history.goBack()}
        sidebar={sidebar}
        showSidebar={showSidebar}
        setSidebar={setSidebar}
        check
      />
      <form
        className={`${clientWidth > clientInnerWidth.width && "margin300"}`}
        onSubmit={(e) => {
          e.preventDefault();
          // setConfirmModal(true)
        }}
        onClick={hideSideBar}
      >
        <div className="mains">
          <div className="cont-n">
            {packageList.map((post) => {
              return (
                <>
                  {
                    console.log("post", post)
                  }
                  <div className="imgBackDiv mb-4">
                    <img className="imgPackage" src={"https://admin.openborders.io/public" + post.image} />
                  </div>
                  <h1 className="news-h"> {lang == "sp" ? post.plan_name_es : post.plan_name}</h1>

                  <p className="date-price">
                    {post.plan_prices.length > 1 ? (
                      <span className="planPrice">
                        {t("From")} $
                        {amount
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                        {t("amountPackage1")}
                      </span>
                    ) : (
                      <span className="planPrice">
                        {" "}
                        $
                        {post.amount
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                        {t("amountPackage")} {post.duration} {t("months")}
                      </span>
                    )}
                  </p>

                  {lang == "en" ? <div
                    className="news-p"
                    dangerouslySetInnerHTML={{
                      __html: post.description,
                    }}
                  ></div> :
                    <div
                      className="news-p"
                      dangerouslySetInnerHTML={{
                        __html: post.description_es,
                      }}
                    ></div>}

                  <div className="pack-2"></div>

                  <div className="am-div">
                    <ul className="ul-pack" style={{ marginLeft: "-3%" }}>
                      <li
                        className="li-pack mx-4"
                        style={{ listStyle: "none" }}
                      >
                        <p style={{ color: "black" }}>{t("amount")} </p>
                        {post.plan_prices.length > 1 ? (
                          <Dropdown className="dropDetail">
                            <Dropdown.Toggle id="dropdown-basic">
                              $
                              {amount
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                              / {t("month")}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="priceDrop">
                              {post.plan_prices.map((pr) => {
                                return (
                                  <>
                                    <Dropdown.Item
                                      onClick={(e) => gettingAmount(e, pr)}
                                    >
                                      $
                                      {pr.price
                                        .toString()
                                        .replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}{" "}
                                      / {t("month")}
                                    </Dropdown.Item>
                                  </>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          <div style={{ position: "relative" }}>
                            <span
                              style={{
                                position: "absolute",
                                top: "7px",
                                left: "5%",
                                color: "white"
                              }}
                            >
                              $
                            </span>
                            <input
                              className="ipt-pack"
                              style={{
                                paddingLeft: "25px",
                                top: "7px",
                                left: "3%",
                                width: "270px",
                                color: "white"
                              }}
                              placeholder="1"
                              maxLength={9}
                              value={post.amount}
                              min="1"
                              max="10"
                              required
                              disabled
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "-25%",
                                top: "7px",
                                color: "white"
                              }}
                              className="w-100"
                            >
                              {" "}
                              {t("amountPackage")} {post.duration} {t("months")}{" "}
                            </span>
                          </div>
                        )}
                      </li>
                    </ul>
                  </div>
                </>
              );
            })}
            {/* <a target="_blank" href="https://facebook.com/OpenBorder">
              <img src={Facebook} />
            </a>
            <a target="_blank" href="https://twitter.com/OpenBorder">
              <img className="m-2" src={Twitter} />
            </a> */}
          </div>
          {!location?.type && !loader && packageList?.length ? (
            <div className="footer" style={(browserName == "Mobile Safari" || isIOS) ? { marginTop: "-25px", marginBottom: "25%" } : { marginTop: "-25px" }}>
              <button onClick={handleSubmit} className="btn btn-main btn-pack sideBarResp" style={{ fontSize: "18.72px" }}>
                {t("BuySub")}
              </button>
            </div>
          ) : ""}
        </div>

      </form>

      <GuestModal show={show} handleClose={handleModalClose} />

      <ConfirmModal
        text={t("buyPkg")}
        show={confirmModal}
        closeModal={closeModal}
        Call={subscriptionPackage}
      />
      <PayModal
        show={showPay}
        handleClose={handleModalPay}
        PackageDetail={priceId}
        dropBool={dropBool}
        check
        Name={planName}
        priceCheck={priceCheck > 1 ? false : duration}
        setShowPaidModal={setShowPaidModal}
        setPurchasedId={setPurchasedId}
      />
      {loader && <Loader />}
      <Congrats
        show={showPaidModal}
        handleClose={modalClose}
      />
    </>
  );
};

export default PackageDetail;
