import { NavLink } from "react-router-dom";
import React, { useState, useMemo, useEffect } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import "../../../shared/styles/css/auth.css";
import { Dropdown, Row, Col, Form } from "react-bootstrap";
import { Info, Dollar, Location, SideBarLogo2, Additional } from "../../../assets/index";
import { t } from "i18next";
// import { signup } from "../../../features/userSlice";
import { logout, signup } from "../../../shared/Redux/Reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import QuestModal from "../../../shared/component/Modals/QuestModal";
import CountryList from "country-list-with-dial-code-and-flag";
import { getAnalytics, logEvent } from "firebase/analytics";
import { browserName, browserVersion, isMobile, isIOS } from "react-device-detect";


import {
  SignupService,
  toastMessage,
  updateGuestUserService,
  getQuestionService,
} from "../../../shared";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../../shared/loader/loader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CryptoJS from "crypto-js";
import { decryption } from "../../../shared/util/constant";
const Signup = () => {
  const { t, i18n } = useTranslation();
  const { user } = useSelector((state) => state.root);
  const history = useHistory();
  const location = useLocation();
  const [fname, setFname] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setcPassword] = useState("");
  const [phone, setPhone] = useState(null);
  const [street, setStreet] = useState("");
  const [emailForUS, setEmailForUS] = useState("");
  const [fNameUS, setFNameUS] = useState("");
  const [lNameUS, setLNameUS] = useState("");
  const [country, setCountry] = useState({
    value: "US",
    label: "United States",
  });
  const [countryCode, setCountryCode] = useState("+1");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [check, setCheck] = useState(false);
  const [checkForModal, setCheckforModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [disclaimer, setDesclaimer] = useState([]);
  const [question, setQuestion] = useState([]);
  const dispatch = useDispatch();
  const [countryFilter, setCountryFilter] = useState([]);

  const SelectCountry = (e) => {
    let temp = CountryList.filter(
      (country) => country.name + " " + country.flag == e.target.innerText
    );
    temp.unshift();
    setCountryCode(temp[0].dial_code);
  };

  // {
  //   code: "US",
  //   dial_code: "+1",
  //   flag: "🇺🇸",
  //   name: "United States",
  //   preferred: true
  // }

  const options = useMemo(() => countryList().getData(), []);
  const changeHandler = (value) => {
    setCountry(value);
  };
  const closeModal = () => setShowModal(!showModal);
  const getQuestion = () => {
    getQuestionService()
      .then(({ data: { data } }) => {
        let bytes = CryptoJS.AES.decrypt(data, decryption.key);
        let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        let temp = decryptedData.questions.filter(
          (newItem) => newItem.id === "08d6214d-638d-4e35-aee4-2c015ebda981"
        );
        setDesclaimer(temp);
      })
      .catch((err) => { })
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    getQuestion();
  }, []);

  const formatPhoneNumber = (phone) => {
    setPhone(phone.replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    
    if (user?.user?.is_guest) {
      let obj = {
        user_id: user?.user?.id,
        email: email,
        password: password,
        password_confirmation: cpassword,
        first_name: fname,
        last_name: lname,
        zip_code: zip,
        city: city,
        state: state,
        country: country.label,
        street_address: street,
        phone_no: countryCode + phone.split("-").join(""),
      }

      if (emailForUS.length > 0 && fNameUS.length > 0 && lNameUS.length > 0) {
        obj['petitioner_email'] = emailForUS;
        obj['petitioner_firstname'] = fNameUS;
        obj['petitioner_lastname'] = lNameUS;
        obj['is_us_citizen'] = 1
      }


      updateGuestUserService(obj)
        .then(({ data }) => {
          setLoader(false);
          if(data.status){
          setEmail("");
          setPassword("");
          setcPassword("");
          data["email"] = email;
          data["password"] = password;
          data["first_name"] = fname;
          data["last_name"] = lname;
          data["phone_no"] = countryCode + phone.split("-").join("");
          data["street_address"] = street;
          data["country"] = country;
          data["state"] = state;
          data["city"] = city;
          data["zip_code"] = zip;
          dispatch(signup(data.data));
          history.push({
            pathname: "/home",
          });
          }else{
            toastMessage(data?.message)
          }
          
        })
        .catch((err) => {
          setLoader(false);
          toastMessage(err?.response?.data?.message);
        });
    } else {
      let obj = {
        email: email,
        password: password,
        password_confirmation: cpassword,
        first_name: fname,
        last_name: lname,
        phone_no: countryCode + phone.split("-").join(""),
        street_address: street,
        country: country.label,
        state: state,
        city: city,
        zip_code: zip
      }

      if (emailForUS.length > 0 && fNameUS.length > 0 && lNameUS.length > 0) {
        obj['petitioner_email'] = emailForUS;
        obj['petitioner_firstname'] = fNameUS;
        obj['petitioner_lastname'] = lNameUS;
        obj['is_us_citizen'] = 1
      }

      SignupService(obj)
        .then(({ data }) => {
          setLoader(false);
          if(data.status){
            setEmail("");
            setPassword("");
            setcPassword("");
  
            data["email"] = email;
            data["password"] = password;
  
            dispatch(signup(data.data));
            history.push({
              pathname: "/home",
              
            });
          }else{
            toastMessage(data?.message)
          }
          
        })
        .catch((err) => {
          setLoader(false);
          toastMessage(err?.response?.data?.message);
        });
    }
  };

  const handleModal = () => {
    setShowModal(true);
  };

  const filterCountryList = (e) => {
    let countryCodeArr = [];
    let temp = CountryList.map((i) => {
      if (i.name.toLowerCase().includes(e.target.value.toLowerCase())) {
        countryCodeArr.push(i);
      }
    });
    setCountryFilter(countryCodeArr);
  };

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, "Sign Up");
  }, []);

  const handleCheck = () => {
    handleModal();
    setCheckforModal(false);
  }

  return (
    <div className="mains">
      <img src={SideBarLogo2} className="signupLogo" />

      <h1 className="heading">{t("SignUpCreate")}</h1>
      <p className="paragraph">{t("signup-info")}</p>

      <div className="container auth-container">
        <ul className="acc-after1">
          <li>
            <img src={Info} />
          </li>
          <li>
            <p className="p-acc">{t("information")}</p>
          </li>
        </ul>
        <form className="signin-form" onSubmit={(e) => handleSubmit(e)} >
          <label>{t("fname")}</label>
          <input
            value={fname}
            className="signin-input"
            type="text"
            placeholder=""
            required
            id="fname"
            onChange={(e) => setFname(e.target.value)}
          />
          <label>{t("lname")}</label>
          <input
            value={lname}
            className="signin-input"
            type="text"
            placeholder=""
            id="lname"
            required
            onChange={(e) => setLname(e.target.value)}
          />
          <label>Email</label>
          <input
            value={email}
            className="signin-input"
            type="email"
            placeholder=""
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>{t("password")}</label>
          <input
            value={password}
            className="signin-input"
            type="password"
            required
            onChange={(e) => setPassword(e.target.value)}
          />

          <label>{t("confirm-password")}</label>
          <input
            className="signin-input"
            value={cpassword}
            type="password"
            onChange={(e) => setcPassword(e.target.value)}
            required
          />

          <Row>
            {/* <Col sm={3} style={{ width: "auto" }} >
              <label>Code</label>

            </Col> */}
            <Col>
              <label>{t("phoneNo")}</label>
            </Col>
          </Row>
          <Row>
            <Col sm={3} style={{ paddingRight: "0px", width: "auto", height: "60px" }}>
              <Dropdown>
                <Dropdown.Toggle
                  className="phoneCode"
                  variant="success"
                  id="dropdown-basic"
                  style={{ height: "53px" }}
                >
                  {countryCode}
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropCode">
                  <input
                    className="countryList"
                    placeholder="Search Country..."
                    onChange={(e) => filterCountryList(e)}
                  />
                  {countryFilter.length > 0
                    ? countryFilter.map((country) => {
                      return (
                        <>
                          <Dropdown.Item
                            href="#"
                            onClick={(e) => SelectCountry(e)}
                          >
                            {country.name + " " + country.flag}
                          </Dropdown.Item>
                        </>
                      );
                    })
                    : CountryList.map((country) => {
                      return (
                        <>
                          <Dropdown.Item
                            href="#"
                            onClick={(e) => SelectCountry(e)}
                          >
                            {country.name + " " + country.flag}
                          </Dropdown.Item>
                        </>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col className="colPhone" style={{ paddingLeft: "0px", marginLeft: "10px" }}>
              <input
                className="signin-input w-100"
                onChange={(e) => formatPhoneNumber(e.target.value)}
                type="phone"
                inputmode="numeric"
                // pattern="[0-9\s]{13,19}"
                style={{ height: "43px" }}
                autocomplete="cc-number"
                maxLength="12"
                id="PhoneNum"
                autocomplete="off"
                value={phone}
                required
              />
            </Col>
          </Row>
          {/* <div>
            <PhoneInput
              country={"us"}
              value={phone}
              onChange={(phone) => setPhone(phone)}
            />
          </div> */}

          <ul className="acc-after1">
            <li>
              <img src={Location} />
            </li>
            <li>
              <p className="p-acc">{t("address")}</p>
            </li>
          </ul>
          <label>{t("street")}</label>
          <input
            className="signin-input"
            onChange={(e) => setStreet(e.target.value)}
            type="text"
            id="Street"
            placeholder=""
            value={street}
            required
          />
          <label for="city">{t("city")}</label>
          <input
            onChange={(e) => setCity(e.target.value)}
            type="text"
            className="signin-input"
            id="city"
            placeholder=""
            required
            value={city}
          />

          <label for="state">{t("state")}</label>
          <input
            onChange={(e) => setState(e.target.value)}
            type="text"
            className="signin-input"
            id="state"
            placeholder=""
            value={state}
            required
          />
          <label for="postal">{t("zip")}</label>
          <input
            onChange={(e) => setZip(e.target.value)}
            type="number"
            className="signin-input"
            id="postal"
            placeholder=""
            value={zip}
            required
          />
          <label for="country">{t("country")}</label>
          {/* <input
            onChange={(e) => setCountry(e.target.value)}
            type="text"
            className="signin-input"
            id="country"
            value={country}
            placeholder=""
            required
          /> */}
          <Select
            options={options}
            value={country}
            onChange={changeHandler}
            className="country-input"
          />

          {/* {check ? <label className="forget-pwd">
            <input
              type="checkbox"
              required
              value={check}
              onChange={(e) => setCheck(!check)}
            />{" "}
            {t("ReviewText")}
            <br />
            <span style={{ cursor: "pointer" }} onClick={() => handleModal()}>
              {" "}
              {t("ClickReview")}
            </span>
          </label> :
            <label className="forget-pwd"
              onClick={() => handleModal()}
              style={{ cursor: "pointer" }}>
              {t("ConditionalReviewText")}
            </label>
          } */}
          <ul className="acc-after1">
            <li>
            👨‍👩‍👧‍👦
              {/* <img src={Additional} style={{ height: "35px" }} /> */}
            </li>
            <li>
              <p className="p-acc">{t("InformationForUS")}</p>
            </li>
          </ul>
          {/* <label for="postal" className="mt-2" style={{ opacity: "1" }}>{t("InformationForUS")}</label> */}
          <label for="postal" className="mt-2">{t("InformationStatement")}</label>

          <label for="postal" className="mt-4">{t("fname")}</label>
          <input
            onChange={(e) => setFNameUS(e.target.value)}
            type="text"
            className="signin-input"
            id="usFName"
            placeholder=""
            value={fNameUS}
          />
          <label for="postal">{t("lname")}</label>
          <input
            onChange={(e) => setLNameUS(e.target.value)}
            type="text"
            className="signin-input"
            id="usLName"
            placeholder=""
            value={lNameUS}
          />


          <label for="emailForUs">{t("EmailForUs")}
          {/* <b className="signUpPertitionEmailBold">{t("EmailForUs").slice(5,110)}</b> */}
          </label>
          <input
            onChange={(e) => setEmailForUS(e.target.value)}
            type="email"
            className="signin-input"
            id="emailForUs"
            name="emailForUs"
            placeholder=""
            value={emailForUS}
          />

          {check ? (
            <Form style={{ height: "35px" }}>
              {["checkbox"].map((type) => (
                <div key={`default-${type}`} className="mb-3">
                  <Form.Check
                    type={type}
                    id={`default-${type}`}
                    className="labelForm w-100"
                    style={{ textDecoration: "underline" }}
                    label={t("ConditionalReviewText")}
                    defaultChecked={check}
                    onChange={(e) => setCheck(!check)}
                    required
                  />
                </div>
              ))}
            </Form>
          ) : (
            <div style={{ display: "flex", height: "35px" }}>
              <Form style={{ height: "20px", marginRight: "6px" }}>
                {["checkbox"].map((type) => (
                  <div key={`default-${type}`} className="mb-3">
                    <Form.Check
                      type={type}
                      id={`default-${type}`}
                      className="labelForm1 w-100"
                      style={{ textDecoration: "underline" }}
                      // label={t("ConditionalReviewText")}
                      defaultChecked={checkForModal}
                      onChange={() => handleCheck()}
                      checked={checkForModal}
                      required
                    />
                  </div>
                ))}
              </Form>
              <label
                className="forget-pwd"
                onClick={() => handleModal()}
                style={{
                  cursor: "pointer",
                  height: "20px",
                  textDecoration: "underline",
                }}
              >
                {t("ConditionalReviewText")}
              </label>
            </div>
          )}

          {/* <Form>
            {["checkbox"].map((type) => (
              <div key={`default-${type}`} className="mb-3">
                <Form.Check
                  type={type}
                  id={`default-${type}`}
                  className=""
                  label={t("ReviewText")}
                  onChange={(e) => setCheck(!check)}
                />
              </div>
            ))}
          </Form> */}

          <button className="btn btn-auth" disabled={!check}>
            {t("signup")}
          </button>

          <p className="paragraph w-100" style={(browserName == "Mobile Safari" || isIOS) ? {marginBottom: "50%"} : {}}>
            {t("already-account")} &nbsp;
            <span
              className="links"
              onClick={() => {
                // dispatch(logout())
                history.push("/signin");
              }}
              style={{ fontWeight: "bold" }}
            >
              {t("signIn")}
            </span>
          </p>
        </form>
      </div>
      {loader && <Loader />}
      <QuestModal
        show={showModal}
        disclaimer={disclaimer[0]}
        closeModal={closeModal}
        check
        setCheck={setCheck}
        setCheckforModal={setCheckforModal}
      />
    </div>
  );
};

export default Signup;
