import React, { useState, useEffect } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { Report, CloseSign, ReportQuestion, ConsultationUnSelected } from "../../../assets/index";
import { useTranslation } from "react-i18next";
import { toastMessage } from "../../../shared";
import { reportBugService } from "../../../shared/index";
import { GoMail } from "react-icons/go";
import { FiPhone } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { browserName, browserVersion, isMobile, isIOS } from "react-device-detect";


function ReportModal({ show, handleClose, path, allQuestion, currentNode }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [description, setDescription] = useState("");
  const [loader, setLoader] = useState(false);
  const [checkforChild, setCheckforChild] = useState(false)



  const evaluateCondition = (firstCondition, operation, thirdCondition) => {
    if (operation === "AND") {
      if (firstCondition && thirdCondition) {
        return true;
      } else {
        return false;
      }
    } else if (operation === "OR") {
      if (firstCondition || thirdCondition) {
        return true;
      } else {
        return false;
      }
    } else if (operation === "AND NOT") {
      if (firstCondition && !thirdCondition) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const evaluateAnd = (newItem) => {
    let tempAND = newItem.split("&&");
    for (const item of path) {
      if (item.nodeId === tempAND[0] && item.optionId === tempAND[1]) {
        return true;
      }
    }
    return false;
  };

  const evaluateWithoutAnd = (newItem) => {
    for (const item of path) {
      if (item.nodeId === newItem) {
        return true;
      }
    }
    return false;
  };

  const solveFinalCondition = (result) => {
    let firstCondition = result[0];
    let operation = result[1];
    let thirdCondition = result[2];
    return evaluateCondition(firstCondition, operation, thirdCondition);
  };

  const findNodeInPath = (newItem) => {
    if (newItem.includes("||")) {
      let tempOR = newItem.split("||");
      let check = false;
      tempOR.forEach((newItem) => {
        if (newItem.includes("&&")) {
          check = check || evaluateAnd(newItem);
        } else {
          check = check || evaluateWithoutAnd(newItem);
        }
      });
      return check;
    } else if (newItem.includes("&&")) {
      return evaluateAnd(newItem);
    } else {
      return evaluateWithoutAnd(newItem);
    }
  };




  const handleSubmit = () => {
    if (description.length == 0) {
      toastMessage("Please enter something in description", "error")
    }
    else {
      let Labels = [];
      for (let i = 0; i < path.length; i++) {
        let temp = allQuestion.find((ii) => ii.id == path[i].nodeId)
        let bool = false;
        if (temp?.data?.conditions) {
          temp?.data?.conditions.map((ii) => {
            let condition = ii.condition.split(">");
            var result = [];
            var flag = false;
            condition.forEach((newItem, newIndex) => {
              if (newItem === "AND" || newItem === "OR" || newItem === "AND NOT") {
                result.push(newItem);
              } else {
                result.push(findNodeInPath(newItem));
              }
            });
            if (result.length === 3) {
              flag = solveFinalCondition(result);
            } else if (result.length > 3) {
              let temp = [];
              result.forEach((item, index) => {
                if (temp.length === 3) {
                  temp = [solveFinalCondition(temp)];
                  temp.push(item);
                } else {
                  temp.push(item);
                }
              });
              flag = solveFinalCondition(temp);
            } else {
              flag = result[0];
            }

            bool = bool || flag;

            if (flag) {
              let temp1 = ii.conditionOptions.find((x) => x.id == path[i].optionId);
              if (temp1) {
                if (!Labels.includes(temp1.text)) {
                  Labels.push(temp1.text)
                }
              }
            }

          }
          )
        }
        if (!bool) {
          let temp2 = temp.data.child.find((ii) => ii.id == path[i].optionId);
          if (temp2) {
            if (!Labels.includes(temp2.text)) {
              Labels.push(temp2.text);
            }
          }
        }
      }
      // Labels.push(currentNode.data.label)

      let obj = {
        description: description,
        node_url: JSON.stringify(Labels),
        current_node: currentNode.data.label
      }

      setLoader(true);
      reportBugService(obj)
        .then(({ data }) => {
          setLoader(false);
          setDescription("");
          toastMessage(data.message, "success")
          handleClose();
        })
        .catch((err) => {

        });
    }
  }

  const handleCross = () => {
    setDescription("");
    handleClose();
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} centered backdrop="static" className='modalReport'>
        <Modal.Header style={{ borderBottom: "none" }}>
          <Modal.Title className="reportTitle" >
            <div>
              <h3 style={{ fontSize: "22px" }}>{t("ContactUs")}</h3>
              <p className="reportPara reportLang">ℹ️ {t("ReportModalText")}</p>
              <button className="reportDivBtn" onClick={() => history.push("/schedule")}><ConsultationUnSelected style={{ height: "25px" }} className="consultantIcon" />{t("consultant")}</button>
              <button className="reportDivBtn" onClick={() => window.open('mailto:Help@bordercrossinglaw.com', "_self")}><GoMail style={{ height: "25px", width: "21px" }} className="mx-1" size={70} /><span style={{ paddingLeft: "5px" }} className="reportLang">Help@bordercrossinglaw.com</span></button>
              <button className="reportDivBtn" onClick={() => window.open('tel:+1(888)595-2004', "_self")}><FiPhone style={{ height: "25px", width: "21px" }} className="mx-1" size={70} /> +1 (888) 595-2004</button>
            </div>

            <div className="reportDiv">
              <ReportQuestion style={{ marginRight: "7px" }} />
              <span className="reportSpan" style={isIOS ? { lineHeight: "18px" } : {}}>{t("ReportText")}</span>
            </div>
          </Modal.Title>
          <img onClick={() => handleCross()} src={CloseSign} style={{
            position: "absolute",
            cursor: "pointer",
            right: "15px",
            top: "15px"
          }} />
        </Modal.Header>
        <Modal.Body>
          <textarea onChange={(e) => setDescription(e.target.value)} id="w3review" name="w3review" rows="4" cols="50" className="reportTextArea" placeholder="Write details here">
          </textarea>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: " none", paddingBottom: "20px" }}>
          <Button className="btnReport mb-5" onClick={() => handleSubmit()}>
            {loader ?
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              :
              "Send"
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ReportModal;


