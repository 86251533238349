export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL_Backend = process.env.REACT_APP_Laravel_BASE_URL;
// export const BASE_URL_Backend = "http://178.128.29.7/open-border-web/api";
// export const BASE_URL_Backend = "https://devcp.codingpixels.us/open-border-web/api";

export const AucityUserId = process.env.REACT_APP_User_Id;
export const AucityApiKey = process.env.REACT_APP_API_KEY;
export const clientInnerWidth = {
  width: 768,
};
export const AllRoute = [
  "/payment",

  ,
  "/news-detail",

  ,
  "/language",

  ,
  "/home",

  ,
  "/account",

  ,
  "/question",

  ,
  "/history",

  ,
  "/news",

  ,
  "/schedule",

  ,
  "/package/:id",

  ,
  "/services",

  ,
  "/package-detail/:id",

  ,
  "/package"

  ,
  "/package-detail"

  ,
  "/news-detail"

];
export const paymentRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
  allowedPaymentMethods: [
    {
      type: "CARD",
      parameters: {
        allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
        allowedCardNetworks: ["MASTERCARD", "VISA"],
      },
      tokenizationSpecification: {
        type: "PAYMENT_GATEWAY",
        parameters: {
          gateway: "stripe",
          "stripe:version": "v3",
          "stripe:publishableKey": "pk_live_qnJs6x0H3LdgCPIOsSIoIVjw",
          // "stripe:publishableKey": "pk_test_M4kc0ugwOFFrcPtUyxdpgKMG",
        },
      },
    },
  ],
  merchantInfo: {
    merchantId: "BCR2DN4TQCV3XAC6",
    merchantName: "Border Crossing Law Firm",
  },
  transactionInfo: {
    totalPriceStatus: "FINAL",
    totalPriceLabel: "Total",
    totalPrice: "1000.00",
    currencyCode: "USD",
    countryCode: "US",
  },
  callbackIntents: ["PAYMENT_AUTHORIZATION"],
  emailRequired: true,
};
export const language = {
  eng: "en",
  spanish: "sp",
};
export const decryption = {
  key: "open_border_8824714592",
};
