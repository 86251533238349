import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
const stripePromise = loadStripe(
    "pk_test_M4kc0ugwOFFrcPtUyxdpgKMG"
  );
const PaymentModal = ({ show, handleClose, setstripeToken,stripe,elements }) => {
    const { t, i18n } = useTranslation();
    const handleSubmit = async () => {
    
     // Block native form submission.
        
  
     if (!stripe || !elements) {
       // Stripe.js has not loaded yet. Make sure to disable
       // form submission until Stripe.js has loaded.
       return;
     }
    
     const cardElement = elements.getElement(CardElement);
    
     const { error, paymentMethod } = await stripe.createPaymentMethod({
       type: "card",
       card: cardElement,
     });
    
    
     if (error) {
       console.log("[error]", error);
     } else {
      const {token} = await stripe.createToken(cardElement);
      
      setstripeToken(token.id)
      handleClose()
       console.log("[PaymentMethod]", paymentMethod);
     
     }
    };
  return (
    
      <Modal show={show} onHide={handleClose}>
          
          <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{textAlign:"center",height:"294px"}}>
          <p>Consultation Charges 300$</p>
          <div style={{paddingTop:"53px"}}>
          <CardElement
                style={{ marginTop: "100px" }}
                className="acc-payment"
                options={{
                  style: {
                    base: {
                      marginTop: "10px",
                      fontSize: "16px",
                      color: "black",
                      "::placeholder": {
                        color: "#808080",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
              />
              <button
              className="btn btn-schu btn-modal"
              style={{width:"100%"}}
              onClick={handleSubmit}
            >
                
              {t("continue")}
            </button>
          </div>
          
        </Modal.Body>
          
       
      </Modal>

  );
};
const InjectedCheckoutForm = ({ show, handleClose, setstripeToken }) => {
    return (
      <Elements stripe={stripePromise}>
        <ElementsConsumer>
          {({ elements, stripe }) => (
            <PaymentModal show={show} handleClose={handleClose} setstripeToken={setstripeToken} elements={elements} stripe={stripe} />
          )}
        </ElementsConsumer>
      </Elements>
    );
  };
  
export default InjectedCheckoutForm;
