import React, { useState } from 'react';
import { Button, Modal, Container } from 'react-bootstrap';
import { CongratsIcon } from "../../../assets/index";
import { useTranslation } from "react-i18next";



function Congrats({ show, handleClose }) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Modal className="ModalPaid" show={show} onHide={handleClose}>
        <Modal.Header style={{ borderBottom: "none" }} >
          <Modal.Title style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: "1rem" }}>
            <CongratsIcon />
            <span className="spanModal mt-2">
              {t("retaining")}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "10px" }}>
          <Container>
            <Button className='w-100 PaidModalBtn spanModal' style={{ cursor: "initial" }}> 📨 {t("fewMins")}</Button>
            <Button className='w-100 mt-2 PaidModalBtn spanModal' style={{ cursor: "initial" }}> ☀️ {t("LookForward")}</Button>
            <Button className='w-100 mt-2 PaidModalBtn spanModal' onClick={() => window.open('mailto:attorney@bordercrossinglaw.com', "_self")}> 💬 {t("EmailAttorney")}</Button>
          </Container>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Congrats;