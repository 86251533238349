import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Form } from "react-bootstrap";
import { SidebarPng, SideBarLogo, CrossIcon, popLogo } from "../../../assets";
import "../../styles/css/quest.css";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getAnalytics, logEvent } from "firebase/analytics";


const QuestModal = ({ text, show, closeModal, Call, disclaimer, check, setCheck, setCheckforModal }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const {
    lang: { lang },
    user: { user },
  } = useSelector((state) => state.root);

  const routinModal = () => {
    if (check) {
      closeModal();
    }
    else {
      history.push("/question")
    }
    setCheck(true);
  }


  useEffect(() => {
    if (show) {
      const analytics = getAnalytics();
      logEvent(analytics, "Terms and Conditions Pop-up");
    }
  }, [show])

  const handleCheck = () => {
    if (setCheckforModal) {
      setCheckforModal(false);
      closeModal();
    }
    else {
      closeModal();
    }
  }

  return (
    <div>
      <Modal centered show={show} backdrop="static" scrollable={true}>
        <Modal.Header
          style={{ borderBottom: "none" }}
        >
          <CrossIcon onClick={() => handleCheck()} style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }} />
        </Modal.Header>
        <Modal.Body>
          <div className="mr-body">
            <div>
              {/* <img src={popLogo} width="200px" className="headerSpac" /> */}

              <div>
                {disclaimer ?
                  <p
                    className="desclaim"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang == "sp"
                          ? disclaimer?.data?.description_ES?.replace(
                            /&lt;/gi,
                            "<"
                          )
                          : disclaimer?.data?.description?.replace(/&lt;/gi, "<"),
                    }}
                  ></p>
                  :
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                }
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="desclaimFoot">
          {/* <div className="agree">
            <input type="checkbox" className="checkDesclaim" onClick={() => history.push("/question")} id="agrees" name="agrees" />
            <label className="agreeLabel" htmlFor="agrees">
              I agree with the Legal Disclaimers and Terms of Use
            </label>
          </div> */}
          <Form>
            {["checkbox"].map((type) => (
              <div key={`default-${type}`} className="mb-3">
                <Form.Check
                  type={type}
                  id={`default-${type}`}
                  className="desclaimCheck"
                  label={t("ReviewText")}
                  onClick={() => routinModal()}
                />
              </div>
            ))}
          </Form>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default QuestModal;
