import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { ApplePay, GooglePay } from "../../../assets/index";
import { useTranslation } from "react-i18next";
import GooglePayButton from "@google-pay/button-react";
import { useSelector } from "react-redux";
import moment from "moment";
import { bookConsultationService, toastMessage } from "../../../shared";
import Loader from "../../../shared/loader/loader";
import { subPaymentService } from "../../../shared";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { hasLoadedNamespace } from "i18next";
import { getAnalytics, logEvent } from "firebase/analytics";
import CreditCardInput from "react-credit-card-input";
import { HiCreditCard } from "react-icons/hi";
import { FiUser } from "react-icons/fi";
import { paymentCardService } from "../../../shared/services/paymentPackage";
import { getUserDetails, getProfileService } from "../../../shared/services/account";
import { useLocation } from "react-router-dom";




function Pay({
  show,
  handleClose,
  description,
  calendarName,
  dateobj,
  state,
  check,
  dropBool,
  PackageDetail,
  quantity,
  timeZoneArea,
  Name,
  priceCheck,
  setShowPaidModal,
  setPurchasedId,
}) {
  const {
    lang: { lang },
    user: { user },
  } = useSelector((state) => state.root);

  const location = useLocation();


  const { t, i18n } = useTranslation();
  const [planName, setPlanName] = useState("");
  const [planSecret, setPlanSecret] = useState("");
  const [bookingStatus, setBookingStatus] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cvc, setCVC] = useState("");
  const [expiry, setExpiry] = useState("");
  const [errorCard, setErrorCard] = useState("");
  const [errorCVC, setErrorCVC] = useState("");
  const [errorExpiry, setErrorExpiry] = useState("");
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [cardToken, setCardToken] = useState("");
  const [cardBool, setCardBool] = useState(false);
  const [lastFour, setLastFour] = useState("");



  const options = {
    // clientSecret: '{{CLIENT_SECRET}}',
  };

  //pk_test_51JzZuXHlJ57SdeJaeSzuMD7oHsPQa05OesccvVzDKFDsjpVVQWyQIT1EyrI4FfAQNWZNzWfkhc6cuiz5kk6NVqhP00EnouzOCE

  //pk_live_qnJs6x0H3LdgCPIOsSIoIVjw

  //pk_live_51JNtd5EEWahky04aHMAYMeda92xC0T3tGlRv1pxAlzMxVq9Dx26qRcGZoWg4UNlVBS2QH7P6iv4H5Nsz81lG1lpx00OsBH3ThR

  // const [token, setToken] = useState("");

  const history = useHistory();

  const [isSupported, setIsSupported] = useState(false);

  function beginApplePay() {
    try {
      var session = null;
      if (check) {
        var paymentRequest = {
          countryCode: "US",
          currencyCode: "USD",
          // lineItems: [
          //   {
          //     type: "final",
          //     label: "Payin",
          //     amount: "15.00",
          //   }
          // ],
          // merchantInfo: {
          //   merchantId: "merchant.com.cp.OpenBorders",
          //   merchantName: "Open Borders",
          // },

          total: {
            label: !priceCheck
              ? `${Name} for $${PackageDetail.price}`
              : priceCheck == 1
                ? `${Name} , $${PackageDetail.price} for every month`
                : `${Name}, $${PackageDetail.price} for every month for ${priceCheck} months `,
            amount: PackageDetail.price,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        };
        session = window.Stripe.applePay.buildSession(
          paymentRequest,
          function (result, completion) {
            ConsultationPay(result.token.id);
            completion(true);
          }
        );
      } else {
        var paymentRequest2 = {
          countryCode: "US",
          currencyCode: "USD",

          // lineItems: [
          //   {
          //     type: "final",
          //     label: "Payin",
          //     amount: "15.00",
          //   }
          // ],

          total: {
            label: "Border Crossing Law Firm for Consultation",
            amount: 300,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        };

        session = window.Stripe.applePay.buildSession(
          paymentRequest2,
          function (result, completion) {
            ConsultationPay(result.token.id);
            completion(true);
          }
        );
      }
      console.log("session", session);
      session.begin();
    } catch (err) {
      toastMessage("Apple Pay is not supported in this browser", "error");
    }
  }

  useEffect(() => {
    window.Stripe.setPublishableKey("pk_live_qnJs6x0H3LdgCPIOsSIoIVjw");

    window.Stripe.applePay.checkAvailability(function (available) {
      console.log("available", available);
      if (available) {
        setIsSupported(true);
        console.log("Supported");
      } else {
        setIsSupported(true);
        console.log("Not Supported");
      }
    });
  }, []);

  const paymentRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: "CARD",
        parameters: {
          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
          allowedCardNetworks: ["MASTERCARD", "VISA"],
        },
        tokenizationSpecification: {
          type: "PAYMENT_GATEWAY",
          parameters: {
            gateway: "stripe",
            "stripe:version": "v3",
            "stripe:publishableKey": "pk_live_qnJs6x0H3LdgCPIOsSIoIVjw",
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: "BCR2DN4TQCV3XAC6",
      merchantName: "Border Crossing Law Firm",
    },
    transactionInfo: {
      totalPriceStatus: "FINAL",
      totalPriceLabel: "Total",
      totalPrice: "1000.00",
      currencyCode: "USD",
      countryCode: "US",
    },
    callbackIntents: ["PAYMENT_AUTHORIZATION"],
    emailRequired: true,
  };


  if (!check) {
    paymentRequest["transactionInfo"] = {
      totalPriceStatus: "FINAL",
      totalPriceLabel: "Total",
      totalPrice: "300.00",
      currencyCode: "USD",
      countryCode: "US",
    };
  } else {
    if (quantity == 1 && Object.keys(PackageDetail).length) {
      paymentRequest["transactionInfo"] = {
        totalPriceStatus: "FINAL",
        totalPriceLabel: "Total",
        totalPrice: PackageDetail.price.toString(),
        currencyCode: "USD",
        countryCode: "US",
      };
    } else {
      if (Object.keys(PackageDetail).length) {
        paymentRequest["transactionInfo"]['totalPrice'] = (
          PackageDetail.price * quantity
        ).toString();
      }
    }
  }




  const ConsultationPay = (token, lastDigits, paymentCard) => {
    if (check) {
      let resp = {
        stripe_payment_method: token,
        plan_id: PackageDetail.plan_id,
        price_id: PackageDetail.id,
        is_web: 1,
        card_holder_name: name,
        expire_date: expiry.split(" ").join(""),
        pm_last_four: lastFour.length > 0 ? Number(lastFour) : Number(lastDigits),
        is_card_save: cardBool ? 1 : 0,
        is_other_payment_gateway: paymentCard ? 0 : 1
      };

      // if (lastFour.length > 0) {
      //   resp['pm_last_4'] = Number(lastFour);
      // }

      // if (lastDigits) {
      //   resp['pm_last_4'] = Number(lastDigits);
      // }


      if (quantity) {
        resp["quantity"] = Number(quantity);
      } else {
        resp["quantity"] = 1;
      }

      // if (cardBool) {
      //   resp["is_card_save"] = true;
      // } else {
      //   resp['is_card_save'] = false
      // }

      // if (paymentCard && cardBool) {
      //   resp['is_other_payment_gateway'] = 0
      // } else {
      //   resp['is_other_payment_gateway'] = 1
      // }

      // if (paymentCard && !cardBool) {
      //   resp['is_other_payment_gateway'] = false
      //   resp["is_card_save"] = 0;
      // } else {
      //   resp['is_other_payment_gateway'] = true
      // }

      // let resp = {
      //   stripe_payment_method: "tok_1MhXwZHlJ57SdeJatO7WkCH7",
      //   plan_id: 26,
      //   price_id: 14,
      //   quantity: 1,
      //   is_web: 1,
      //   amount: 500,
      //   is_card_save: 0,
      //   is_other_payment_gateway: 0,
      //   card_holder_name: "this tesr",
      //   expire_date: "12/25",
      //   pm_last_4: 4242
      // }

      // console.log({
      //   stripe_payment_method: typeof token,
      //   plan_id: typeof PackageDetail.plan_id,
      //   price_id: typeof PackageDetail.id,
      //   quantity: typeof Number(quantity),
      //   is_web: typeof 1,
      //   amount: typeof 500,
      //   is_card_save: typeof 0,
      //   is_other_payment_gateway: typeof 0,
      //   card_holder_name: typeof name,
      //   expire_date: typeof expiry.split(" ").join(""),
      //   pm_last_4: typeof Number(lastFour),
      //   pm_last_4: typeof Number(lastDigits)
      // })



      setLoader(true);
      subPaymentService(resp)
        .then(({ data }) => {
          setLoader(false);

          // history.push({
          //   pathname: "/history",
          //   paid: true,
          // });

          if (data.status) {
            setShowPaidModal(true);
            handleClose();
            if (setPurchasedId) {
              setPurchasedId(true);
            }
          }

          if (data.status) {
            let obj = {
              PurchaseSecret: data.data.client_secret,
              PlanName: data.data.plan_detail.plan_name,
            };

            const analytics = getAnalytics();
            logEvent(analytics, "PlanPurchase", obj);
          }
        })
        .catch((err) => {
          toastMessage(err?.response?.data?.message, "error");
          setLoader(false);
        });
    } else {

      let resp = {
        user_id: user?.id,
        date: moment(state.dateobj).format("YYYY-MM-DD"),
        consultation_time: moment(dateobj).format("HH:mm"),
        consultation_with: calendarName,
        consultation_id: 1,
        amount: 300,
        stripe_token: token,
        is_web: 1,
        // datetime: moment(state.dateobj).format(),
        datetime: moment(state.dateobj).tz(timeZoneArea).format(),
        calendarID: state.type,
        appointmentTypeID: state.calendar,
        street_address: user?.user_address?.street_address,
        city: user?.user_address?.city,
        zip_code: user?.user_address?.zip_code,
        state: user?.user_address?.state,
        country: user?.user_address?.country,
        description: description,
        term_and_condition: true,
        email: user?.email,
        phone_no: user?.phone_no,
        timezone: timeZoneArea,
        card_holder_name: name,
        expire_date: expiry
      };




      if (cardBool) {
        resp["is_card_save"] = true;
      } else {
        resp["is_card_save"] = false
      }


      if (lastFour.length > 0) {
        resp['pm_last_four'] = lastFour
      }

      if (lastDigits) {
        resp['pm_last_four'] = lastDigits
      }

      if (paymentCard) {
        resp['is_other_payment_gateway'] = false
      } else {
        resp['is_other_payment_gateway'] = true
      }



      // if (cardId) {
      //   resp['card'] = cardId
      // }

      setLoader(true);
      bookConsultationService(resp)
        .then(({ data }) => {
          setLoader(false);
          // history.push({
          //   pathname: "/history",
          //   paid: true,
          // });

          toastMessage(data?.message, "success");

          if (data) {
            setBookingStatus(data.status);
            setShowPaidModal(true);
            handleClose();
          }
          bookingGoogle(data.status);
        })
        .catch((err) => {
          setLoader(false);
          toastMessage(err?.response?.data?.message, "error");
        });
    }
  };

  const bookingGoogle = (bookingStatus) => {
    if (bookingStatus) {
      let obj = {
        ConsultationBookingStatus: bookingStatus,
      };

      const analytics = getAnalytics();
      logEvent(analytics, "NewConsultationBooking", obj);
    }

    if (bookingStatus) {
      const analytics = getAnalytics();
      logEvent(analytics, "Consultation Payment");
    }
  };

  useEffect(() => {
    bookingGoogle();
  }, [bookingStatus]);

  const ELEMENTS_OPTIONS = {
    // clientSecret: '{{CLIENT_SECRET}}',
  };

  const paymentViaCard = () => {

    if (cardNumber.includes("****")) {
      ConsultationPay(cardToken, lastFour, true)
      handleClose(true);
    } else {
      if (cardNumber.length == 0 || cvc.length == 0 || expiry.length == 0) {
        toastMessage("Please complete all of the required information.", "error");
      } else {
        window.Stripe.createToken(
          {
            number: cardNumber,
            cvc: cvc,
            exp_month: expiry.split("/")[0].replace(" ", ""),
            exp_year: expiry.split("/")[1].replace(" ", ""),
            name: name,
          },
          function (result, response) {
            if (response?.hasOwnProperty("error")) {
              toastMessage(response?.error?.message, "error");
              handleClose(true);
            } else {
              if (response.hasOwnProperty("id")) {
                let obj = {
                  stripe_token: response.id,
                  pm_last_four: response.card.last4,
                  card_holder_name: name,
                  expire_date: expiry
                }

                console.log("resp", response)

                setLastFour(response.card.last4)

                ConsultationPay(response.id, response.card.last4, true);

                // paymentCardService(obj)
                //   .then((data) => {
                //     if (data) {
                //       ConsultationPay(response.id, response.card.last4, true);
                //     }
                //   })
                //   .catch((err) => {

                //   })
                handleClose(true);
              }
            }
            // Handle result.error or result.token
          }
        );
      }
    }


  };

  const getCVC = (e) => {
    if (e.target.value < 0) {
      setCVC(0);
    } else if (e.target.value > 999) {
      setCVC(999);
    } else {
      setCVC(e.target.value);
    }
  };

  useEffect(() => {
    getProfileService()
      .then(({ data } = data) => {
        if (data?.data?.user?.pm_last_four) {
          setCardBool(true)
          setCardToken(data?.data?.user?.card_token)
          setCardNumber("**** **** **** " + data?.data?.user?.pm_last_four)
          setLastFour(data?.data?.user?.pm_last_four)
        }

        if (data?.data?.user?.card_holder_name) {
          setName(data?.data?.user?.card_holder_name)
        }

        if (data?.data?.user?.expire_date) {
          setExpiry((data?.data?.user?.expire_date).split(" ").join(""))
        }

      })
      .catch(() => {

      })
  }, [show])

  return (
    <>
      <Modal show={show} onHide={handleClose} keyboard={false} centered>
        <Modal.Header style={{ borderBottom: "none" }}>
          <Modal.Title style={{ fontWeight: "800" }}>
            {t("paymentMethod")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer style={{ borderTop: "none", display: "flex" }}>
          <Button
            className="btnGuest modalPay applePay"
            onClick={() => beginApplePay()}
            disabled={!isSupported}
          >
            <img
              src={ApplePay}
              style={{ height: "25px", marginBottom: "2px" }}
            />{" "}
            Pay
          </Button>
          <GooglePayButton
            buttonSizeMode="fill"
            className="googlePlaybtn"
            buttonType="short"
            environment="PRODUCTION"
            paymentRequest={paymentRequest}
            onPaymentAuthorized={() => ({ transactionState: "SUCCESS" })}
            onLoadPaymentData={(paymentRequest) => {
              // console.log("paymentRequest", paymentRequest)
              ConsultationPay(
                JSON.parse(
                  paymentRequest.paymentMethodData.tokenizationData.token
                ).id
              );
            }}
          />
          <div className="lineDiv w-100">
            <div className="line"></div>
            <div className="lineText">{t("continueWith")}</div>
            <div className="line"></div>
          </div>

          {!cardBool ? <div
            style={{ height: "125px", position: "relative", margin: "0 auto" }}
          >
            <div
              className="w-100"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <CreditCardInput
                onError={({ inputName, err }) =>
                  console.log(`credit card input error: ${err}`)
                }
                cardExpiryInputProps={{
                  onBlur: (e) => console.log("expiry blur", e),
                  onChange: (e) =>
                    console.log("expiry change", setExpiry(e.target.value)),
                  onError: (err) => console.log(`expiry error: ${err}`),
                }}
                cardNumberInputProps={{
                  value: cardNumber,
                  onBlur: (e) => console.log("number blur", e),
                  onChange: (e) =>
                    console.log("number change", setCardNumber(e.target.value)),
                  onError: (err) => console.log(`number error: ${err}`),
                }}
                customTextLabels={{
                  cardNumberPlaceholder: "Card Number",
                }}
                fieldClassName="ClassError"
              />
            </div>

            <div className="cardDiv">
              <input
                className="inputCard firstCardIpt"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Card Holder"
              />
              <input
                className="inputCard secondCardIpt"
                placeholder="CVC"
                value={cvc}
                onChange={(e) => getCVC(e)}
              />
            </div>
          </div> :

            <div className="cardAlreadyAdded">
              <CreditCardInput
                onError={({ inputName, err }) =>
                  console.log(`credit card input error: ${err}`)
                }
                cardNumberInputProps={{
                  value: cardNumber,
                  onBlur: (e) => console.log("number blur", e),
                  onChange: (e) =>
                    console.log("number change", setCardNumber(e.target.value)),
                  onError: (err) => console.log(`number error: ${err}`),
                  disabled: true
                }}
                customTextLabels={{
                  cardNumberPlaceholder: "Card Number",
                }}
                fieldClassName="ClassCardAdded"
              />
              <button className="payButtonModal" id="cardAdded" onClick={() => {
                history.push({ pathname: "/payment", redirect: location.pathname })
              }}>Update
              </button>
            </div>
          }
          <div
            className="w-100"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button className="payButtonModal" disabled={loader} onClick={() => paymentViaCard()}>
              {" "}
              <HiCreditCard size={25} style={{ marginRight: "5px" }} />
              Pay
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {loader && <Loader />}
    </>
  );
}

export default Pay;
