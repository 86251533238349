import React, { useEffect, useState } from "react";
import "../../shared/styles/css/account.css";
import "../../shared/styles/css/news.css";
import Navigation from "../navigation";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getNewsService } from "../../shared";
import Loader from "../../shared/loader/loader";
import moment from "moment";
import { clientInnerWidth } from "../../shared/util/constant";
import { getAnalytics, logEvent } from "firebase/analytics";
import { browserName, browserVersion, isIOS } from "react-device-detect";
import InfiniteScroll from "react-infinite-scroll-component";

const News = () => {
  const [newsHeading, setnewsHeading] = useState([]);
  const [newsTitle, setNewsTitle] = useState(null);
  const [clientWidth, setClientWidth] = useState(0);
  const [resp, setResp] = useState(null);
  const [loader, setLoader] = useState(true);
  const [date, setDate] = useState("");
  const [dataList, setdataList] = useState([]);
  const [image, setImage] = useState("");
  const [offset, setOffset] = useState(0);
  const [infiniteScrollBool,setInfiniteScroll] = useState(false)

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const getNews = () => {
    getNewsService(offset)
      .then(({ data }) => {
        setOffset(offset + 10);
        setdataList(data);
        setLoader(false);
        // let temp = data.find((i) => i.id == 100);
        // setNewsTitle(temp.title.rendered);
        // setResp(temp);
        // setnewsHeading(temp.excerpt.rendered);
        // setDate(moment(temp.date).format("MMMM Do, YY"));
        // setImage(temp?.x_featured_media_large);
      })
      .catch((err) => {
        setLoader(false);
        console.log("news err", err);
      });
  };
  useEffect(() => {
    getNews();
  }, []);
  const [sidebar, setSidebar] = useState(true);
  const showSidebar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(!sidebar);
    }
  };
  const hideSideBar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(false);
    }
  };
  const loadMore = () => {
    setInfiniteScroll(true)
    getNewsService(offset)
      .then(({ data }) => {
        if (data.length) {
          let cloneData = [...dataList];
          // data.forEach((item,inx)=>{
          //   let checkElement = cloneData.findIndex(ii=> ii.id == item.id);
          //   if(checkElement < 0){
          //     cloneData = [...cloneData,item]
          //   }
          // })
          setdataList(cloneData.concat(data));
          setOffset(offset + 10);
        }
        setLoader(false);
        // let temp = data.find((i) => i.id == 100);
        // setNewsTitle(temp.title.rendered);
        // setResp(temp);
        // setnewsHeading(temp.excerpt.rendered);
        // setDate(moment(temp.date).format("MMMM Do, YY"));
        // setImage(temp?.x_featured_media_large);
      })
      .catch((err) => {
        setLoader(false);
        console.log("news err", err);
      }).finally(()=>setInfiniteScroll(false))
  };

  // console.log("dataList", dataList[0]?.content?.rendered?.split("</p>")[0].replace(`<p class="" style="white-space: pre-wrap;">`, ""))

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, "News");
  }, []);
  useEffect(()=>{
   let Element =  document.getElementById("layout");
   Element.style.position = "relative"
  })
  // useEffect(()=>{
  //   // document.querySelector('iframe').contentWindow.document.querySelector("").style.color = "red";
  //  let IframeElement =  document.getElementById("iFrame")?.contentWindow
  //  console.log(IframeElement?.window?.document.querySelector("img"),"iFrame",IframeElement?.document.querySelector("img"))
  // })
  return (
    <>
      <Navigation
        label={t("news")}
        width={setClientWidth}
        onPress={() => history.goBack()}
        sidebar={sidebar}
        showSidebar={showSidebar}
        setSidebar={setSidebar}
      />
      {!loader ? (
        <div
          className={`news-m ${
            clientWidth > clientInnerWidth.width && "margin300"
          }`}
          onClick={hideSideBar}
        >
          {/* <div>
            <img className="img-n" src={image} />
            <div className="cont-n">
              <p className="date-price">{date}</p>

              <p
                className="news-p"
                dangerouslySetInnerHTML={{
                  __html: newsTitle,
                }}
              ></p>
              <p
                className="news-p"
                dangerouslySetInnerHTML={{
                  __html: newsHeading,
                }}
              ></p>
            </div>

            <p
              onClick={() => {
                history.push({
                  pathname: "/news-detail",
                  state: resp,
                });
              }}
              className="viewDetl"
            >
              {t("view-detail")}&gt;&gt;
            </p>
          </div>

          <p className="paragraph-home">{t("previous-news")}</p> */}
          <InfiniteScroll
                dataLength={dataList.length}
                next={loadMore}
                hasMore={true}
                loader={<h4 className="text-center">
                  {
                    infiniteScrollBool ? <div className="spinner-border darkBrown" role="status">
                    <span class="sr-only"></span>
                  </div>:""
                  }
                  
              </h4>}
              >
          <div className="container cont-news mt-5">
            <div
              className="row d-flex"
              style={
                browserName == "Mobile Safari" || isIOS
                  ? { marginBottom: "25%" }
                  : {}
              }
            >
              
                <div className="row">
                  {dataList.map((post) => {
                    return (
                      <>
                        <div
                          className="col-6 col-news col-news2"
                          style={{
                            borderRadius: "10px",
                            cursor: "pointer",
                            position: "relative",
                            width: "100%",
                          }}
                          onClick={() => {
                            history.push({
                              pathname: "/news-detail",
                              state: { post: post, check: "true" },
                            });
                          }}
                        >
                          {
                            post?.x_metadata?.youtube_video ? <iframe id="iFrame" style={{
                              borderRadius: "10px",
                              width: "124px",
                              height: "76px"  
                            }} src={post?.x_metadata?.youtube_video}></iframe>:<img
                            className="img-news"
                            style={{
                              borderRadius: "10px",
                              margin: "30px 0px 30px 0px",
                            }}
                            src={
                              // "http://178.128.29.7/open-border-web/public/" +
                              post.x_featured_media
                            }
                          />
                          }
                          
                          
                          <div
                            className="descrptn_cont"
                            style={{ marginLeft: "2%" }}
                          >
                            {/* <p className="head-news"> {post.}</p> */}

                            <div
                              className="head-news mtClass"
                              dangerouslySetInnerHTML={{
                                __html: post.title.rendered,
                              }}
                            ></div>

                            <div
                              className="news-desc1"
                              dangerouslySetInnerHTML={{
                                __html: post.excerpt?.rendered,
                              }}
                            ></div>

                            <p className="view-news">
                              {/* {t("view-detail")}&gt;&gt; */}
                              {moment(post.date).format("MMMM D, YYYY")}
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              
            </div>
          </div>
          </InfiniteScroll>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default News;
