import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom"


const UpdateCardModal = ({ show, closeModal }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  return (
    <div>
      <Modal
        show={show}
        onHide={closeModal}
        centered
        contentClassName="rounded-0"
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: "none", borderRadius: "0px" }}
        ></Modal.Header>
        <Modal.Body>
          <div className="mr-body">
            <label className="labelCard">{t("wantUpdateCreditCard")}</label>
          </div>
        </Modal.Body>
        <div className="mdl-footer">
          <button
            type="button"
            className="btn btn-yes"
            style={{ marginRight: "10px" }}
            onClick={() => history.push("/payment")}
          >
            {t("yes")}
          </button>
          <button type="button" className="btn btn-no" onClick={closeModal}>
            {t("no")}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default UpdateCardModal;
