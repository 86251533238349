import React, { useState, useMemo } from "react";
import { login } from "../Redux/Reducers/userSlice";
import { LoginService, toastMessage } from "../";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Dropdown, Row, Col, Form } from "react-bootstrap";
import countryList from "react-select-country-list";
import Select from "react-select";
import CountryList from "country-list-with-dial-code-and-flag";
import "./guestPyament.css";
export default function GuestPaymentForm({
  guestForm,
  setGuestForm,
  check,
  setCheck,
  handleSumit,
  setSwitchForm,
  handleClose,
  phone,
  setPhone,
  countryCode,
  setCountryCode
}) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [countryFilter, setCountryFilter] = useState([]);



  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    mutateState("phone", (countryCode + phone.split("-").join("")))
    let check = checkPassword()
    if (check) {

      setSwitchForm("pay")
    }

  };
  const checkPassword = () => {
    if (guestForm.password !== guestForm.confrmPassword) {
      toastMessage("The password confirmation does not match", "success")
      return false
    } else {
      return true
    }
  }
  const filterCountryList = (e) => {
    let countryCodeArr = [];
    let temp = CountryList.map((i) => {
      if (i.name.toLowerCase().includes(e.target.value.toLowerCase())) {
        countryCodeArr.push(i);
      }
    });
    setCountryFilter(countryCodeArr);
  };
  const SelectCountry = (e) => {
    let temp = CountryList.filter(
      (country) => country.name + " " + country.flag == e.target.innerText
    );
    temp.unshift();
    setCountryCode(temp[0].dial_code);
  };
  const changeHandler = (value) => {
    mutateState("country", value)
  };

  const formatPhoneNumber = (e) => {
    setPhone(e.target.value.replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
  };
  const mutateState = (name, value) => setGuestForm({
    ...guestForm,
    [name]: value
  })
  const options = useMemo(() => countryList().getData(), []);
  console.log("guestForm", guestForm)
  return (
    <>
      {/* ${check ? "formHeightCheck":"formHeight"} */}
      {/* ${check ? "":""} */}
      <form
        className={`guestForm guestformGrid `}
        onSubmit={(e) => handleSubmit(e)}
        style={{ marginBottom: "0px", overflow: "hidden" }}
      >
        {/* <div className={`guestForm guestformGrid ${check ? "padding450":""}`}> */}
        <InputTitle title={t("fname")} type="text" value={guestForm.fname} handleChange={(value) => mutateState("fname", value)} required={true} />
        <InputTitle title={t("lname")} type="text" value={guestForm.lname} handleChange={(value) => mutateState("lname", value)} required={true} />
        <InputTitle title="E-mail" type="email" value={guestForm.email} handleChange={(value) => mutateState("email", value)} required={true} />

        <Row>
          <Col>
            <label>{t("phoneNo")}</label>
          </Col>
        </Row>
        <Row>
          <Col sm={3} style={{ width: "auto" }}>
            <Dropdown>
              <Dropdown.Toggle
                className="phoneCode"
                variant="success"
                id="dropdown-basic"
              >
                {countryCode}
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropCode">
                <input
                  className="countryList"
                  placeholder="Search Country..."
                  onChange={(e) => filterCountryList(e)}
                />
                {countryFilter.length > 0
                  ? countryFilter.map((country) => {
                    return (
                      <>
                        <Dropdown.Item
                          href="#"
                          onClick={(e) => SelectCountry(e)}
                        >
                          {country.name + " " + country.flag}
                        </Dropdown.Item>
                      </>
                    );
                  })
                  : CountryList.map((country) => {
                    return (
                      <>
                        <Dropdown.Item
                          href="#"
                          onClick={(e) => SelectCountry(e)}
                        >
                          {country.name + " " + country.flag}
                        </Dropdown.Item>
                      </>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col>
            <input
              className="signin-input w-100"
              onChange={(e) => formatPhoneNumber(e)}
              type="phone"
              // pattern="[0-9\s]{13,19}"
              autocomplete="cc-number"
              maxLength="12"
              // pattern="\d*"
              inputMode={"numeric"}
              id="PhoneNum"
              value={phone}
              required
            />
          </Col>
        </Row>
        <InputTitle title={t("address")} type="text" value={guestForm.stAddress} handleChange={(value) => mutateState("stAddress", value)} required={true} />
        <InputTitle title={t("city")} type="text" value={guestForm.city} handleChange={(value) => mutateState("city", value)} required={true} />
        <InputTitle title={t("state")} type="text" value={guestForm.state} handleChange={(value) => mutateState("state", value)} required={true} />
        <InputTitle title={t("zip")} type="number" value={guestForm.zip} handleChange={(value) => mutateState("zip", value)} required={true} />
        <label for="country">{t("country")}</label>
        <Select
          options={options}
          value={guestForm?.country}
          onChange={changeHandler}
          className="country-input"
        />
        {/* <ul className="acc-after1">
          <li>👨‍👩‍👧‍👦</li>
          <li>
            <p className="p-acc">{t("collectUsforPublic")}</p>
          </li>
        </ul> */}
        <ul className="acc-after1 marginBottom0">
          <li>
            👨‍👩‍👧‍👦
            {/* <img src={Additional} style={{ height: "35px" }} /> */}
          </li>
          <li>
            <p className="p-acc">{t("InformationForUS")}</p>
          </li>

        </ul>
        <label for="postal" className="marginBottom20" >{t("InformationStatement")}</label>
        <InputTitle title={t("fname")} type="text" value={guestForm.pFname} handleChange={(value) => mutateState("pFname", value)} />
        <InputTitle title={t("lname")} type="text" value={guestForm.plName} handleChange={(value) => mutateState("plName", value)} />
        <InputTitle title="E-mail" type="email" value={guestForm.pEmail} handleChange={(value) => mutateState("pEmail", value)} />
        <Form>
          {["checkbox"].map((type, inx) => (
            <div key={`default-${type}`} className="mb-3">
              <Form.Check
                type={type}
                id={`default-${type}`}
                key={inx}
                className="guestPayment w-100"
                style={{ textDecoration: "underline" }}
                label={t("publicSignin")}
                defaultChecked={check}
                onChange={(e) => {
                  mutateState("password", "")
                  let val = !check
                  setCheck(val)
                  if (!val) {
                    setTimeout(() => {
                      mutateState("confrmPassword", "")
                    }, 100)

                    mutateState("password", "")
                  }
                }

                }
                required
              />
            </div>
          ))}
        </Form>
        {check ? (
          <>
            <InputTitle title="Password" type="password" value={guestForm.password} handleChange={(value) => mutateState("password", value)} />
            <InputTitle title="Confirm Password" type="password" value={guestForm.confrmPassword} handleChange={(value) => mutateState("confrmPassword", value)} />
          </>
        ) : (
          ""
        )}

        {/* </div> */}

        {/* <div className= {` guestFooter text-center ${check ? "guestFooterTop":""} `}> */}
        <button
          type="submit"
          className="btn  btn-main btn-pack"
          style={{ fontSize: "18.72px" }}
        >
          {t("guestPay")}
        </button>
        <div className="text-center">
          <span className="font15">{t("already-account")}</span>
          <span
            className="font15 marginleft6 font600 pointer"
            onClick={() => setSwitchForm("signin")}
          >
            {t("signIn")}
          </span>
          {/* </div> */}
        </div>
      </form>
    </>
  );
}
const InputTitle = ({ title, type, placeholder, value, handleChange, required }) => {
  return (
    <>
      <label>{title}</label>
      <input
        className="signin-input"
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={(e) => handleChange(e.target.value)}
        required={required}
      />
    </>
  );
};
