// import React from "react";
// import { useTranslation } from "react-i18next";
// import { ClientRestart } from "../../../assets";
// const ModalRestart = ({ onPress }) => {
//   const { t, i18n } = useTranslation();

//   return (
//     <div>
//       <button
//         type="button"
//         className="btn btn-foot me-2"
//         data-toggle="modal"
//         data-target="#exampleModal"
//       >
//         <ClientRestart className="footer-btn-icon" />
//         <span className="ques-foo">{t("restart")}</span>
//       </button>

//       <div
//         className="modal fade modalRestrt"
//         id="exampleModal"
//         tabindex="-1"
//         role="dialog"
//         aria-labelledby="exampleModalLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-dialog-centered" role="document">
//           <div className="modal-content">
//             <div className="modal-body">
//               <div className="mr-body">{t("restart-modal")}</div>
//             </div>
//             <div className="modal-footer mr-foot">
//               <button
//                 type="button"
//                 className="btn btn-yes"
//                 data-dismiss="modal"
//                 onClick={onPress}
//               >
//                 {t("yes")}
//               </button>
//               <button type="button" className="btn btn-no" data-dismiss="modal">
//                 {t("no")}
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ModalRestart;


import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { ClientRestart, Reload } from "../../../assets";

function ModalRestart({ onPress }) {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary"
        className="btn btn-foot me-2 reloadBtn"
        onClick={handleShow}
            
      >
        <Reload className="footer-btn-icon questionBtnSvgs" style={{ marginLeft: "-10px" }} />
        <span className="ques-foo">{t("restart")}</span>
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header style={{ borderBottom: "none" }}>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>{t("restart-modal")}</Modal.Body>
        <Modal.Footer style={{ borderTop: "none", justifyContent: "center" }}>
          <Button
            onClick={onPress}
            className="btn btn-yes"
          >
            {t("yes")}
          </Button>
          <Button
            onClick={handleClose}
            className="btn btn-no"
          >
            {t("no")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalRestart 
