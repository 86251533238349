import React from "react";
import Lottie from "react-lottie";
function LogoAnimation({ Pic, Message }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Pic,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <Lottie
        isClickToPauseDisabled
        options={defaultOptions}
        isStopped={false}
        isPaused={false}
        height={"300px"}
        width={
          window.innerWidth <= 1200 && window.innerWidth >= 900
            ? "600px"
            : window.innerWidth <= 900
              ? "350px"
              : "800px"
        }
        padding={"0%"}
      />
      {Message && (
        <h3 className="text-secondary text-center mt-4">{Message}</h3>
      )}
    </div>
  );
}

export default LogoAnimation;
