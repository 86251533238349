import { HTTP_Request } from "../util/config";
import { Endpoint } from "../util/endpoint";
const singlePaymentService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(Endpoint.singlePayment, formBody);
};
const subPaymentService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(Endpoint.subPayment, formBody);
};
const publicSubcriptionPaymentService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(Endpoint.publicSubscriptionPayment, formBody);
};
const paymentCardService = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });
  return HTTP_Request.post(Endpoint.addPaymentCard, formBody);
}
export { singlePaymentService, subPaymentService, paymentCardService, publicSubcriptionPaymentService };
