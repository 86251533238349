import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ModalForgotPassword from "../../shared/component/Modals/ModalForgotPassword";
import { SideBarLogo2 } from "../../assets";
import "./guestModal.css";
import { login } from "../../shared/Redux/Reducers/userSlice";
import { LoginService, toastMessage } from "../../shared";
import GuestModalWrapper from "./GuestModalWrapper.js";
function GuestModal({phoneNumber,setShowPaidModal,setPhoneNumber,countryCode,setCountryCode,switchForm,guestForm,setGuestForm,resetCloseModal,consultation,setSwitchForm ,packageName,show,PackageDetail,priceCheck,check,Name,quantity, Logincheck,setLogincheck,handleClose }) {
  
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const handleSumit = ()=>{
    console.log("handleSumit")
  }

  return (
    <>
      <Modal show={show} onHide={()=>{
        if(resetCloseModal){

          setSwitchForm(resetCloseModal)
        }
        if(phoneNumber){
          setGuestForm({
            ...guestForm,
            phone:(countryCode + phoneNumber.split("-").join(""))
          })
        }
        
        handleClose()
        }} keyboard={false} centered>
        <Modal.Header
          className="d-flex align-items-center flex-column"
          style={{ borderBottom: "none" }}
          closeButton
        >
          {
            switchForm == "payment" ?  <Modal.Title>
            <img src={SideBarLogo2} width={110} />
          </Modal.Title>:<Modal.Title style={{ fontWeight: "800",padding:"10px 0" }}>
            
          </Modal.Title>
          }
         
          
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            marginTop: "-17px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {switchForm == "payment" ? (
            <>
            <p className="p-acc">{packageName ? packageName:"" }</p>
            <span>
            {t("collectInformationPublic")}
            </span>
              
            </>
          ) : (
            <>
              {" "}
              <span style={{ fontWeight: "800" }} className="modal-title h4 NeueMdFamily">
                {t("paymentMethod")}
              </span>
              {/* <span>{t("accessTitle")}</span> */}
            </>
          )}
        </Modal.Body>
        <Modal.Body className="removeTopBorder">
          <div className="container">
            <GuestModalWrapper
              switchForm={switchForm}
              setSwitchForm={setSwitchForm}
              handleClose={handleClose}
              handleSumit={handleSumit}
              PackageDetail={PackageDetail}
              quantity={quantity}
              priceCheck={priceCheck}
              check={check}
              Name={Name}
              consultation={consultation}
              Logincheck={Logincheck}
              setLogincheck={setLogincheck}
              guestForm={guestForm} 
              setGuestForm={setGuestForm}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              countryCode={countryCode}
              setShowPaidModal={setShowPaidModal}
              setCountryCode={setCountryCode}
              
            />
          </div>
        </Modal.Body>
        {/* {
          switchForm == "payment" ? <Modal.Footer>
            <div className="container text-center">

            <button
              onClick={handleSumit}
              className="btn  btn-main btn-pack"
              style={{ fontSize: "18.72px" }}
            >
             Continue & Pay
            </button>
            <span className="font15">Already Have An account?</span><span className="font15 marginleft6 font600 pointer" onClick={()=> setSwitchForm("signin")}>Sign In</span>
            </div>
            
          
         </Modal.Footer>:""
        } */}
        
      </Modal>
    </>
  );
}

export default GuestModal;
