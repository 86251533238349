import { NavLink } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../../../shared/styles/css/main.css";
import "../../../shared/styles/css/auth.css";
// import { login } from "../../../features/userSlice";
import { login } from "../../../shared/Redux/Reducers/userSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { guestLoginService, LoginService, toastMessage } from "../../../shared";
import { useHistory } from "react-router-dom";
import { SideBarLogo2 } from "../../../assets/index"
import ModalForgotPassword from "../../../shared/component/Modals/ModalForgotPassword";
import "../../../shared/styles/css/modal.css";
import Loader from "../../../shared/loader/loader";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useSelector } from "react-redux";



const SignIn = () => {
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false);
  const [showResetEmail,setResetEmail] = useState(false);
  const user = useSelector((state) => state.root.user);

const closeResetEmail = ()=> setResetEmail(false)
const openResetEmail = ()=> setResetEmail(true)

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    LoginService({
      email: email,
      password: password,
    })
      .then(({ data }) => {
        setEmail("");
        setPassword("");
        setLoader(false);
        setStatus(data.status)
        let verifyEmail = data.data?.user?.email_verified_at;
        dispatch(login(data.data));
        // if (!verifyEmail) {
        //   history.push("/otp");
        // } else {
        //   history.push("/home");
        // }
        history.push("/home");
      })
      .catch((err) => {
        setLoader(false);
        toastMessage(err?.response?.data?.message, "error");
      });
  };
  const LoginGuest = () => {
    if (user && user?.user?.is_guest == 1) {
      history.push("/home");
    }
    else {
      guestLoginService()
        .then(({ data: { data } }) => {
          dispatch(login(data));
          history.push("/home");
        })
        .catch((err) => console.log(err));
    }

    // let obj = {
    //   user:{
    //     type:"guest",
    //     name:"guest user"
    //   },
    //   access_token:"token"
    // }
  };

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, "Log In");
  }, [])

  useEffect(() => {
    if (status) {
      const analytics = getAnalytics();
      logEvent(analytics, "Log In");
    }
  }, [status])


  const { t, i18n } = useTranslation();
  return (
    <div className="mains">
      <img src={SideBarLogo2} className="signupLogo" style={{ marginTop: "2rem" }} />

      <h1 className="heading">{t("greeting")}</h1>
      <p className="paragraph">{t("SigninText")}</p>

      <div className="container auth-container">
        <form className="signin-form" style={{ marginBottom: "0px",overflowY:"hidden" }} onSubmit={(e) => handleSubmit(e)}>
          <label>Email</label>
          <input
            value={email}
            className="signin-input"
            type="email"
            placeholder=""
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>{t("password")}</label>
          <input
            value={password}
            className="signin-input"
            type="password"
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <label onClick={()=> openResetEmail()}  className="forget-pwd w-100"
        style={{ textAlign: "right",cursor:"pointer" }}>{t("forget-password")}</label>
          
          <button className="btn btn-auth">{t("signIn")}</button>
        </form>
        <div className="signin-form">
          <NavLink to="/signup" style={{ textDecoration: "none" }}>
            <button className="btn btn-guest">
              {t("signup")}
            </button>
          </NavLink>
          <button className="btn btn-guest1" onClick={LoginGuest}>
            {t("ContinueGuest")}
          </button>
          {/* <p className="paragraph w-100">
            {t("not-have-a-account")} &nbsp;
            <NavLink style={{ fontWeight: "bold" }} className="links" to="/signup">
              {t("signup")}
            </NavLink>
          </p> */}
        </div>
      </div>
      <ModalForgotPassword show={showResetEmail} handleClose={closeResetEmail} />
      {loader && <Loader />}
    </div>
  );
};

export default SignIn;
