import React from "react";
import { useSelector } from "react-redux";
import { publicRoute, PrivateRoute } from "./allRoute";
import { Route, Redirect, useLocation } from "react-router-dom";
import Layout from "./layout";
export default function AuthRoute() {
  const {
    user: { user },
  } = useSelector((state) => state.root);
  const location = useLocation();
  return (
    <>
      {(user) || user?.is_guest ? (
        <>
          {PrivateRoute.map((rote, inx) => {
            return (
              <Route
                key={inx}
                path={rote.path}
                exact={true}
                render={(props) => {
                  return <Layout {...props} {...rote} />;
                }}
              />
            );
          })}
          {location.pathname === "/" ? (
            <Redirect exact={true} to="/home" path="/" />
          ) : user?.is_guest ? (
            ""
          ) : !user?.is_guest && location.pathname === "/signin" ? (
            <Redirect exact={true} to="/signin" path="/signin" />
          ) : (
            ""
          )}
        </>
      ) : user ? (
        <>
          {publicRoute.map((rote, inx) => {
            return (
              <Route
                key={inx}
                path={rote.path}
                exact={true}
                render={(props) => {
                  return <Layout {...props} {...rote} />;
                }}
              />
            );
          })}
          <Redirect path="/home" />
          <Redirect path="/" to={"/signup"} />
        </>
      ) : (
        <>
          {publicRoute.map((rote, inx) => {
            return (
              <Route
                key={inx}
                path={rote.path}
                exact={true}
                render={(props) => {
                  return <Layout {...props} {...rote} />;
                }}
              />
            );
          })}
          <Redirect path="/" to={"/home"} />
        </>
      )}
    </>
  );
}
