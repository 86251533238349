import React, { useEffect, useState } from "react";
import { Placeholder } from "../../assets/index";
import "../../shared/styles/css/account.css";
import "../../shared/styles/css/news.css";
import Navigation from "../navigation";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getNewsService } from "../../shared";
import { useLocation } from "react-router-dom";
import Loader from "../../shared/loader/loader";
import moment from "moment";
import { clientInnerWidth } from "../../shared/util/constant";
import axios from "axios";
import { getAnalytics, logEvent } from "firebase/analytics";
import { browserName, browserVersion, isIOS } from "react-device-detect";


const About = () => {
  const location = useLocation();
  const { state } = location;
  const [stateRes, setState] = useState(state);
  const [clientWidth, setClientWidth] = useState(0);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(!sidebar);
    }
  };
  const hideSideBar = () => {
    if (!(window.innerWidth >= clientInnerWidth.width)) {
      setSidebar(false);
    }
  };

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'About Us');
  }, [])

  return (
    <>
      <Navigation
        label={t("about")}
        width={setClientWidth}
        onPress={() => history.goBack()}
        sidebar={sidebar}
        showSidebar={showSidebar}
        setSidebar={setSidebar}
      />
      {/* <Navigation label={t("news")} onPress={() => history.goBack()} /> */}

      <div
        className={`news-m ${clientWidth >= clientInnerWidth.width && "margin300"
          }`}
        style={{ background: "#FFFAED", marginTop: "-60px" }}
        onClick={hideSideBar}
      >
        <Container style={(browserName == "Mobile Safari" || isIOS) ? { width: "90%", marginBottom: "50%" } : { width: "90%" }} className="mt-5">
          <iframe
            onLoad={() => setLoader(true)}
            src="https://bordercrossinglaw.com/about-us/"
            style={{ width: "100%", height: "100vh" }}
          ></iframe>
        </Container>
        {!loader && <Loader />}
      </div>
    </>
  );
};

export default About;
